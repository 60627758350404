import styled from '@emotion/styled';
import { useState } from 'react';
import { FcWithChildren } from 'types/fcWithChildren';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Tooltip } from '@mui/material';

const Full = styled.div<{ fixed?: boolean }>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
`;

const FullscreenToggle = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 0;
`;

export const FullScreen: FcWithChildren = ({ children }) => {
  const [fixed, setFixed] = useState(false);

  return (
    <Full fixed={fixed}>
      <FullscreenToggle onClick={() => setFixed(!fixed)}>
        {fixed ? <ExitFullScreen /> : <ToFullScreen />}
      </FullscreenToggle>
      {children}
    </Full>
  );
};

const ToFullScreen = () => {
  return (
    <Tooltip title={'Helskärm'}>
      <FullscreenIcon />
    </Tooltip>
  );
}

const ExitFullScreen = () => {
  return (
    <Tooltip title={'Avsluta helskärm'}>
      <FullscreenExitIcon />
    </Tooltip>
  );
}
