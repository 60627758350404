/**
 * Parses a response object and returns the HTTP status code.
 *
 * If the response object is not an instance of Response, returns 0.
 */
export const parseHttpResponse = (response: unknown): number => {
  if (response instanceof Response) {
    return response.status;
  } else {
    return 0;
  }
};