import { SortOrder } from "types/index";

export const sortOnDate = (aDate?: Date | string, bDate?: Date | string, sortOrderArg = SortOrder.ASC) => {
  if (aDate && bDate) {
    const dateA: number = new Date(aDate).getTime();
    const dateB: number = new Date(bDate).getTime();
    const result = dateA < dateB ? -1 : 1;
    return sortOrderArg === SortOrder.ASC ? result : -result;
  }
  return 0;
}