import { useEffect, FC, useContext, useState } from 'react';
import TableContext, { TableContextProvider } from 'components/table/TableContext';
import { TableFilter } from 'components/table/tableFilter/TableFilter';
import { useFetchAnalysis } from 'hooks/useFetchAnalysis';
import MissingAnalyzesContext, { MissingAnalyzesContextProvider } from './MissingAnalyzesContext';
import { MissingAnalyzes } from './MissingAnalyzes';
import { sortOnDate } from 'utils/dateUtils';
import { SampleWithMissingAnalyzes } from 'types/sampleWithMissingAnalyzes';
import { useSupport } from 'hooks/useSupport';
import { SupportType } from 'types/supportType';

export const MissingAnalyzesContainer = () => {
  const { getMissingAnalyzes } = useFetchAnalysis();
  const readingNames = useSupport(SupportType.READING_NAME);
  const [readingNamesIsSet, setReadingNamesIsSet] = useState<boolean>(false);

  const {
    setTableItems,
  } = useContext(MissingAnalyzesContext);

  const {
    readyForInitFetch,
    setIsLoading,
    getFilterValues,
    restTableState,
    setFilterValues,
    filterValues: { sortOrder, chosenReadingCodes },
  } = useContext(TableContext);

  useEffect(() => {
    if (readingNames?.length) {
      setFilterValues(current => ({
        ...current,
        chosenReadingCodes: readingNames.map((item) => item.value)
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readingNames]);

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      restTableState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initial loading of table items
  useEffect(() => {
    if (readyForInitFetch &&
      readingNamesIsSet === false
      && chosenReadingCodes &&
      chosenReadingCodes?.length > 0) {
      fetchTableItems();
      setReadingNamesIsSet(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyForInitFetch, chosenReadingCodes?.length]);

  const createTableItems = (items?: SampleWithMissingAnalyzes[]) => {
    if (!items) return [];

    // Sort table items on firstCreatedAnalysis
    const sortedTableItems = items.sort((a, b) =>
      sortOnDate(a.created, b.created, sortOrder));
    return sortedTableItems
  }

  const fetchTableItems = async () => {
    setIsLoading(true);
    try {
      const response = await (getMissingAnalyzes(getFilterValues()));

      const tableItems = createTableItems(response)
      setTableItems(tableItems);
    } catch (error) {
      setTableItems([]);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <TableFilter
        fetchTableItems={fetchTableItems}
        componentsToRender={[
          'facility', 'analysisType', 'datePickers', 'sampleId']}
      />
      <MissingAnalyzes />
    </>
  );
};

export const MissingAnalyzesWithContext: FC = () => {
  return (
    <TableContextProvider >
      <MissingAnalyzesContextProvider >
        <MissingAnalyzesContainer />
      </MissingAnalyzesContextProvider>
    </TableContextProvider>
  );
};