
export function compareBy<T>(getValue: (x: T) => string | number | undefined, descending?: boolean) {
  return (a: T, b: T) => {
    const aValue = getValue(a);
    const bValue = getValue(b);

    if (aValue === undefined && bValue === undefined) {
      return 0;
    }

    if (aValue === undefined) {
      return descending ? -1 : 1;
    }

    if (bValue === undefined) {
      return descending ? 1 : -1;
    }

    if (aValue < bValue) {
      return descending ? 1 : -1;
    }

    if (aValue > bValue) {
      return descending ? -1 : 1;
    }

    return 0;
  };
}
