import React, { useContext, useEffect } from 'react';
import { Grid, Select, MenuItem, Box, SelectChangeEvent } from '@mui/material';
import { getPresentableDeviceType } from 'functions/deviceNameConverter';
import TableContext from '../TableContext';
import useFetch from 'hooks/useFetch';
import { IDevice } from 'types';
import { Checkbox, StyledFormControl, StyledInputLabel } from 'components/shared/styleMuiComponents';

export const DeviceTypeSelect: React.FC = () => {
  const SELECT_ALL = "selectAll";
  const [allSelected, setAllSelected] = React.useState<boolean>(false);
  const { getDefaultBackend } = useFetch();
  const {
    deviceTypes,
    filterValues: {
      chosenDeviceTypes,
    },
    setFilterValues,
    setDeviceTypes,
  } = useContext(TableContext);

  useEffect(() => {
    (async () => {
      const response = await getDefaultBackend(`/api/devices`);
      if (response === null) {
        setDeviceTypes([]);
        return;
      }
      const devices: IDevice[] = await response.json();
      if (devices !== undefined) {
        setDeviceTypes(
          devices
            .map((device) => device.deviceType)
            .filter((value, index, self) => self.indexOf(value) === index)
        );
      }
    })();
  }, [setDeviceTypes, getDefaultBackend]);

  const handleChangeDeviceType = (e: SelectChangeEvent<string[]>) => {
    const selectedItems = e.target.value as string[];
    if (selectedItems.some(x => x === SELECT_ALL)) {
      const devicesToSet = allSelected === false ? deviceTypes : [];
      setFilterValues(current => ({
        ...current,
        chosenDeviceTypes: devicesToSet
      }));
      setAllSelected(!allSelected);
      return;
    }

    setFilterValues(current => ({
      ...current,
      chosenDeviceTypes: selectedItems?.length > 0 ? selectedItems : undefined
    }));
    setAllSelected(false);
  };

  const getRenderValue = () => {
    if (!chosenDeviceTypes?.length){
      return;
    }
    const MAX_LENGTH = 18;
    const displayText = chosenDeviceTypes.join(', ');
    const truncatedText = displayText.length > MAX_LENGTH
      ? `${displayText.slice(0, 15)}...`
      : displayText;

    return <Box>{truncatedText}</Box>;
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <StyledFormControl >

        <StyledInputLabel id="deviceType-label" >Instrumenttyp</StyledInputLabel>
        <Select
          labelId='deviceType-label'
          fullWidth
          multiple
          label="Instrumenttyp"
          value={chosenDeviceTypes ?? []}
          renderValue={getRenderValue}
          onChange={handleChangeDeviceType}
          disabled={!deviceTypes?.length}
        >
          <MenuItem key={SELECT_ALL} value={SELECT_ALL}>
            <Checkbox checked={allSelected} />
              {"Välj alla"}
            </MenuItem>
          {[...deviceTypes]?.sort().map((dt) => (
            <MenuItem key={dt} value={dt}>
              <Checkbox checked={(chosenDeviceTypes ?? [])?.includes(dt)} />
              {getPresentableDeviceType(dt)}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </Grid>
  )
};
