import { useParams } from 'react-router-dom';
import {
  LmTabContainer,
  LmTabContent,
  LmTabContentContainer,
  LmTabNav,
} from './lm2/LmTabNav';

type LocationType = {
  location: string;
  deviceId: string;
  tab: string;
};

export const Tabs = () => {
  const { location, deviceId } = useParams<LocationType>();
  return (
    <LmTabNav>
      <LmTabContainer>
        <LmTabContentContainer>
          <LmTabContent to={`/${location}/${deviceId}/analyzes/nytt`}>
            Nytt
          </LmTabContent>
          <LmTabContent to={`/${location}/${deviceId}/analyzes/alla`}>
            Alla
          </LmTabContent>
          <LmTabContent to={`/${location}/${deviceId}/analyzes/saknade`}>
            Saknade
          </LmTabContent>
        </LmTabContentContainer>
      </LmTabContainer>
    </LmTabNav>
  );
};
