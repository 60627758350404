import { FcWithChildren } from 'types/fcWithChildren';
import { NavLink } from 'react-router-dom';

interface Props {
  to: string;
}

export const LmTabNav: FcWithChildren = ({ children }) => {
  return <div className="tab-navigation  margin-top-20">{children}</div>;
};

export const LmTabContainer: FcWithChildren = ({ children }) => {
  return <div className="tab-navigation__tab-container">{children}</div>;
};

export const LmTabContent: FcWithChildren<Props> = ({ children, to }) => {
  return (
    <NavLink
      className={({ isActive }) => `tab-navigation__tab js-tab ${isActive ? 'is-active' : ''}`}
      to={to}
    >
      {children}
    </NavLink>
  );
};

export const LmTabContentContainer: FcWithChildren = ({ children }) => {
  return (
    <div className="tab-navigation__tab-content-container">{children}</div>
  );
};
