import { FC } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import {LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LmButtonBlue } from '../../lm2/LmButton';
import { FacilitySelect } from './Facilities';
import { DeviceTypeSelect } from './InstrumentType';
import { DatePickers } from './DatePicker';
import { ReadingCodeSelect } from './ReadingCodeSelect';
import SampleIdInput from './SampleId';

type ComponentNameType = 'facility' | 'deviceType' | 'datePickers' | 'sampleId'| 'analysisType';

interface ITableFilterProps {
  /**
   * Components to render in the table filter
   *
   * Set the components in the order you want them to be rendered
   * */
  componentsToRender: ComponentNameType[];
  fetchTableItems: () => void;
}

export const TableFilter: FC<ITableFilterProps> = ({ fetchTableItems, componentsToRender }) => {

  const components: { [key: string]: JSX.Element } = {
    facility: <FacilitySelect key={'facility'} />,
    deviceType: <DeviceTypeSelect key={'deviceType'} />,
    analysisType: <ReadingCodeSelect key={'analysisType'} />,
    datePickers: <DatePickers key={'datePickers'} />,
    sampleId: <SampleIdInput key={'sampleId'} />
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3} alignItems="flex-end">
                {componentsToRender.map((component) => components[component])}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  {/* Empty grid item to move the following element one cell*/}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                <Grid container spacing={3} justifyContent="right" alignItems="right">
                  <LmButtonBlue onClick={fetchTableItems}>
                    Sök
                  </LmButtonBlue>
                </Grid>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </CardContent>
      </Card>
        
      </Grid>
    </Grid>
  );
};
