import React, { ChangeEvent, useContext, useEffect } from 'react';
import { Grid, MenuItem } from '@mui/material';
import TableContext from '../TableContext';
import { StyledTextField } from 'components/shared/styleMuiComponents';
import { useFetchFacilities } from 'hooks/useFetchFacilities';

export const FacilitySelect: React.FC = () => {
  const fetchFacilities = useFetchFacilities();
  const {
    facilities,
    filterValues: {
      location,
    },
    setFilterValues,
    setFacilities,
  } = useContext(TableContext);

  const handleChangeLocation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterValues(current => ({
      ...current,
      location: e.target.value
    }));
  };

  useEffect(() => {
      setFacilities(fetchFacilities?.facilities);
  }, [fetchFacilities?.facilities, setFacilities]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <StyledTextField
        fullWidth
        select
        label="Ort"
        value={location}
        onChange={handleChangeLocation}
        disabled={!facilities?.length}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {facilities?.sort().map((facility) => (
          <MenuItem key={facility.id} value={facility.name}>
            {facility.name}
          </MenuItem>
        ))}
      </StyledTextField>
    </Grid>
  )
}
  ;
