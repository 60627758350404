import { createContext, useCallback, useEffect, useState } from 'react';
import { AnalysisPosition } from './fnAnalysisTypes';
import { Analysis } from 'types';

interface IFnAnalysisCardContext {
  isSameSampleIdInLeftAndRight?: boolean;
  setSampleId: (position: AnalysisPosition, sampleId?: string) => void;
  averageIsChecked: boolean;
  setAverageIsChecked: (checked: boolean) => void;
  averageAnalysisSubmitted: boolean;
  setAverageAnalysisSubmitted: (submitted: boolean) => void;
  setLastIncomingAnalyzes: (analyzes?: Analysis[]) => void;
  leftState: {
    setLeftAnalysis: (analysis?: Analysis) => void;
    leftAnalysis?: Analysis;
    lastIncomingLeftAnalysis?: Analysis;
    setLastIncomingLeftAnalysis: (analysis?: Analysis) => void;
    leftNewExtraAnalyzes: Analysis[];
    setLeftNewExtraAnalyzes: (analysis: Analysis[]) => void;
    leftOldText: string;
    setLeftOldText: (text: string) => void;
    leftText: string;
    setLeftText: (text: string) => void;
  };
  rightState: {
    setRightAnalysis: (analysis?: Analysis) => void;
    rightAnalysis?: Analysis;
    lastIncomingRightAnalysis?: Analysis;
    setLastIncomingRightAnalysis: (analysis?: Analysis) => void;
    rightNewExtraAnalyzes: Analysis[];
    setRightNewExtraAnalyzes: (analysis: Analysis[]) => void;
    rightOldText: string;
    setRightOldText: (text: string) => void;
    rightText: string;
    setRightText: (text: string) => void;
  };
}

const initContext: IFnAnalysisCardContext = {
  isSameSampleIdInLeftAndRight: false,
  setSampleId: () => null,
  setAverageIsChecked: () => null,
  averageIsChecked: false,
  averageAnalysisSubmitted: false,
  setAverageAnalysisSubmitted: () => null,
  setLastIncomingAnalyzes: () => null,
  leftState: {
    setLeftAnalysis: () => null,
    setLastIncomingLeftAnalysis: () => null,
    setLeftNewExtraAnalyzes: () => null,
    setLeftOldText: () => null,
    setLeftText: () => null,
    leftNewExtraAnalyzes: [],
    leftText: '',
    leftOldText: '',

  },
  rightState: {
    setRightAnalysis: () => null,
    setLastIncomingRightAnalysis: () => null,
    setRightNewExtraAnalyzes: () => null,
    setRightOldText: () => null,
    setRightText: () => null,
    rightNewExtraAnalyzes: [],
    rightText: '',
    rightOldText: '',
  },
};

export const FnAnalysisCardContext = createContext<IFnAnalysisCardContext>(initContext);

const FnAnalysisContextProvider = ({ children }: { children: React.ReactNode }) => {
  // Use to check id sample id is the same in left and right card
  const [sampleIdLeft, setSampleIdLeft] = useState<string | undefined>();
  const [sampleIdRight, setSampleIdRight] = useState<string | undefined>();
  const [isSameSampleIdInLeftAndRight, setIsSameSampleIdInLeftAndRight] = useState<boolean>(false);
  const [averageIsChecked, setAverageIsChecked] = useState(false);
  const [leftAnalysis, setLeftAnalysis] = useState<Analysis>();
  const [rightAnalysis, setRightAnalysis] = useState<Analysis>();
  const [averageAnalysisSubmitted, setAverageAnalysisSubmitted] = useState<boolean>(false);
  const [lastIncomingLeftAnalysis, setLastIncomingLeftAnalysis] = useState<Analysis>();
  const [lastIncomingRightAnalysis, setLastIncomingRightAnalysis] = useState<Analysis>();
  const [leftNewExtraAnalyzes, setLeftNewExtraAnalyzes] = useState<Analysis[]>([]);
  const [rightNewExtraAnalyzes, setRightNewExtraAnalyzes] = useState<Analysis[]>([]);
  const [leftOldText, setLeftOldText] = useState<string>('');
  const [rightOldText, setRightOldText] = useState<string>('');
  const [leftText, setLeftText] = useState<string>('');
  const [rightText, setRightText] = useState<string>('');

  useEffect(() => {
    if (sampleIdLeft && sampleIdRight && sampleIdLeft === sampleIdRight) {
      setIsSameSampleIdInLeftAndRight(true);
    } else {
      setIsSameSampleIdInLeftAndRight(false);
    }
  }, [sampleIdLeft, sampleIdRight]);

  const setSampleId = (position: AnalysisPosition, sampleId?: string) => {
    if (position === AnalysisPosition.LEFT) {
      setSampleIdLeft(sampleId);
    } else {
      setSampleIdRight(sampleId);
    }
  };

  const setLastIncomingAnalyzes = useCallback((analyzes?: Analysis[]) => {
    const lastLeftAnalysis = analyzes?.find(a => a.position === AnalysisPosition.LEFT);
    const lastRightAnalysis = analyzes?.find(a => a.position === AnalysisPosition.RIGHT);

    if (lastLeftAnalysis) {
      setLastIncomingLeftAnalysis(lastLeftAnalysis);
    }
    if (lastRightAnalysis) {
      setLastIncomingRightAnalysis(lastRightAnalysis);
    }
  }, []);

  const contextValue = {
      setSampleId,
      isSameSampleIdInLeftAndRight,
      averageIsChecked,
      setAverageIsChecked,
      averageAnalysisSubmitted,
      setAverageAnalysisSubmitted,
      setLastIncomingAnalyzes,
      leftState: {
        setLeftAnalysis,
        leftAnalysis,
        lastIncomingLeftAnalysis,
        setLastIncomingLeftAnalysis,
        leftNewExtraAnalyzes,
        setLeftNewExtraAnalyzes,
        leftOldText,
        setLeftOldText,
        leftText,
        setLeftText,
      },
      rightState: {
        setRightAnalysis,
        rightAnalysis,
        lastIncomingRightAnalysis,
        setLastIncomingRightAnalysis,
        rightNewExtraAnalyzes,
        setRightNewExtraAnalyzes,
        rightOldText,
        setRightOldText,
        rightText,
        setRightText,
      },
    };

  return <FnAnalysisCardContext.Provider value={contextValue}>{children}</FnAnalysisCardContext.Provider>;
};

export default FnAnalysisContextProvider;
