 import styled from '@emotion/styled';
import { Field, FieldName } from 'components/shared/Field';
import { FC } from 'react';
import { Reading } from 'types';

const CardListItem = styled.div`
  & > div {
    padding: 0 10px;
    margin: 0 -10px;
`;

interface TableReadingProps {
  reading: Reading;
  hideBorder?: boolean;
}

export const TableReading: FC<TableReadingProps> = ({
  reading, hideBorder
}) => {
  return (
    < CardListItem>
        <Field hideBorder={hideBorder} >
          <FieldName>
            <span>{reading.code} </span>
            <b>{reading.sourceName} </b>
          </FieldName>
            <strong>{reading.value}</strong>
        </Field>
      </CardListItem>
  );
};
