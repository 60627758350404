import React, { FC } from 'react';
import { Route, RouterProvider, createHashRouter, createRoutesFromElements } from 'react-router-dom';
import { Location } from './pages/Location';
import { MainPage } from './pages/MainPage';
import { Device } from './pages/Device';
import { AnalyzesWithContext } from './pages/Analyzes';
import { appInsights } from './applicationInsights';
import { Auth0Provider } from '@auth0/auth0-react';
import { configuration } from './config';
import DialogContextProvider from 'contexts/DialogContextProvider';
import { SampleContainer } from 'components/SampleContainer';
import { DialogAlert } from 'components/shared/DialogAlert';

const router = createHashRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<MainPage />} />
      <Route path="/:location" element={<Location />} />
      <Route path="/:location/:deviceId" element={<Device />} />
      <Route
        path="/:location/:deviceId/analyzes/:tab?"
        element={<AnalyzesWithContext />}
      />
    </>
  )
);
const App: FC = () => {

  appInsights.trackTrace({ message: 'App starting' });
  return (
    <React.StrictMode>
      <Auth0Provider
        domain={configuration.authDomain}
        clientId={configuration.authClientId}
        audience={configuration.authAudience}
        redirectUri={window.location.origin}
        cacheLocation="localstorage" >

        <DialogContextProvider>
          <SampleContainer>
          <RouterProvider router={router} />
          <DialogAlert />
          </SampleContainer>
        </DialogContextProvider>
      </Auth0Provider>
    </React.StrictMode>
  );
};

export default App;
