import { ReadingCode } from 'constants/readingCode';
import { Reading } from 'types';

/**
 * Returns an array of readings with the latest nit readings
 */
export const getLatestNitReadings = (readings: Reading[] | undefined): Reading[] | undefined => {
  const codes = [
    ReadingCode.NIT_WATER,
    ReadingCode.NIT_GLUTEN,
    ReadingCode.NIT_PROTEIN,
    ReadingCode.NIT_STARCH_DM,
    ReadingCode.NIT_SPECIFIC_WEIGHT,
    ReadingCode.NIT_COLOR,
  ];
  const latestNitReadings = readings?.filter(
    reading =>
      reading.code !== undefined &&
      codes.includes(reading.code as ReadingCode) &&
      reading.result !== ReadingCode.EMPTY_STRING
  );
  return latestNitReadings;
};

/**
 * Filter only required data for readings
 * @param readings
 * @returns
 */
export const filterAnalysisReadings = (readings?: Reading[]) => {
  return readings?.map(({ code, sourceName, value, outliers }) => {
    const newReading: Reading =  value !== undefined && value !== null
      ? { code, sourceName, value }
      : { code, sourceName };

    if (outliers) {
      newReading.outliers = outliers;
    }

    return newReading;
  });
};

/**
 * Sort e.g. readings on code
 */
export const sortOnReadingCode = (a: Reading, b: Reading): number => {
  const codeA = (a.code ?? '').toLowerCase();
  const codeB = (b.code ?? '').toLowerCase();
  switch (true) {
    case codeA < codeB:
      return -1;
    case codeA > codeB:
      return 1;
    default:
      return 0;
  }
};

/**
 * Get percentage of waste, rounded to one decimal
 * @param netValue
 * @param grossValue
 */
export const calculateWastePercentValue = (netValue: number, grossValue: number): number => {
  if (grossValue === 0) {
    return 0;
  }
  // Percentage rounded to one decimal
  const percent = Number(((netValue / grossValue) * 100).toFixed(1))
  return percent;
}