/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { InformationButton } from "../../InformationButton";
import { LmButtonGreen, LmButtonRed } from "../../lm2/LmButton";
import { FlexContainer, FlexItem } from "../../shared/FlexLayout";
import { NitCardButtonPanelProps } from "../models/Props";
import { LockButton } from "./LockButton";

enum NIT_CARD_BUTTON_REFS {
    LOCK = 'lock',
    INFORMATION = 'information',
    CLEAN = 'clean',
    APPROVE = 'approve',
}

const NitCardButtonPanel = forwardRef<any, NitCardButtonPanelProps>(({
    onLock,
    onLockReading,
    locked,
    saving,
    handleSubmit,
    showInformation,
    informationAvailble,
    handleClear,
    approveDisabled,
    informationDisabled,
    lockDisabled,
    focus,
    onFocus
}: NitCardButtonPanelProps, ref) => {
    const approveButtonRef = useRef<HTMLButtonElement>(null);
    const cleanButtonRef = useRef<HTMLButtonElement>(null);
    const informationButtonRef = useRef<HTMLButtonElement>(null);
    const lockButtonRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(ref, () => {
        return {
            anyDisabled(): any {
                return {
                    approve: approveButtonRef.current?.disabled,
                    clean: cleanButtonRef.current?.disabled,
                    information: informationButtonRef.current?.disabled,
                    lock: lockButtonRef.current?.disabled
                }
            }
        }
    }, [approveButtonRef, cleanButtonRef, informationButtonRef, lockButtonRef]);

    useEffect(() => {
        switch(focus) {
            case NIT_CARD_BUTTON_REFS.LOCK:
                if (lockButtonRef.current) {
                    lockButtonRef.current.focus();
                }
                break;
            case NIT_CARD_BUTTON_REFS.INFORMATION:
                if (informationButtonRef.current) {
                    informationButtonRef.current.focus();
                }
                break;
            case NIT_CARD_BUTTON_REFS.CLEAN:
                if (cleanButtonRef.current) {
                    cleanButtonRef.current.focus();
                }
                break;
            case NIT_CARD_BUTTON_REFS.APPROVE:
                if (approveButtonRef.current) {
                    approveButtonRef.current.focus();
                }
                break;
            default:
                break;
        }
    }, [focus, lockButtonRef, informationButtonRef, cleanButtonRef, approveButtonRef]);

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
        }} ref={ref}>
            <FlexContainer spacing={4}>
                {onLock && onLockReading && (
                    <LockButton
                        ref={lockButtonRef}
                        type="button"
                        onClick={() => onLock(!locked)}
                        disabled={lockDisabled()}
                        onFocus={(e) => onFocus(e, NIT_CARD_BUTTON_REFS.LOCK)}>
                    {locked ? 'Frigör analysen' : 'Lås analysen'}
                    </LockButton>)}
                <FlexItem spacing={4} style={{ marginLeft: "auto" }}>
                    <InformationButton
                        ref={informationButtonRef}
                        type="button"
                        tabIndex={100}
                        informationAvailable={informationAvailble}
                        onClick={() => showInformation(true)}
                        disabled={informationDisabled()}
                        onFocus={(e) => onFocus(e, NIT_CARD_BUTTON_REFS.INFORMATION)}>
                    Lägg till information
                    </InformationButton>
                </FlexItem>
                <FlexItem spacing={4}>
                    <LmButtonRed
                        ref={cleanButtonRef}
                        type="button"
                        onClick={handleClear}
                        tabIndex={6}
                        className="last-item"
                        onFocus={(e) => onFocus(e, NIT_CARD_BUTTON_REFS.CLEAN)}>
                    Rensa
                    </LmButtonRed>
                </FlexItem>
                <FlexItem spacing={4}>
                    <LmButtonGreen
                        ref={approveButtonRef}
                        className="approve"
                        type="submit"
                        disabled={approveDisabled}
                        inProgress={saving}
                        tabIndex={5}
                        onFocus={(e) => onFocus(e, NIT_CARD_BUTTON_REFS.APPROVE)}>
                      Godkänn
                    </LmButtonGreen>
                </FlexItem>
            </FlexContainer>
        </form>
    );
});

export {NitCardButtonPanel, NIT_CARD_BUTTON_REFS};