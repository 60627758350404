import AnalyzesContext from 'contexts/AnalyzesContext';
import { useContext } from 'react';
import { Breadcrumb } from 'components/BreadCrumb';
import { Breadcrumbs } from 'components/BreadCrumbs';
import { SampleContext } from 'components/SampleContainer';

export const BreadcrumbsForAnalyzes = () => {
  const {
    device,
    params: { deviceId },
  } = useContext(AnalyzesContext);
  const { location } = useContext(SampleContext);
  return (
    <Breadcrumbs>
      <Breadcrumb to={`/`}>Välj Ort</Breadcrumb>
      <Breadcrumb to={`/${location}`}>{location}</Breadcrumb>
      <Breadcrumb to={`/${location}/${deviceId}/analyzes`}>{device?.alias ?? deviceId}</Breadcrumb>
    </Breadcrumbs>
  );
};
