import styled from '@emotion/styled';

export const Overlay = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;
