import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

type LmListItemProps = {
  children: ReactNode;
  to: string;
};
export const LmListItem: FC<LmListItemProps> = ({ children, to }) => {
  return (
    <article key={to} className="lm__news-item">
      <Link to={to}>
        <h4 className="article-title right-icon-pos">
          {children}
          <i className="icon  icon--arrow-right" aria-hidden="true"></i>
        </h4>
      </Link>
    </article>
  );
};
