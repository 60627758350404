import { createContext,  useMemo, useState } from "react";
import { Analysis, TableSample } from "types";

interface IAnalyzesTableContext {
  analyzes: Analysis[];
  setAnalyzes: (analyzesParm: Analysis[]) => void;
  tableItems: TableSample[];
  setTableItems: (samplesParm: TableSample[]) => void;
}

const initState: IAnalyzesTableContext = {
  analyzes: [],
  setAnalyzes: () => null,
  tableItems: [],
  setTableItems: () => null,
};

interface TableContextProviderProps {
  children: React.ReactNode;
}

export const AnalyzesTableContextProvider = ({ children }: TableContextProviderProps) => {
  const [analyzes, setAnalyzes] = useState<Analysis[]>([]);
  const [tableItems, setTableItems] = useState<TableSample[]>([]);

  const values = useMemo(() => ({
    analyzes, setAnalyzes,
    tableItems, setTableItems,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [ analyzes, tableItems]);

  return (
    <AnalyzesTableContext.Provider value={values}>
      {children}
    </AnalyzesTableContext.Provider>
  );
};

const AnalyzesTableContext = createContext<IAnalyzesTableContext>(initState);
export default AnalyzesTableContext;
