import React, { ChangeEvent, useContext } from 'react';
import TableContext from '../TableContext';
import { Grid} from '@mui/material';
import { StyledTextField } from 'components/shared/styleMuiComponents';


const SampleIdInput: React.FC = () => {
  const { filterValues: { sampleId }, setFilterValues } = useContext(TableContext);
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterValues(current => ({
      ...current,
      sampleId: e.target.value
    }));
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <StyledTextField
        label="Prov ID"
        value={sampleId}
        onChange={onChange}
      />
    </Grid>
  );
};

export default SampleIdInput;