import styled from '@emotion/styled';
import { FcWithChildren } from 'types/fcWithChildren';

const PageHeadRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  color: #009636;
`;

export const PageHead: FcWithChildren = ({ children }) => {
  return <PageHeadRow>{children}</PageHeadRow>;
};
