import { Analysis } from "../types";

export const haveCommonReadings = (analyzes: Analysis[] | undefined, analyzesToCompare: Analysis[] | undefined): boolean => {
    if (analyzes === undefined || analyzesToCompare === undefined) {
        return false
    }
    return analyzes.some(analysis =>
      analysis.readings?.some(reading =>
        analyzesToCompare.some(analysisToCompare =>
          analysisToCompare.readings?.some(readingToCompare =>
            reading.code === readingToCompare.code
          )
        )
      )
    );
  }