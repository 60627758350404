import { useEffect, useState } from "react";
import { Facility } from "types";
import useFetch from "./useFetch";

export const useFetchFacilities = () => {
  const { getDefaultBackend } = useFetch();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFacilities = async () => {
      setIsLoading(true);
      const response = await getDefaultBackend(`/api/GetLocations?onlyActive=true`);
      if (!response) {
        setFacilities([]);
        return;
      }
      const fetchedFacilities: Facility[] = await response.json();
      fetchedFacilities?.sort((facilityA, facilityB) => (facilityA.name > facilityB.name ? 1 : -1));
      setFacilities(fetchedFacilities);
      setIsLoading(false);
    };

    fetchFacilities();
  }, [getDefaultBackend]);

  return { facilities, isLoading };
};