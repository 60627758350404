import { useState, useEffect } from 'react';
import { SupportItem } from '../types';
import { configuration } from "../config";
import useFetch from './useFetch';
import { IsSuccessCode } from 'utils/fetchUtils';
import { SupportType } from 'types/supportType';
const { backendBaseUrl } = configuration;

export const useSupport = (supportType: SupportType) => {
  const [supportItems, setSupportItems] = useState<SupportItem[]>();
  const [supportTypeState, setSupportTypeState] = useState<string>();
  useEffect(() => {
    setSupportTypeState(supportType);
  }, [supportType]);

  const { get } = useFetch();

  useEffect(() => {
    (async () => {
      if (!supportItems && supportTypeState) {
        const response = await get(`${backendBaseUrl}/api/support/${supportTypeState}`);

        if (!response || !IsSuccessCode(response)) {
          setSupportItems([]);
          return;
        }
        const responseItems = await response?.json();
        setSupportItems(responseItems);
      }
    })();
  }, [supportTypeState, supportItems, get]);
  return supportItems ?? [];
};
