import styled from '@emotion/styled';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: ReactNode;
}

const ErrorMessageDiv = forwardRef(({ children, className, ...props }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div {...props} className={className + ' lm__block'} ref={ref}>
      {children}
    </div>
  );
});

export const ErrorMessage = styled(ErrorMessageDiv)`
    border: 2px solid #ff5722;
    background-color: #ffa270;
    padding: 5px 10px;
    margin-bottom: 10px;
`;