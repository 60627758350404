import { PublishMessage, Sample } from '../types';
import { configuration } from '../config';
import useFetch from './useFetch';
import { PostOptions } from 'classes/FetchOptions';
import { useState } from 'react';
import { SampleMessage } from 'constants/messageTypes';

const { backendBaseUrl } = configuration;

export type UseGetSampleReturnType = {
  getSample: (sampleId: string | undefined) => Promise<void>;
  resetSampleValues: () => void;
  sample: Sample | undefined;
  loading: boolean;
  triedToFetchSample: boolean;
  sampleMessages: PublishMessage[];
  showM3ConnectionError: boolean | undefined;
};

export const useGetSample = (): UseGetSampleReturnType => {
  const { get, post, isServerErrorResponse, isClientErrorResponse } = useFetch();
  const [loading, setLoading] = useState(false);
  const [showM3ConnectionError, setShowM3ConnectionError] = useState<boolean>();
  const [triedToFetchSample, setTriedToFetchSample] = useState<boolean>(false);
  const [sample, setSample] = useState<Sample>();
  const [sampleMessages, setSampleMessages] = useState<PublishMessage[]>([]);

  const controller = new AbortController();
  const { signal } = controller;

  const resetSampleValues = () => {
    controller.abort();
    setSample(undefined);
    setSampleMessages([]);
    setShowM3ConnectionError(false);
    setLoading(false);
    setTriedToFetchSample(false);
  };

  const handleGetSampleResponse = async (response: Response | null): Promise<Sample | undefined> => {
    if (!response || isServerErrorResponse(response)) {
      setShowM3ConnectionError(true);
      setLoading(false);
      return;
    }

    if (isClientErrorResponse(response)) {
      setTriedToFetchSample(true);
      setLoading(false);
      return;
    }

    return await response.json()
  };

  const getSample = async (sampleId: string | undefined): Promise<void> => {
    if (!sampleId) {
      setSample(undefined);
      return;
    }

    setLoading(true);
    setShowM3ConnectionError(false);

    const response = await get(`${backendBaseUrl}/api/samples/${sampleId}`, signal);

    const fetchedSample = await handleGetSampleResponse(response)

    setTriedToFetchSample(true);

    if (!fetchedSample) {
      setSample(undefined);
      return;
    }

    setSample(fetchedSample);
    const messagesBody = {
      type: SampleMessage,
      data: fetchedSample,
    };
    const messagesResponse = await post(
      `${backendBaseUrl}/api/messages`,
      new PostOptions(JSON.stringify(messagesBody))
    );
    if (messagesResponse?.ok) {
      const fetchedMessages: PublishMessage[] = await messagesResponse.json();
      setSampleMessages(fetchedMessages);
    }
    setLoading(false);
  };

  const returnObj: UseGetSampleReturnType = {
    getSample,
    resetSampleValues,
    sample,
    loading,
    triedToFetchSample,
    sampleMessages,
    showM3ConnectionError,
  };

  return returnObj;
};
