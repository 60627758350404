import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { Sample } from '../../types';

const ErrorMessage = styled.div`
  border: 2px solid #ff5722;
  background-color: #ffa270;
  padding: 5px 10px;
  margin-bottom: 10px;
`;

interface Props {
  sample?: Sample;
  sampleId?: string;
  triedToFetchSample?: boolean;
  getSample: (sampleId: string | undefined) => Promise<void>;
}

export const SampleErrorMessage = ({ sample, sampleId, triedToFetchSample, getSample }: Props) => {
  const handleButtonClick = () => {
    getSample(sampleId);
  };
  return (
    <>
      {sampleId && triedToFetchSample && !sample?.articleNumber ? (
        <ErrorMessage style={{ display: 'flex', alignItems: 'center' }}>
          Anmodan saknas för:
          <strong style={{ marginLeft: '5px' }}>{sampleId}</strong>
          <IconButton
            onClick={handleButtonClick}
            size="small"
            style={{ marginLeft: 'auto', fontSize: '14px', color: '#009fe3' }}
          >
            Hämta igen
            <ReplayIcon />
          </IconButton>
        </ErrorMessage>
      ) : null}
    </>
  );
};
