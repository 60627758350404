import Tooltip from '@mui/material/Tooltip';
import { Reading } from '../types';
import { Field, FieldName } from './shared/Field';
import { requiredReadingTooltipText } from 'constants/readingTooltipStrings';

interface Props {
  reading: Reading | undefined;
}

export const IncompleteReading = ({ reading }: Props) => {
  return (
    <Field>
      <FieldName color="gray">
        {reading?.required === true ? (
          <Tooltip title={requiredReadingTooltipText} placement="bottom-start">
          <div>
            <strong>{reading?.code} </strong>
            <strong>{reading?.sourceName}</strong>
          </div>
          </Tooltip>
        ) :
          <div>
            <span>{reading?.code} </span>
            <span>{reading?.sourceName}</span>
          </div>
        }
      </FieldName>
    </Field>
  );
};
