import { FC } from 'react';
import { Field, FieldName, FieldValue } from '../../shared/Field';
import { FlexItem } from '../../shared/FlexLayout';
import { InformationContainerProps } from '../models/Props';

export function InformationContainer({
  width,
  spacing,
  analysis,
  sample,
}: InformationContainerProps) {
  return (
    <FlexItem width={width} spacing={spacing}>
      <FieldContent
        content={
          <><b style={{ marginRight: '1rem' }}> {analysis?.calibration}</b><> {sample?.grainType}</></>
        }
      />
      <FieldContent name="Inläst:" content={<>{analysis?.timestamp}</>} />
      <FieldContent name="Lab:" content={<>{sample?.lab}</>} />
      <FieldContent name="Analyspaket:" content={<>{sample?.analysisPackage}</>} />
      <FieldContent name="Artikelnamn:" content={<b>{sample?.articleName}</b>} />
    </FlexItem>
  );
}

const FieldContent: FC<{ content: JSX.Element; name?: string }> = ({ name, content }) => {
  return (
    <Field>
      <FieldName>{name}</FieldName>
      <FieldValue>{content}</FieldValue>
    </Field>
  );
};
