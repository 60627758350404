import { useEffect, FC, useContext } from 'react';
import { TableSample } from '../../types';
import { AnalyzesTable } from './AnalyzesTable';
import AnalyzesTableContext, { AnalyzesTableContextProvider } from './AnalyzesTableContext';
import { sortOnDate } from 'utils/dateUtils';
import TableContext, { TableContextProvider } from 'components/table/TableContext';
import { TableFilter } from 'components/table/tableFilter/TableFilter';
import { useFetchAnalysis } from 'hooks/useFetchAnalysis';
import LoadMoreButton from './LoadMoreButton';

export const AnalyzesTableContainer = () => {
  const { getAnalyzes } = useFetchAnalysis();
  const {
    analyzes,
    setAnalyzes,
    setTableItems,
  } = useContext(AnalyzesTableContext);

  const {
    readyForInitFetch,
    filterValues,
    setFetchState,
    setIsLoading,
    getFilterValues,
    restTableState
  } = useContext(TableContext);

  useEffect(() => {
    if (!analyzes) return;

    const tableItems = analyzes.reduce((result: TableSample[], analysis) => {
      const existingSample = result.find(
        (sample) => sample.id === analysis.sampleId
      );

      if (!existingSample) {
        const newSample: TableSample = {
          id: analysis.sampleId,
          articleNumber: analysis.articleNumber,
          articleName: analysis?.articleName ?? analysis.description,
          analyzes: [analysis],
        };
        return [...result, newSample];
      }

      existingSample.analyzes.push(analysis);
      return result;
    }, [])

    // Set firstCreatedAnalysis on each sample
    tableItems.forEach((item) => {
      const firstCreated = [...item.analyzes].sort((a,b) => sortOnDate(a.approvedDate, b.approvedDate));
      item.firstCreatedAnalysis = firstCreated[0];
    });

    // Sort table items on firstCreatedAnalysis
    const sortedTableItems = [...tableItems].sort((a, b) =>
    sortOnDate(a.firstCreatedAnalysis?.approvedDate, b.firstCreatedAnalysis?.approvedDate, filterValues.sortOrder));

    setTableItems(sortedTableItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyzes, filterValues.sortOrder]);

  useEffect(() => {
    // Clear context when component unmounts
    return () => {
      setAnalyzes([]);
      restTableState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetchAnalyzes = async () => {
    setIsLoading(true);
    try {
      const response = await getAnalyzes(getFilterValues());
      setAnalyzes(response.analyzes);
      setFetchState({
        hasMoreResults: response.hasMoreResults ?? false,
        continuationToken: response.continuationToken,
      });
    } catch (error) {
      setAnalyzes([]);
      setFetchState({ hasMoreResults: false });
    } finally {
      setIsLoading(false);
    }
  }

  // Init fetch and Refresh table when sortOrder changes
  useEffect(() => {
    if(readyForInitFetch){
      fetchAnalyzes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues.sortOrder, readyForInitFetch]);

  return (
    <>
      <TableFilter
        fetchTableItems={fetchAnalyzes}
        componentsToRender={[
          'facility', 'deviceType', 'datePickers', 'sampleId']}
      />
      <AnalyzesTable />
      <LoadMoreButton />
    </>
  );
};

export const AnalyzesTableWithContext: FC = () => {
  return (
    <TableContextProvider >
      <AnalyzesTableContextProvider >
        <AnalyzesTableContainer />
      </AnalyzesTableContextProvider>
    </TableContextProvider>
  );
};