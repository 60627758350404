import { ChangeEvent } from 'react';
import { Analysis, Reading } from '../../types';
import { Field, FieldName, FieldValue } from '../shared/Field';
import {GrFormEdit} from 'react-icons/gr';
import { TiDelete } from 'react-icons/ti';

interface Props {
  reading?: Reading;
  analysis?: Analysis;
  deleteAnalysis?:(code: string)=> void
  completeFlag: boolean | undefined;
  isEditing: boolean;
  inputValue?: number;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onMouseClick: () => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>)=> void;
  onClickEditing: () => void;
}

export const Readings = ({
  reading,
  completeFlag,
  isEditing,
  deleteAnalysis,
  inputValue,
  handleInputChange,
  onMouseClick,
  onKeyDown,
  onClickEditing
}: Omit<Props, 'analysis'>) => {

  return (
    <Field key={`List${reading?.code?.toString()}`}>
      <FieldName color='#009fe3'>
        <span>{reading?.code} </span>
        <b> {reading?.sourceName}</b>
      </FieldName>
      {
        (reading?.value !== undefined  || reading?.editable) ? (
          <FieldValue color='#009fe3' style={{display:'contents'}}>
            { reading?.editable && isEditing ? (
              <input
                type='number'
                onWheel={event => event.currentTarget.blur()}
                id='all-analysis'
                name='all-analysis'
                value={inputValue}
                onBlur={onMouseClick}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
                required
                autoFocus
                tabIndex={2}
                className='first-item'
                style={{height:'10px', maxWidth:'fit-content'}}
                /> ) : <strong>{reading?.defaultValue ? reading.defaultValue: reading.value} </strong>
            }
            <>
            {
              !completeFlag && reading?.editable && !isEditing ? (
                <GrFormEdit onClick={() => onClickEditing()} cursor={'pointer'} style={{marginLeft:'10px', color:'lightBlue'}}/>
              ) : null
            }
            </>
          </FieldValue>
        ) : null
      }
      {
      reading?.analysisId
        ? null
        : (
          <TiDelete
            onClick={() => {(deleteAnalysis && reading?.code) && deleteAnalysis(reading?.code)}}
            cursor={'pointer'}
            style={{marginLeft:'10px', color:'red'}}
          />
        )
      }
    </Field>
  )
};
