import { LmButtonGreen, LmButtonRed } from './lm2/LmButton';
import { Analysis } from '../types';
import { useEffect, useState, FC, useContext, ChangeEvent } from 'react';
import { FlexContainer, FlexItem } from './shared/FlexLayout';
import { LinearLoader } from './shared/LinearLoader';
import { Card, CardBody, CardHeader, CardRow } from './shared/Card';
import { Field, FieldName, FieldValue } from './shared/Field';
import { OtherAnalyzes } from './OtherAnalyzes';
import { ExtraAnalyzes } from './ExtraAnalyzes/ExtraAnalyzes';
import { SampleErrorMessage } from './shared/ErrorMessage';
import { useParams } from 'react-router-dom';
import { ExtraInfo } from './ExtraInfo';
import { InformationButton } from './InformationButton';
import { ReadingCode } from '../constants/readingCode';
import { isGrossNetAmountCorrect } from '../functions/isGrossNetAmountCorrect';
import { BannerMessageContainer } from './BannerMessages/BannerMessageContainer';
import { ErrorContainer } from './ErrorContainer';
import { Overlay } from './shared/Overlay';
import useDialog from 'hooks/useDialog';
import { RESET_VALUES_DIALOG_BODY, RESET_VALUES_DIALOG_TITLE } from 'constants/resetValuesStrings';
import { EXISTING_ANALYSIS_DIALOG_TITLE } from 'constants/existingAnalysisDialogTitle';
import { generateExistingAnalysisDialogString } from 'functions/generateExistingAnalysisDialogString';
import { DeviceType } from 'constants/deviceTypes';
import AnalyzesContext from 'contexts/AnalyzesContext';
import { WasteTypes } from 'constants/wasteTypes';
import { isApproveDisabled } from 'functions/isApproveDisabled';
import { filterAnalysisReadings } from 'utils/readingUtils';
import useSubmitAnalyzes from 'hooks/useSubmitAnalyzes';
import { SampleInformation } from './sampleInfo/SampleInfo';
import { BASIC_INPUT_REFS, BasicInputContainer } from './shared/inputs/BasicInputContainer';
import useNavigationBlocker from 'hooks/useNavigationBlocker';
import { DivTopPadding } from './shared/DivWithTopPadding';
import { UseGetSampleReturnType } from 'hooks/useGetSample';

const SIGNATURE_ELEMENT_ID = BASIC_INPUT_REFS.SIGN;

type ScaleCardProps = UseGetSampleReturnType & {
  analysis?: Analysis;
};

const defaultInputValues = {
  articleNumber: '',
  sampleId: '',
  signature: '',
};

export const ScaleCard: FC<ScaleCardProps> = ({ analysis,sample,
  getSample,
  resetSampleValues,
  loading,
  triedToFetchSample,
  sampleMessages,
  showM3ConnectionError}) => {
  const [focus, setFocus] = useState<string>(SIGNATURE_ELEMENT_ID);
  const [inputValue, setInputValue] = useState({ ...defaultInputValues });
  const [showModal, setShowModal] = useState<boolean>(false);
  const { location } = useParams<LocationType>();
  const { showDialog } = useDialog();
  const { setIncomingAnalyzes, resetAnalyzesState, oldText, setOldText, text, setText, newExtraAnalyzes, setNewExtraAnalyzes } = useContext(AnalyzesContext);
  const { errorMessages, isSaving, setErrorMessages, saveAnalyzes } = useSubmitAnalyzes();

  const grossReadingValue = analysis?.readings?.find(reading => reading.sourceName === WasteTypes.GROSS_SOURCE_NAME)?.value;
  const netReadingValue = analysis?.readings?.find(reading => reading.sourceName === WasteTypes.NET_SOURCE_NAME)?.value;

  const navigationBlockingConditions = [
    analysis !== undefined,
    newExtraAnalyzes.length !== 0,
    text !== oldText
  ]
  useNavigationBlocker(navigationBlockingConditions);

  type LocationType = {
    location: string;
  };

  const resetValues = () => {
    setInputValue({ ...defaultInputValues });
    setNewExtraAnalyzes([]);
    resetAnalyzesState();
    setOldText('');
    setText('');
    resetSampleValues();
    setErrorMessages([]);
  };

  const handleBlurSampleId = async () => {
    if (inputValue?.sampleId === sample?.id) {
      return;
    }

    getSample(inputValue?.sampleId);
  };

  const handleBlurSignature = async () => {
      setFocus('');
  };

  useEffect(() => {
    const sampleText = sample?.text ?? '';
    setInputValue({ ...inputValue, articleNumber: sample?.articleNumber ?? '' });
    setOldText(sampleText);
    setText(sampleText);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sample]
  )

  // This is a workaround to focus the first and last input fields when tabbing
  const focusGuard1 = document.querySelector('#focusguard-1');
  const focusGuard2 = document.querySelector('#focusguard-2');
  focusGuard1?.addEventListener('focus', () => {
    document.querySelector<HTMLInputElement>('.last-item')?.focus();
  });
  focusGuard2?.addEventListener('focus', () => {
    document.querySelector<HTMLInputElement>('.first-item')?.focus();
  });

  /**
   * @returns Submit all the analysis
   */
  const handleSubmit = async () => {
    document.querySelector<HTMLInputElement>('.first-item')?.focus();

    if (
      analysis &&
      sample?.readings?.some(reading =>
        reading.location === analysis?.location
        && reading.code === ReadingCode.AF_WASTE)
    ) {
      showDialog(
        EXISTING_ANALYSIS_DIALOG_TITLE,
        generateExistingAnalysisDialogString(sample.id, location, DeviceType.VAG),
        () => submitAnalyzes(),
        false)
    } else {
      submitAnalyzes()
    }
  };

  const submitAnalyzes = async (): Promise<void> => {
    const analyzesToSave: Analysis[] = newExtraAnalyzes.map(newAnalysis => {
      return {
        ...newAnalysis,
        readings: filterAnalysisReadings(newAnalysis.readings),
      };
    })

    analysis && analyzesToSave.push(analysis);

    const saveIsSuccess = await saveAnalyzes(analyzesToSave, inputValue, location, text, oldText, sample);

    if (saveIsSuccess) {
      resetValues();
      setIncomingAnalyzes([])
      document.querySelector<HTMLInputElement>('.first-item')?.focus();
    }
  };

  const handleClear = () => {
    document.querySelector<HTMLInputElement>('.first-item')?.focus();
    showDialog(RESET_VALUES_DIALOG_TITLE, RESET_VALUES_DIALOG_BODY, resetValues, true)
  };

  const handleSignatureAndSampleIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue({ ...inputValue, [event.target.name]: event.target.value });
  };

  const enableApproveConditions: boolean[] = [
    (analysis !== undefined && isGrossNetAmountCorrect(grossReadingValue, netReadingValue))
  ]
  const isApproveButtonDisabled = isApproveDisabled({
    sample,
    newExtraAnalyzes,
    inputValue,
    text,
    oldText,
    isSaving,
    enableConditions: enableApproveConditions
  });

  useEffect(() => {
    if (
      !isApproveButtonDisabled &&
      showModal === false &&
      focus !== SIGNATURE_ELEMENT_ID) {
      document.querySelector<HTMLInputElement>('.approve')?.focus();
    }

  }, [isApproveButtonDisabled, focus, showModal]);

  return (
    <Card>
      {showModal && (
        <ExtraInfo
          sample={sample}
          newExtraAnalyzes={newExtraAnalyzes}
          setNewExtraAnalyzes={setNewExtraAnalyzes}
          location={location}
          onClose={() => setShowModal(false)}
          setText={setText}
          sampleText={text}
        />
      )}
      {isSaving ? (
        <Overlay>
          <LinearLoader />
        </Overlay>
      ) : null}
      {loading ? <LinearLoader /> : null}
      <div id="focusguard-1" tabIndex={1}></div>
      <CardHeader>
        <BannerMessageContainer sampleMessages={sampleMessages} />
        <SampleErrorMessage
          sample={sample}
          sampleId={inputValue?.sampleId}
          triedToFetchSample={triedToFetchSample}
          getSample={getSample}
        />
        <ErrorContainer
          analysis={analysis}
          connectionError={showM3ConnectionError}
          sample={sample}
          analysisErrors={errorMessages}
          grossNetError={
            !isGrossNetAmountCorrect(grossReadingValue, netReadingValue) && netReadingValue !== undefined
          }
        />

        <FlexContainer>
          <BasicInputContainer
            inputValue={inputValue}
            handleSignatureChange={handleSignatureAndSampleIdChange}
            handleIDChange={handleSignatureAndSampleIdChange}
            onBlurSampleId={handleBlurSampleId}
            onBlurSignature={handleBlurSignature}
            focus={SIGNATURE_ELEMENT_ID}
            onFocus={(_ev, id) => {setFocus(id)}}
          />
          <SampleInformation analysis={analysis} sample={sample} />
        </FlexContainer>
      </CardHeader>
      <CardBody>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <FlexItem width={50}>
            <h3>Värde från våg:</h3>
          </FlexItem>
          <Field>
            <FieldName>Vikt brutto</FieldName>
            <FieldValue>{grossReadingValue?.toFixed(1) ?? ''}</FieldValue>
          </Field>

          <Field>
            <FieldName>Vikt netto</FieldName>
            <FieldValue>{netReadingValue?.toFixed(1) ?? ''}</FieldValue>
          </Field>
          <Field>
            <FieldName>
              <strong>Vikt %</strong>
            </FieldName>
            <FieldValue>
              <strong>
                {analysis?.readings?.find(reading => reading.code === ReadingCode.AF_WASTE)?.value}
              </strong>
            </FieldValue>
          </Field>
          <CardRow>
            <InformationButton
              informationAvailable={text ? true : false}
              type="button"
              tabIndex={100}
              onClick={() => setShowModal(true)}
              disabled={!sample || sample.completed}
            >
              Lägg till information
            </InformationButton>
            <FlexItem spacing={4} style={{ marginLeft: 'auto' }}>
              <LmButtonRed type="button" onClick={handleClear} tabIndex={6} className="last-item">
                Rensa
              </LmButtonRed>
            </FlexItem>
            <FlexItem spacing={4}>
              <LmButtonGreen
                className="approve"
                type="submit"
                tabIndex={5}
                disabled={isApproveButtonDisabled}
                inProgress={isSaving}
              >
                Godkänn
              </LmButtonGreen>
            </FlexItem>
          </CardRow>
        </form>
        {sample && (
          <DivTopPadding>
            <OtherAnalyzes sample={sample} />
          </DivTopPadding>
        )}
        <ExtraAnalyzes sample={sample} newExtraAnalyzes={newExtraAnalyzes} setNewExtraAnalyzes={setNewExtraAnalyzes} />
      </CardBody>
      {loading ? <LinearLoader /> : null}
      <div id="focusguard-2" tabIndex={13}></div>
    </Card>
  );
};
