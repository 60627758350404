import { createContext, useMemo, useState } from "react";
import IContextProps from "interfaces/IContextProps";
import { Analysis, IDevice } from "types";

export type AnalyzesParmasType = {
  deviceId?: string;
  tab?: string;
  location?: string;
};

interface IAnalyzesContext {
  device?: IDevice,
  setDevice: (device?: IDevice) => void
  params: AnalyzesParmasType
  setParams: (params: AnalyzesParmasType) => void
  incomingAnalyzes: Analysis[],
  setIncomingAnalyzes: (analyzes: Analysis[]) => void,
  addAnalysisToIncomingAnalyzes: (analysis: Analysis) => void,
  lastIncomingAnalysis?: Analysis,
  resetAnalyzesState: () => void,
  newExtraAnalyzes: Analysis[],
  setNewExtraAnalyzes: (analyzes: Analysis[]) => void,
  oldText: string;
  setOldText: (text: string) => void;
  text: string;
  setText: (text: string) => void;
}

const initState: IAnalyzesContext = {
  device: undefined,
  incomingAnalyzes: [],
  setDevice: () => null,
  params: {
    deviceId: '',
    tab: ''
  },
  setParams: () => null,
  addAnalysisToIncomingAnalyzes: () => null,
  setIncomingAnalyzes: () => null,
  resetAnalyzesState: () => null,
  newExtraAnalyzes: [],
  setNewExtraAnalyzes: () => null,
  oldText: '',
  setOldText: () => null,
  text: '',
  setText: () => null,
};



export const AnalyzesContextProvider = (children: IContextProps) => {
  const [deviceState, setDeviceState] = useState<IDevice>();
  const [paramsState, setParamsState] = useState<AnalyzesParmasType>(initState.params);
  const [incomingAnalyzes, setIncomingAnalyzesState] = useState<Analysis[]>([]);
  const [newExtraAnalyzes, setNewExtraAnalyzes] = useState<Analysis[]>([]);
  const [oldText, setOldText] = useState('');
  const [text, setText] = useState('');

  const lastIncomingAnalysis = useMemo(
    () => incomingAnalyzes && incomingAnalyzes[incomingAnalyzes.length - 1],
    [incomingAnalyzes]
  );

  const resetAnalyzesState = () => {
    setIncomingAnalyzes([]);
  };

  const setIncomingAnalyzes = (analyzes: Analysis[]) => {
    setIncomingAnalyzesState(analyzes);
  }

  const addAnalysisToIncomingAnalyzes = (analysis: Analysis) => {
    setIncomingAnalyzesState(currentAnalyzes => {
      const currentAnalysis = currentAnalyzes.find(item => item);
      const hasEqualEventId =
        (currentAnalysis?.eventId && currentAnalysis?.eventId === analysis.eventId) ||
        (currentAnalysis?.sampleId && currentAnalysis.sampleId === analysis.sampleId);

      return hasEqualEventId ? [...currentAnalyzes, analysis] : [analysis];
    });
  }

  const incomingAnalyzesMemo = useMemo(() => incomingAnalyzes, [incomingAnalyzes]);

  const setDevice = (device?: IDevice) => {
    setDeviceState(device);
  }

  const setParams = (params: AnalyzesParmasType) => {
    setParamsState(params);
  }

  const values = {
    device: deviceState,
    params: paramsState,
    setDevice,
    setParams,
    incomingAnalyzes: incomingAnalyzesMemo,
    addAnalysisToIncomingAnalyzes,
    setIncomingAnalyzes,
    lastIncomingAnalysis,
    resetAnalyzesState,
    newExtraAnalyzes,
    setNewExtraAnalyzes,
    oldText,
    setOldText,
    text,
    setText,
  };

  return (
    <AnalyzesContext.Provider value={values}>
      {children.children}
    </AnalyzesContext.Provider>
  );
};

const AnalyzesContext = createContext<IAnalyzesContext>(initState);
export default AnalyzesContext;
