import React, { useContext, useEffect } from 'react';
import { Grid, Select, MenuItem, Box, SelectChangeEvent, OutlinedInput } from '@mui/material';
import TableContext from '../TableContext';
import { useSupport } from 'hooks/useSupport';
import { Checkbox, StyledFormControl, StyledInputLabel } from 'components/shared/styleMuiComponents';
import { SupportType } from 'types/supportType';

export const ReadingCodeSelect: React.FC = () => {
  const SELECT_ALL = "selectAll";
  const [allSelected, setAllSelected] = React.useState<boolean>(false);
  const readingNames = useSupport(SupportType.READING_NAME);
  const {
    filterValues: {
      chosenReadingCodes,
    },
    setFilterValues,
  } = useContext(TableContext);

  const handleOnChange = (e: SelectChangeEvent<string[]>) => {
    const selectedItems = e.target.value as string[];

    if (selectedItems.some(x => x === SELECT_ALL)) {
      const devicesToSet = (allSelected === false ? readingNames : []).map((item) => item.value);
      setFilterValues(current => ({
        ...current,
        chosenReadingCodes: devicesToSet?.length > 0 ? devicesToSet : undefined
      }));
      return;
    }
    setFilterValues(current => ({
      ...current,
      chosenReadingCodes: selectedItems?.length > 0 ? selectedItems : undefined,
    }));
  };

  useEffect(() => {
      setAllSelected(readingNames?.length === chosenReadingCodes?.length);
  }, [readingNames?.length, chosenReadingCodes?.length]);

  const getRenderValue = () => {
    if (!chosenReadingCodes?.length){
      return;
    }
    const MAX_LENGTH = 18;
    const displayText = chosenReadingCodes.join(', ');
    const truncatedText = displayText.length > MAX_LENGTH
      ? `${displayText.slice(0, 15)}...`
      : displayText;

    return <Box>{truncatedText}</Box>;
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} >
      <StyledFormControl sx={{ minWidth: 211 }}  >
      <StyledInputLabel id="readingCode-label">Analysklass</StyledInputLabel>
      <Select
        labelId="readingCode-label"
        fullWidth
        multiple
        input={<OutlinedInput  label="Analysklass" />}
        value={chosenReadingCodes ?? []}
        renderValue={getRenderValue}
        onChange={handleOnChange}
        disabled={!readingNames?.length}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '50vh', // maximum height of menu items
            },
          },
        }}
        >
          <MenuItem key={SELECT_ALL} value={SELECT_ALL}>
            <Checkbox checked={allSelected} />
            {"Välj alla"}
          </MenuItem>
        {[...readingNames]?.sort((a, b) => a.id > b.id ? 1 : -1).map((item) => (
          <MenuItem key={item.id} value={item.value}>
            <Checkbox checked={(chosenReadingCodes ?? [])?.includes(item.value)} />
            {item.name} - {item.value}
          </MenuItem>
        ))}
      </Select>
        </StyledFormControl>
    </Grid>
  )
};
