
import React, { useContext, useEffect, useState } from 'react';
import AnalyzesTableContext from './AnalyzesTableContext';
import { LmButtonBlue } from 'components/lm2/LmButton';
import { LinearLoader } from 'components/shared/LinearLoader';
import { useFetchAnalysis } from 'hooks/useFetchAnalysis';
import styled from '@emotion/styled';
import TableContext from 'components/table/TableContext';

const LoadMoreButtonStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const LoadMoreButton: React.FC = () => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { getAnalyzes } = useFetchAnalysis();
  const buttonText = 'Ladda fler';
  const {
    analyzes,
    setAnalyzes,
  } = useContext(AnalyzesTableContext);

  const {
    isLoading,
    fetchState: { hasMoreResults, continuationToken },
    setFetchState,
    filterValues: {
      page,
    },
    setFilterValues,
    getFilterValues
  } = useContext(TableContext);

  useEffect(() => {
    const fetchAnalyzes = async () => {
      setIsLoadingMore(true);
      try {
        const response = await getAnalyzes(getFilterValues(continuationToken));

        const combinedAnalyzes = [...analyzes, ...response.analyzes];
        const uniqueAnalyzesById = [
          ...new Map(combinedAnalyzes.map(item => [item.id, item])).values()
        ];

        setAnalyzes(uniqueAnalyzesById);
        setFetchState({
          hasMoreResults: response?.hasMoreResults ?? false,
          continuationToken: response.continuationToken,
        });
        // eslint-disable-next-line no-debugger
      } catch (error) {
        setAnalyzes([]);
      }
      finally {
        setIsLoadingMore(false);
      }
    };

    if (continuationToken) {
      fetchAnalyzes();
    }

    // Only run on when page changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onClick = () => {
    setFilterValues(current => ({
      ...current,
      page: current.page + 1
    }));
  };

  return (
    <LoadMoreButtonStyled>
      <LmButtonBlue
        disabled={(isLoadingMore || isLoading) || !hasMoreResults}
        onClick={onClick}>
        {buttonText}
        {isLoadingMore && <LinearLoader />}
      </LmButtonBlue>
    </LoadMoreButtonStyled>
  );
};

export default LoadMoreButton;
