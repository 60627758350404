import { Dispatch, SetStateAction, createContext, useMemo, useState } from "react";
import { SampleWithMissingAnalyzes } from "types/sampleWithMissingAnalyzes";

interface IMissingTableContext {
  tableItems: SampleWithMissingAnalyzes[];
  setTableItems: Dispatch<SetStateAction<SampleWithMissingAnalyzes[]>>;
}

const initState: IMissingTableContext = {
  tableItems: [],
  setTableItems: () => null,
};

interface TableContextProviderProps {
  children: React.ReactNode;
}

export const MissingAnalyzesContextProvider = ({ children }: TableContextProviderProps) => {
  const [tableItems, setTableItems] = useState<SampleWithMissingAnalyzes[]>([]);

  const values = useMemo(() => ({
    tableItems, setTableItems,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [tableItems]);

  return (
    <MissingAnalyzesContext.Provider value={values}>
      {children}
    </MissingAnalyzesContext.Provider>
  );
};

const MissingAnalyzesContext = createContext<IMissingTableContext>(initState);
export default MissingAnalyzesContext;
