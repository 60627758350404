import styled from '@emotion/styled';
import { LmButtonGreen, LmButtonRed } from '../lm2/LmButton';
import { Analysis, InputValueProps } from 'types';
import { useEffect, useState, FC, useContext, ChangeEvent } from 'react';
import { FlexContainer, FlexItem } from '../shared/FlexLayout';
import { LinearLoader } from '../shared/LinearLoader';
import { Card, CardBody, CardHeader, CardRow } from '../shared/Card';
import { Overlay } from '../shared/Overlay';
import { Field, FieldName, FieldValue } from '../shared/Field';
import { OtherAnalyzes } from '../OtherAnalyzes';
import { ExtraAnalyzes } from '../ExtraAnalyzes/ExtraAnalyzes';
import { SampleErrorMessage } from '../shared/ErrorMessage';
import { useParams } from 'react-router-dom';
import { ExtraInfo } from '../ExtraInfo';
import { InformationButton } from '../InformationButton';
import { ReadingCode } from '../../constants/readingCode';
import { BannerMessageContainer } from '../BannerMessages/BannerMessageContainer';
import { ErrorContainer } from '../ErrorContainer';
import { DeviceType } from 'constants/deviceTypes';
import { SignatureInput } from './SignatureInput';
import { FieldContainer } from 'components/shared/inputs/FieldContainer';
import { SampleInformation } from '../sampleInfo/SampleInfo';
import useDialog from 'hooks/useDialog';
import { RESET_VALUES_DIALOG_BODY, RESET_VALUES_DIALOG_TITLE } from 'constants/resetValuesStrings';
import { useGetSample } from 'hooks/useGetSample';
import { FnAnalysisCardContext } from './fnAnalysisCardContext';
import { AnalysisPosition, SetBothSignaturesType } from './fnAnalysisTypes';
import { generateExistingAnalysisDialogString } from 'functions/generateExistingAnalysisDialogString';
import { EXISTING_ANALYSIS_DIALOG_TITLE } from 'constants/existingAnalysisDialogTitle';
import AnalyzesContext from 'contexts/AnalyzesContext';
import { isApproveDisabled } from 'functions/isApproveDisabled';
import useSubmitAnalyzes from 'hooks/useSubmitAnalyzes';
import { DivTopPadding } from 'components/shared/DivWithTopPadding';

export const defaultInputValues = {
  articleNumber: '',
  sampleId: '',
  signature: '',
};

const Checkbox = styled.input`
  margin: 23px 0 !important;
`;

interface FnAnalysisCardProps {
  analysis?: Analysis;
  cardPosition: AnalysisPosition;
  tabIndexNumbers: number[];
  inputValue: InputValueProps;
  setInputValue: (inputValue: InputValueProps) => void;
  setBothSignatures: SetBothSignaturesType;
}

export const FnAnalysisCard: FC<FnAnalysisCardProps> = ({
  analysis,
  cardPosition,
  tabIndexNumbers,
  inputValue,
  setInputValue,
  setBothSignatures,
}) => {
  const [showM3ConnectionError, setShowM3ConnectionError] = useState<boolean>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { location } = useParams<{ location: string }>();
  const { showDialog } = useDialog();
  const { sample, resetSampleValues, getSample, sampleMessages, triedToFetchSample, loading, } = useGetSample();
  const {
    setSampleId,
    averageIsChecked,
    isSameSampleIdInLeftAndRight,
    setAverageIsChecked,
    averageAnalysisSubmitted,
    rightState,
    leftState,
    setAverageAnalysisSubmitted,

  } = useContext(FnAnalysisCardContext);
  const { setIncomingAnalyzes, resetAnalyzesState } = useContext(AnalyzesContext);
  const { errorMessages, isSaving, setErrorMessages, saveAnalyzes } = useSubmitAnalyzes();
  const cardPositionIsLeft = cardPosition === AnalysisPosition.LEFT;
  const {
    setOldText,
    setText,
    newExtraAnalyzes,
    setNewExtraAnalyzes,
    oldText,
    text,
  } = cardPositionIsLeft ? {
    setOldText: leftState.setLeftOldText,
    setText: leftState.setLeftText,
    newExtraAnalyzes: leftState.leftNewExtraAnalyzes,
    setNewExtraAnalyzes: leftState.setLeftNewExtraAnalyzes,
    oldText: leftState.leftOldText,
    text: leftState.leftText,
  } : {
      setOldText: rightState.setRightOldText,
      setText: rightState.setRightText,
      newExtraAnalyzes: rightState.rightNewExtraAnalyzes,
      setNewExtraAnalyzes: rightState.setRightNewExtraAnalyzes,
      oldText: rightState.rightOldText,
      text: rightState.rightText,
    };

  useEffect(
    () => {
      const sampleText = sample?.text ?? '';
      setInputValue({ ...inputValue, articleNumber: sample?.articleNumber ?? '' });
      setOldText(sampleText);
      setText(sampleText);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sample]
  );

  useEffect(() => {
    if (isSameSampleIdInLeftAndRight && !averageIsChecked && cardPosition === AnalysisPosition.RIGHT) {
      resetValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averageIsChecked, analysis]);

  const resetValues = () => {
    cardPosition === AnalysisPosition.LEFT ? leftState.setLeftAnalysis() : rightState.setRightAnalysis();

    setInputValue({ ...defaultInputValues });
    setNewExtraAnalyzes([]);
    setShowM3ConnectionError(false);
    resetSampleValues();
    setOldText('');
    setText('');
    setErrorMessages([]);
    leftState.setLastIncomingLeftAnalysis();
    rightState.setLastIncomingRightAnalysis();
    resetAnalyzesState();
    setBothSignatures('', cardPosition, true);
  };

  const handleBlur = async () => {
    if (inputValue?.sampleId === sample?.id) {
      return;
    }
    getSample(inputValue?.sampleId);
  };

  useEffect(
    () => {
      const sampleText = sample?.text ?? '';
      setInputValue({ ...inputValue, articleNumber: sample?.articleNumber ?? '' });
      setOldText(sampleText);
      setText(sampleText);
      setSampleId(cardPosition, sample?.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sample]
  );

  const handleSignatureChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setBothSignatures(target.value);
  };

  const handleIDChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    resetSampleValues();
    setInputValue({ ...inputValue, [target.name]: target.value.trim() });
  };

  const enableApproveConditions: boolean[] = [analysis !== undefined];
  const isApproveButtonDisabled = isApproveDisabled({
    sample,
    newExtraAnalyzes,
    inputValue,
    text,
    oldText,
    isSaving,
    enableConditions: enableApproveConditions,
  });

  useEffect(() => {
    if (isApproveButtonDisabled && showModal === false) {
      document.querySelector<HTMLInputElement>('.approve')?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproveButtonDisabled]);

  const handleSubmit = async () => {
    document.querySelector<HTMLInputElement>('.first-item')?.focus();
    if (
      analysis &&
      sample?.readings &&
      sample.readings.some(
        reading => reading.location === analysis?.location && reading.code === ReadingCode.FN_FALLING_NUMBER
      )
    ) {
      showDialog(
        EXISTING_ANALYSIS_DIALOG_TITLE,
        generateExistingAnalysisDialogString(sample.id, location, DeviceType.FN),
        () => submitAnalyzes(),
        false
      );
    } else {
      submitAnalyzes();
    }
  };

  const submitAnalyzes = async (): Promise<void> => {
    const analyzesToSave: Analysis[] = analysis ? [...newExtraAnalyzes, analysis] : [...newExtraAnalyzes];

    const saveIsSuccess = await saveAnalyzes(analyzesToSave, inputValue, location, text, oldText, sample);

    if (!saveIsSuccess) {
      return;
    }
    setIncomingAnalyzes([]);
    averageIsChecked && setAverageAnalysisSubmitted(true);
    setAverageIsChecked(false);
    resetValues();
    document.querySelector<HTMLInputElement>('.first-item')?.focus();
  };

  const handleClear = () => {
    showDialog(RESET_VALUES_DIALOG_TITLE, RESET_VALUES_DIALOG_BODY, resetValues, true);
  };

  const handleCheckbox = () => {
    setAverageIsChecked(!averageIsChecked);
  };

  useEffect(() => {
    if (averageAnalysisSubmitted) {
      resetValues();
      setAverageAnalysisSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averageAnalysisSubmitted]);

  const focusGuard1 = document.querySelector('#focusguard-1');
  const focusGuard2 = document.querySelector('#focusguard-2');
  focusGuard1?.addEventListener('focus', () => {
    document.querySelector<HTMLInputElement>('.last-item')?.focus();
  });
  focusGuard2?.addEventListener('focus', () => {
    document.querySelector<HTMLInputElement>('.first-item')?.focus();
  });

  return (
    <Card>
      {showModal && (
        <ExtraInfo
          sample={sample}
          newExtraAnalyzes={newExtraAnalyzes}
          setNewExtraAnalyzes={setNewExtraAnalyzes}
          location={location}
          onClose={() => setShowModal(false)}
          setText={setText}
          sampleText={text}
        />
      )}
      {isSaving ? (
        <Overlay>
          <LinearLoader />
        </Overlay>
      ) : null}
      {loading ? <LinearLoader /> : null}
      <CardHeader>
        {averageIsChecked && cardPosition === AnalysisPosition.RIGHT ? <Overlay></Overlay> : null}
        <h3 style={{ textAlign: 'center' }}>{cardPosition === AnalysisPosition.LEFT ? 'Vänster' : 'Höger'}</h3>
        <BannerMessageContainer sampleMessages={sampleMessages} />
        <SampleErrorMessage
          triedToFetchSample={triedToFetchSample}
          sample={sample}
          sampleId={inputValue?.sampleId}
          getSample={getSample}
        />
        <ErrorContainer
          analysis={analysis}
          connectionError={showM3ConnectionError}
          sample={sample}
          analysisErrors={errorMessages}
        />
        <SignatureInput
          signature={inputValue.signature}
          position={cardPosition}
          tabIndexNumber={tabIndexNumbers[0]}
          handleSignatureChange={handleSignatureChange}
        />
        <FieldContainer
          title="Prov ID"
          id="sampleId"
          placeholder="Ange Prov Id"
          value={inputValue.sampleId ?? ''}
          onChange={handleIDChange}
          onBlur={handleBlur}
          required
          tabIndex={tabIndexNumbers[1]}
        />
        <FieldContainer
          title="Artikel"
          id="articleNumber"
          value={inputValue.articleNumber ?? ''}
          onChange={handleIDChange}
          onBlur={handleBlur}
          required
          disabled={true}
        />
        {cardPosition === AnalysisPosition.LEFT ? (
          <div>
            <div style={{ height: '40px' }}>
              <FlexContainer alignItems="center">
                <FlexItem width={40}>
                  <label htmlFor="fnCheckbox">Beräkna medeltal</label>
                </FlexItem>
                <FlexItem>
                  <Checkbox
                    type="checkbox"
                    id="fnCheckbox"
                    name="fnCheckbox"
                    checked={averageIsChecked}
                    onChange={handleCheckbox}
                    disabled={!isSameSampleIdInLeftAndRight}
                    tabIndex={tabIndexNumbers[3]}
                  />
                </FlexItem>
              </FlexContainer>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            ></div>
          </div>
        ) : (
          <div>
            <div style={{ height: '40px' }}></div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            ></div>
          </div>
        )}
        <SampleInformation
          analysis={analysis}
          sample={sample}
          style={{ paddingTop: '15px', paddingLeft: '0px' }}
        />
      </CardHeader>
      <CardBody>
        <FlexItem width={50}>{analysis?.readings ? <h3>Värde från falltal:</h3> : null}</FlexItem>
        {analysis?.readings?.map((reading, index) => {
          return (
            <Field key={`${reading.analysisId}-${reading.code}`}>
              <FieldName>
                <span>
                  {averageIsChecked && analysis.position !== AnalysisPosition.RIGHT && reading.code}
                  {!averageIsChecked && reading.code}{' '}
                </span>
                <strong>
                  {averageIsChecked && analysis.position !== AnalysisPosition.RIGHT && reading.sourceName}
                  {!averageIsChecked && reading.sourceName}{' '}
                </strong>
              </FieldName>
              <FieldValue>
                {averageIsChecked && analysis.position !== AnalysisPosition.RIGHT && reading.value}
                {!averageIsChecked && reading.value}
              </FieldValue>
            </Field>
          );
        })}
        <CardBody>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <CardRow>
              <FlexItem spacing={4}>
                <InformationButton
                  informationAvailable={!!text}
                  type="button"
                  tabIndex={100}
                  onClick={() => setShowModal(true)}
                  disabled={!sample || sample.completed}
                >
                  Lägg till information
                </InformationButton>
              </FlexItem>
              <FlexItem spacing={4} style={{ marginLeft: 'auto' }}>
                <LmButtonRed
                  type="button"
                  onClick={handleClear}
                  tabIndex={tabIndexNumbers[4]}
                  className={tabIndexNumbers[4] === 13 ? 'last-item' : ''}
                >
                  Rensa{' '}
                </LmButtonRed>
              </FlexItem>
              <FlexItem spacing={4}>
                <LmButtonGreen
                  className="approve"
                  disabled={isApproveButtonDisabled}
                  tabIndex={tabIndexNumbers[3]}
                  inProgress={isSaving}
                >
                  Godkänn
                </LmButtonGreen>
              </FlexItem>
            </CardRow>
          </form>
        </CardBody>
        {sample && (
          <DivTopPadding>
            <OtherAnalyzes sample={sample} />
          </DivTopPadding>
        )}
        <ExtraAnalyzes
          sample={sample} newExtraAnalyzes={newExtraAnalyzes} setNewExtraAnalyzes={setNewExtraAnalyzes} />
      </CardBody>
    </Card>
  );
};
