import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { colors } from 'components/shared/styles/colors';
import { ForwardedRef, ReactNode, forwardRef } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  inProgress?: boolean;
}

export const LmButton = forwardRef(
  ({ inProgress, children, className, ...props }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <button
        {...props}
        className={className + ' lm__base-btn'}
        ref={ref}
        style={{ position: 'relative' }}
      >
        {children}
        {inProgress ? <StyledCircularProgress /> : ''}
      </button>
    );
  }
);

export const LmButtonBlue = styled(LmButton)`
  background-color: #009fe3;
  color: #fff;
  border-color: #009fe3;
  margin-left: 2px;
  margin-right: 2px;
  &:focus {
    outline: 2px solid orange;
  }
`;

export const LmButtonGreen = styled(LmButton)`
  background-color: ${colors.green.primary};
  color: #fff;
  border-color: ${colors.green.primary};
  margin-left: 2px;
  margin-right: 2px;
  &:focus {
    outline: 2px solid orange;
  }
`;

export const LmButtonTransIcon = styled(LmButton)`
  background-color: transparent;
  border:none !important;
  margin-left: 2px;
  margin-right: 2px;
  width:20px;
  padding: 0 !important;

  &:focus {
    outline: 2px solid orange;
  }
  
  svg {
    font-size: 1.2rem;
    path {
      fill: #202020;
    }
  }
`;

export const LmButtonYellow = styled(LmButton)`
  background-color: #ea8d00;
  color: #fff;
  border-color: #ea8d00;
  margin-left: 2px;
  margin-right: 2px;
  &:focus {
    outline: 2px solid orange;
  }
`;

export const LmButtonRed = styled(LmButton)`
  background-color: ${colors.red.primary};
  color: #fff;
  border-color: ${colors.red.primary};
  margin-left: 2px;
  margin-right: 2px;
  &:focus {
    outline: 2px solid orange;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;
