import { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DeleteOptions, FetchOptions, PostOptions, PutOptions } from 'classes/FetchOptions';
import { configuration } from '../config';
const { backendBaseUrl } = configuration;

function useFetch() {
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const runner = useCallback(
    async (url: string, options?: FetchOptions, abortSignal?: AbortSignal): Promise<Response | null> => {
      setIsLoading(true);

      const accessToken = await getAccessTokenSilently().catch(error => {
        if (error.error !== 'login_required') {
          return error.error;
        }
      });

      if (!accessToken) {
        /**
         * If no access token we check if we should login the user automatically
         * If window.location.origin is an URL specified for automatic login -
         * the user will be logged in without clicking "Login"-btn. Otherwise the
         * login page is presented.
         *
         * Reason for approach: Automatically login caused major delay when feedsmart
         * runs stand alone. When running feedsmart integrated to LM2 automatic login
         * works fine.
         */
        const { origin } = window.location;
        if (configuration.autoLoginAtUrls.includes(origin)) {
          await loginWithRedirect();
        } else {
          logout({ returnTo: window.location.origin });
        }
        return null;
      }

      const headers = {
        ...(options?.headers instanceof Array ? options.headers : []),
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };

      const requestOptions = {
        headers,
        abortSignal,
        body: options?.body,
        method: options?.method,
      };

      const result = await fetch(url, requestOptions);
      if (!result.ok) {
        if (result.status === 401) {
          logout({ returnTo: window.location.origin });
        }

        // eslint-disable-next-line no-console
        console.log('Error in fetch, got ', result.status.toString(), ' ', result.statusText);
        return result;
      }

      setIsLoading(false);

      return result;
    },
    [getAccessTokenSilently, loginWithRedirect, logout]
  );

  const get = useCallback(
    async (url: string, abortSignal?: AbortSignal): Promise<Response | null> => runner(url),
    [runner]
  );

  /**
   * Fetches from the default backend
   * @param url The url to fetch from
   */
  const getDefaultBackend = useCallback(
    async (url: string): Promise<Response | null> => runner(`${backendBaseUrl}${url}`),
    [runner]
  );

  const post = async (url: string, options: PostOptions): Promise<Response | null> => runner(url, options);

  const put = async (url: string, options: PutOptions): Promise<Response | null> => runner(url, options);

  // Named deleteRequest since naming something "delete" is not allowed
  const deleteRequest = async (url: string, options: DeleteOptions): Promise<Response | null> => runner(url, options);

  const isStatusInRange = (response: Response | null, min: number, max: number): boolean =>
    response !== null && response.status >= min && response.status < max;

  /**
   * Checks if the response is a server error response (500-599)
   */
  const isServerErrorResponse = (response: Response | null): boolean => isStatusInRange(response, 500, 600);

  /**
   * Checks if the response is a client error response (400-499)
   */
  const isClientErrorResponse = (response: Response | null): boolean => isStatusInRange(response, 400, 500);
  /**
   * Checks if the response is a successful response (200-299)
   */
  const isSuccessfulResponse = (response: Response | null): boolean => isStatusInRange(response, 200, 300);
  return {
    get,
    getDefaultBackend,
    post,
    put,
    delete: deleteRequest,
    isLoading,
    isServerErrorResponse,
    isClientErrorResponse,
    isSuccessfulResponse,
  };
}

export default useFetch;
