import React, { useState } from 'react';
import { FcWithChildren } from 'types/fcWithChildren';

export const SampleContext = React.createContext<{
  sampleId: string;
  location: string;
  setSampleId: (sampleId: string) => void;
  setLocation: (location: string) => void;
}>({
  sampleId: '',
  location: '',
  setSampleId: () => null,
  setLocation: () => null,
});

export const SampleContainer: FcWithChildren = ({ children }) => {
  const [sampleId, setSampleId] = useState('');
  const [location, setLocation] = useState('');

  return (
    <SampleContext.Provider
      value={{ sampleId, setSampleId, location, setLocation }}
    >
      {children}
    </SampleContext.Provider>
  );
};
