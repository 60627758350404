import { Field, FieldName, FieldValue } from 'components/shared/Field';
import { FlexItem } from 'components/shared/FlexLayout';
import { formatDateFromM3 } from 'functions/dateFormatter';
import { FC } from 'react';
import { Analysis, Sample } from 'types';

type ArticleInfoProps =  {
  /** Name of the field */
  name?: string;
  /** Value of the field */
  value?: string;
  /** JSX element to render instead of value */
  jsxElement?: JSX.Element;
}

const SampleInfo = ({ name, value, jsxElement }: ArticleInfoProps) => {
  return (
    <Field>
      <FieldName>{name}</FieldName>
      <FieldValue>{jsxElement ?? <p>{value}</p>}</FieldValue>
    </Field>
  );
};

type SampleInformationProps = typeof FlexItem.defaultProps & {
  analysis: Analysis | undefined;
  sample: Sample | undefined;
}

export const SampleInformation: FC<SampleInformationProps> = ({ analysis, sample, style }) => {
  const flexItemStyle = { ...style };
  return (
    <FlexItem width={50} spacing={15} style={flexItemStyle}>
      <SampleInfo
        jsxElement={
          <>
            <b>{analysis?.calibration}</b>
            <p style={{ marginLeft: '62px' }}> {sample?.grainType}</p>
          </>
        }
      />
      <SampleInfo name="Anmodan skapad:" value={formatDateFromM3(sample?.registrationDate)} />
      <SampleInfo name="Lab:" value={sample?.lab} />
      <SampleInfo name="Analyspaket:" value={sample?.analysisPackage} />
      <SampleInfo name="Artikelnamn:" jsxElement={<b>{sample?.articleName}</b>} />
    </FlexItem>
  );
};
