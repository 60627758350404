import { Analysis, InputValueProps, Sample } from "types";

export const isApproveDisabled = ({
  sample,
  newExtraAnalyzes,
  inputValue,
  text,
  oldText,
  isSaving,
  enableConditions,
  disableConditions,
}: {
  sample: Sample | undefined;
  newExtraAnalyzes: Analysis[];
  inputValue: InputValueProps;
  text: string;
  oldText: string;
  isSaving: boolean;
  enableConditions?: boolean[];
  disableConditions?: boolean[];
}): boolean => {
  let isDisabled = true;
  // Rules to enable button
  if (newExtraAnalyzes.length > 0 || text !== oldText) {
    isDisabled = false;
  }
  enableConditions?.forEach((condition) => {
    if (condition === true) {
      isDisabled = false;
    }
  });

  // Rules to disable button
  if (!inputValue.signature || !inputValue.sampleId) {
    isDisabled = true;
  }
  if (sample?.completed) {
    isDisabled = true;
  }
  if (isSaving) {
    isDisabled = true;
  }
  disableConditions?.forEach((condition) => {
    if (condition === true) {
      isDisabled = true;
    }
  });
  return isDisabled;
};
