import { LmButtonGreen } from 'components/lm2/LmButton';
import * as React from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredDiv = styled(FlexCenter)`
  height: 95vh;
`;

const CenteredContent = styled(FlexCenter)`
  flex-direction: column;
`;

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <CenteredDiv>
        <CenteredContent>
          <h1 style={{marginBottom: 20}}>Välkommen till LabDC</h1>
          <LmButtonGreen onClick={loginWithRedirect}>Login</LmButtonGreen>
        </CenteredContent>
    </CenteredDiv>
  );
}
export default Login;