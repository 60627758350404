export class UpdateSampleRequest {
  public test!: string;

  constructor(public text: string) {
    this.text = text;
  }

  public stringify(): string {
    return JSON.stringify(this);
  }
}
