import styled from "@emotion/styled";

export const Banner = styled.div`
  background-color: #ffcccb;
  color: #000000;
  padding: 5px 10px;
  margin-bottom: 10px;
`;

export const GlobalBanner = styled.div`
  background-color: #ffa270;
  color: #000000;
  padding: 5px 10px;
  margin-bottom: 10px;
`;
