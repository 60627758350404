/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, FocusEvent } from "react";
import { FlexItem } from "../FlexLayout";
import { FieldContainer } from "./FieldContainer";
import { InputValueProps } from "types";

enum BASIC_INPUT_REFS {
    SIGN = "signature",
    SAMPLE = "sampleId"
}

interface BasicInputContainerProps {
    width?: number;
    inputValue: InputValueProps;
    handleSignatureChange: (event: ChangeEvent<HTMLInputElement>)  => void;
    handleIDChange: (event: ChangeEvent<HTMLInputElement>)  => void;
    onBlurSampleId: (ev: FocusEvent<HTMLInputElement>) => Promise<void>;
    onBlurSignature?: (ev: FocusEvent<HTMLInputElement>) => Promise<void>;
    focus: string;
    onFocus?: (event: FocusEvent<HTMLInputElement>, id: string) => void;
    tabIndexSignature?: number,
    tabIndexSampleId?: number
  }

const BasicInputContainer = forwardRef<any, BasicInputContainerProps>(({
    width=50,
    inputValue,
    handleSignatureChange,
    handleIDChange,
    onBlurSampleId,
    onBlurSignature,
    focus,
    onFocus,
    tabIndexSignature,
    tabIndexSampleId
}: BasicInputContainerProps, ref) => {
    const signatureInputRef = useRef<HTMLInputElement>(null);
    const sampleIDInputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => {
        return {
            anyDisabled(): any {
                return {
                    signature: signatureInputRef.current?.disabled,
                    sampleid: sampleIDInputRef.current?.disabled
                };
            }
        };
    }, []);

    useEffect(() => {
        switch(focus) {
            case BASIC_INPUT_REFS.SIGN:
                if (signatureInputRef.current) {
                    signatureInputRef.current.focus();
                }
                break;
            case BASIC_INPUT_REFS.SAMPLE:
                if (sampleIDInputRef.current) {
                    sampleIDInputRef.current.focus();
                }
                break;
            default:
                break;
        }
    }, [focus, signatureInputRef, sampleIDInputRef]);

    return (
        <FlexItem width={width} ref={ref}>
            <FieldContainer
                id='signature'
                title='Signatur'
                value={inputValue?.signature}
                onChange={handleSignatureChange}
                tabIndex={tabIndexSignature !== undefined ? tabIndexSignature : 2}
                placeholder='Ange signatur'
                className="first-item"
                onBlur={onBlurSignature}
                autofocus={true}
                onFocus={(e) => onFocus && onFocus(e, BASIC_INPUT_REFS.SIGN)}
                ref={signatureInputRef}/>
            <FieldContainer
                id='sampleId'
                title='Prov ID'
                placeholder='Ange Prov ID'
                value={inputValue?.sampleId}
                onChange={handleIDChange}
                onBlur={onBlurSampleId}
                tabIndex={tabIndexSampleId !== undefined ? tabIndexSampleId : 3}
                onFocus={(e) => onFocus && onFocus(e, BASIC_INPUT_REFS.SAMPLE)}
                ref={sampleIDInputRef}/>
            <FieldContainer
                id='articleNumber'
                title='Artikel'
                value={inputValue?.articleNumber}
                disabled={true}/>
        </FlexItem>
    );
});

export { BasicInputContainer, BASIC_INPUT_REFS };