export const colors = {
  green: {
    primary:  '#009636',
    lightest: '#D7E18C',
    lighter:  '#B4CD73',
    light:    '#91B95A',
    dark:     '#418C41',
    darker:   '#1E6E37',
  },
  red: {
    dark:     '#7D2328',
    primary:  '#AA372D',
    light:    '#CD6455',
    lighter:  '#E1A591',
  }
};