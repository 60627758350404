import { HttpStatusCode } from "constants/HttpStatusCode";
import { parseHttpResponse } from "./codeParser";

export const processSubmitResponse = (errorMessages: string[], resp: Response | null) => {
    const code = parseHttpResponse(resp);
    if (code === HttpStatusCode.InternalError) {
        errorMessages.push("Kunde ej skicka resultaten, vänligen godkänn igen. Vid problem anteckna provresultaten.");
    }
    else if (resp === undefined || !resp?.ok) {
        errorMessages.push("Kunde ej skicka resultaten, vänligen kontrollera signatur och Prov ID och godkänn igen. Vid problem anteckna provresultaten.");
    }
}