import { useState } from 'react';
import { Analysis, InputValueProps, Sample } from '../types';
import { useFetchAnalysis } from './useFetchAnalysis';
import { useUpdateSampleText } from './useUpdateSampleText';
import { processSubmitResponse } from 'functions/processSubmitResponse';

const useSubmitAnalyzes = () => {
  const { saveAnalysis } = useFetchAnalysis();
  const handleUpdateSampleText = useUpdateSampleText();
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessagesState, setErrorMessagesState] = useState<string[]>([]);

  const saveAnalyzes = async (
    analyzesToSave: Analysis[],
    inputValue: InputValueProps,
    location: string | undefined,
    text: string,
    oldText: string,
    sample?: Sample
  ): Promise<boolean> => {
    setIsSaving(true);
    const errorMessage: string[] = [];
    const promises: Promise<unknown>[] = [];

    if (!sample) {
      errorMessage.push('Provet saknas, kunde inte spara analysen');
      setErrorMessagesState(errorMessage);
      return false;
    }

    for (const newAnalysis of analyzesToSave) {
      newAnalysis.articleName = sample.articleName;
      newAnalysis.articleNumber = sample.articleNumber;

      const promise = saveAnalysis(newAnalysis, inputValue, location ?? '');
      promises.push(promise);
      const response = await promise;

      processSubmitResponse(errorMessage, response);
    }

    if (text !== oldText && sample) {
      const promise = handleUpdateSampleText(sample, text);
      promises.push(promise);
      const resp = await promise;
      processSubmitResponse(errorMessage, resp);
    }

    await Promise.all(promises);
    setIsSaving(false);

    if (errorMessage.length > 0) {
      setErrorMessagesState(errorMessage);
      return false;
    }
    return true;
  };

  return { isSaving, saveAnalyzes, errorMessages: errorMessagesState, setErrorMessages: setErrorMessagesState };
};

export default useSubmitAnalyzes;
