import { Sample } from '../types';
import { configuration } from "../config";
import useFetch from './useFetch';
import { PutOptions } from 'classes/FetchOptions';
import { UpdateSampleRequest } from 'classes/requests';

const { backendBaseUrl } = configuration;

export const useUpdateSampleText = () => {
  const { put } = useFetch();

  const handleUpdateSampleText = async (sample: Sample, text: string): Promise<Response | null> => {
    const body = new UpdateSampleRequest(text);
    const response = await put(`${backendBaseUrl}/api/samples/${sample.id}`, new PutOptions(body.stringify()));

    return response;
  };

  return handleUpdateSampleText;
};
