import styled from "@emotion/styled";

interface DivWithTopPaddingProps {
  paddingTop?: string;
}

export const DivTopPadding = styled.div<DivWithTopPaddingProps>`
  padding-top: ${props => props.paddingTop || '20px'};
`;

export const H3TopPadding = styled.h3<DivWithTopPaddingProps>`
  padding-top: ${props => props.paddingTop || '20px'};
`;