import { useContext } from "react";
import { DialogContext } from "../contexts/DialogContextProvider";

const useDialog = () => {
  const { dialog, setDialog } = useContext(DialogContext);

  const showDialog = (title: string, body: string, onAccept: () => void, isDestructive: boolean, onCancel?: () => void) => {
    const promise = new Promise<void>((resolve) => {
      setDialog({
        title,
        body,
        isOpen: true,
        isDestructive,
        onAccept: () => {
          onAccept();
          reset();
          resolve();
        },
        onCancel: () => {
          if (onCancel) {
            onCancel();
          }
          reset();
          resolve();
        }
      });
    });

    const reset = () => {
      setDialog({ title: '', body: '', isDestructive: false, isOpen: false });
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };
  return {
    ...dialog,
    showDialog,
  };
};

export default useDialog;
