
/**
 * Formats a date string from M3 to a more readable format
 * @param dateString The date string to format
 * @returns The formatted date string
 * @example * formatDateFromM3('20210101') // returns '2021-01-01'
 */
export const formatDateFromM3 = (dateString?: string): string | undefined => {
  if (!dateString) {
    return;
  }
    return dateString.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
  };

  /**
   * Formats a date to swedish date format
   */
  export const formatToDate = (date: Date | undefined) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString(navigator.language,{
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  /**
   * Formats a date to swedish date format with time
   */
  export const formatToDateTime = (date?: Date) => {
    if (!date) return '';
    return new Date(date).toLocaleString(navigator.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }

  /**
   * Get unix time from date
   * @param date
   * @returns
   */
  export const getUnixTime = (date?: Date | null) => {
    if (!date) return undefined;
    return Math.floor(date.getTime() / 1000);
  }

  export const getLongLocalFormat = (date?: Date) => {
    if (!date) return '';
    return new Date(date).toLocaleString(navigator.language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  export const getUtcISOString = (date: Date | undefined): string | undefined => {
    if (date === undefined) {
      return undefined;
    }
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
  }

  /**
   * Checks if a date is an instanceof of Date and valid
   * @param date
   * @returns
   */
  export const isValidDate = (date?: Date | string | null): date is Date  => {
    return date instanceof Date && !isNaN(date.getTime());
  }

  /**
   * Formats a date to provided locale, default swedish locale
   */
  export const getDateInLocaleFormat = (date: Date | undefined, locale = 'sv-SE' ) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString(locale);
  }