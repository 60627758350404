import { PublishMessage } from '../../types';
import { BannerMessage } from './BannerMessage';
import { GlobalBannerMessage } from './GlobalBannerMessage';
import { BannerContainerProps } from './models/BannerProps';

export function BannerMessageContainer({
  sampleMessages,
  locationMessages,
  globalMessages,
}: BannerContainerProps) {
  return (
    <>
      {sampleMessages?.map((message: PublishMessage) => {
        return <BannerMessage key={message.id} message={message} />;
      })}
      {locationMessages?.map((message: PublishMessage) => {
        return <BannerMessage key={message.id} message={message} />;
      })}
      {globalMessages?.map((message: PublishMessage) => {
        return <GlobalBannerMessage key={message.id} message={message} />;
      })}
    </>
  );
}
