import React, { FC } from 'react';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableRow as MuiTableRow} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface TableRowProps {
  headerContent: JSX.Element;
  bodyContent: JSX.Element;
}
export const TableRow: FC<TableRowProps> = (props) => {
  const { headerContent, bodyContent } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <MuiTableRow hover>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headerContent}
      </MuiTableRow>
      <MuiTableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={7}
        >
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="Consituents">
                <TableBody>
                  {bodyContent}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </MuiTableRow>
    </>
  );
};