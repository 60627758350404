import { Analysis, InputValueProps } from '../types';
import { configuration } from '../config';
import useFetch from './useFetch';
import { PostOptions } from 'classes/FetchOptions';
import { CreateAnalysisRequest } from 'classes/requests';
import { SortOrder } from 'types/index';
import { SampleWithMissingAnalyzes } from 'types/sampleWithMissingAnalyzes';

const { backendBaseUrl } = configuration;

export enum CsvType {
  Standard = 'standard',
  Extended = 'extended',
  Missing = 'missing'
}

export interface QueryParameters {
  division: string;
  location: string;
  deviceTypes?: string[];
  analysisTypes?: string[];
  sampleId?: string;
  fromDate?: string;
  toDate?: string;
  pageSize?: number;
  sortOrder: SortOrder;
  continuationToken?: string;
}

export interface CsvQueryParameters extends QueryParameters {
  csvType: CsvType;
}

type UpdateExtraAnalysisType = (
  analysis: Analysis,
  inputValue: InputValueProps,
  location: string
) => Promise<Response | null>;

type GetAnalyzesResponseType = {
  analyzes: Analysis[];
  hasMoreResults: boolean;
  continuationToken?: string;
};
type GetAnalyzesType = (queryParameters: QueryParameters) => Promise<GetAnalyzesResponseType>;
type GetCsvType = (queryParameters: CsvQueryParameters) => Promise<Response | null>;

type UseFetchAnalysisType = {
  saveAnalysis: UpdateExtraAnalysisType;
  getAnalyzes: GetAnalyzesType
  getCsv: GetCsvType;
  getMissingAnalyzes: (queryParameters: QueryParameters) => Promise<SampleWithMissingAnalyzes[]>;
};

/**
 * @description
 * Hook for create Analysis
 */
export const useFetchAnalysis = (): UseFetchAnalysisType => {
  const { post, getDefaultBackend } = useFetch();

  const saveAnalysis: UpdateExtraAnalysisType = async (
    analysis,
    inputValue,
    location
  ) => {
    if (!inputValue.sampleId) {
      // eslint-disable-next-line no-console
      console.error('Missing sampleId');
      return null
    }
    const body = new CreateAnalysisRequest(analysis, inputValue.sampleId, inputValue.signature, location);
    const response = await post(`${backendBaseUrl}/api/analysis/`, new PostOptions(body.stringify()));
    return response;
  };

  const buildQuery = (queryParameters: QueryParameters) => {
    const queryString =  Object.entries(queryParameters)
      .filter(([key, value]) => value)
      .map(([key, value]) => {
        if (key === 'continuationToken') {
          return `${key}=${encodeURIComponent(value)}`;
        }
        if (key === 'deviceTypes' && value?.length > 0) {
          return `${key}=${value}`;
        }
        return `${key}=${value}`;
      })
      .join('&');

      return queryString ? `?${queryString}` : '';
  }

  const getCsv: GetCsvType = async (queryParameters) => {

    const query = buildQuery(queryParameters);
    let url = queryParameters.csvType === CsvType.Missing
      ? `/api/analyzes/missing/csv`
      : `/api/analyzes/csv`;

      url += query;

    const response = await getDefaultBackend(url);
    return response;
  }


  const getAnalyzes: GetAnalyzesType = async (queryParameters) => {
    const query = buildQuery(queryParameters);
    const url = `/api/analyzes${query}`;

    const response = await getDefaultBackend(url);
    return await response?.json();
  };

  const getMissingAnalyzes = async (queryParameters: QueryParameters) => {
    const query = buildQuery(queryParameters);
    const url = `/api/analyzes/missing${query}`;

    const response = await getDefaultBackend(url);
    return await response?.json();
  };

  const returnObj: UseFetchAnalysisType = {
    saveAnalysis,
    getAnalyzes,
    getCsv,
    getMissingAnalyzes
  };

  return returnObj;
};
