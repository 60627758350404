import { LmModal } from '../lm2/LmModal';
import {
  CardActions,
  CardBody,
  CardHeaderWhite,
} from './Card';
import { LmButtonBlue, LmButtonGreen, LmButtonRed } from '../lm2/LmButton';
import { FlexContainer, FlexItem } from './FlexLayout';
import useDialog from 'hooks/useDialog';

export const DialogAlert = () => {
  const { title, body, isOpen, isDestructive, onCancel, onAccept } = useDialog()
  if (!isOpen) {
    return null
  } else {
    return (
      <LmModal onClose={onCancel}>
        <FlexContainer direction='column' alignItems='center'>
          <CardHeaderWhite>
            <h1>{title}</h1>
          </CardHeaderWhite>
          <CardBody>
            <p>{body}</p>
          </CardBody>
          <CardActions>
            <FlexItem flex={true} width={80}>
              {isDestructive ?
                <LmButtonRed onClick={onAccept}>
                  Ok
                </LmButtonRed>
                :
                <LmButtonGreen onClick={onAccept}>
                  Ok
                </LmButtonGreen>
              }
              <LmButtonBlue onClick={onCancel}>
                Avbryt
              </LmButtonBlue>
            </FlexItem>
          </CardActions>
        </FlexContainer>
      </LmModal>
    )
  }
};
