import { useState, useEffect, useCallback } from 'react';
import { LmBlock } from 'components/lm2/LmBlock';
import { LmBlockHeader } from 'components/lm2/LmBlockHeader';
import { LmList } from 'components/lm2/LmList';
import { LmListItem } from 'components/lm2/LmListItem';
import { PageHead } from 'components/PageHead';
import { configuration } from 'config';
import { useAuth0 } from '@auth0/auth0-react';
import { LmButton } from 'components/lm2/LmButton';
import useFetch from 'hooks/useFetch';
import { LinearLoader } from 'components/shared/LinearLoader';
import { PublishMessage } from 'types';
import { GlobalMessage } from 'constants/messageTypes';
import { BannerMessageContainer } from 'components/BannerMessages/BannerMessageContainer';
import { PostOptions } from 'classes/FetchOptions';
import { useFetchFacilities } from 'hooks/useFetchFacilities';

const { backendBaseUrl } = configuration;

export const SelectFacility = () => {
  const { facilities, isLoading } = useFetchFacilities();
  const [globalMessages, setGlobalMessages] = useState<PublishMessage[]>([]);
  const { post } = useFetch();
  const { logout } = useAuth0();

  const getMessages = useCallback(async () => {
    const messagesBody = {
      type: GlobalMessage,
    };
    const messagesResponse = await post(
      `${backendBaseUrl}/api/messages`,
      new PostOptions(JSON.stringify(messagesBody))
    );
    if (messagesResponse?.ok) {
      const fetchedMessages: PublishMessage[] = await messagesResponse?.json();
      setGlobalMessages(fetchedMessages);
    }
    // don't want to add post to dependency array, since it will cause unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMessages();
  }, [getMessages]);


  return (
    <>
      <PageHead>
        <>
          <h1>Välj ort i listan</h1>
          <LmButton onClick={() => logout({ returnTo: window.location.origin })}>Logga ut</LmButton>
        </>
      </PageHead>
      <LmBlock>
        <BannerMessageContainer globalMessages={globalMessages} />
        <LmBlockHeader>Tillgängliga orter</LmBlockHeader>
        <LmList>
          {facilities?.map(item => {
            return (
              <LmListItem key={item.id} to={`/${item.name}`}>
                {item.name}
              </LmListItem>
            );
          })}
        </LmList>
      </LmBlock>
      {isLoading ? <LinearLoader /> : null}
    </>
  );
};
