import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import TableContext from '../TableContext';
import { isValidDate } from 'functions/dateFormatter';
import dayjs from 'dayjs';
import { StyledDatePicker } from 'components/shared/styleMuiComponents';

interface DatePickerProps {
  label: string;
  date: Date | null;
  handleChange: (date?: Date | null) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({ label, date, handleChange }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <StyledDatePicker
        label={label}
        format="YYYY-MM-DD"
        value={date ? dayjs(date) : null}
        onChange={x => handleChange(dayjs(x).toDate())}
      />
    </Grid>
  );
};

export const DatePickers: React.FC = () => {
  const {
    filterValues: {
      fromDate,
      toDate,
    },
    setFilterValues,
  } = useContext(TableContext);



  const handleChangeFromDate = (date?: Date | null) => {
    if (isValidDate(date)) {
      date.setHours(0, 0, 0, 0);
    } else {
      date = undefined;
    }
    setFilterValues(current => ({ ...current, fromDate: date ?? undefined }));
  };

  const handleChangeToDate = (date?: Date | null) => {
    if (isValidDate(date)) {
      date.setHours(23, 59, 59, 999);
    } else {
      date = undefined;
    }
    setFilterValues(current => ({ ...current, toDate: date ?? undefined }));
  };
  return (
    <>
      <DatePicker
        label="Från"
        date={fromDate ?? null}
        handleChange={handleChangeFromDate}
      />
      <DatePicker
        label="Till"
        date={toDate ?? null}
        handleChange={handleChangeToDate}
      />
    </>
  );
};
