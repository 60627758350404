import styled from '@emotion/styled';
import React from 'react';
import { FlexContainer, FlexItem } from '../FlexLayout';

const StyledInput = styled.input`
  width: 100%;
  max-width: 213px;
  margin-left: auto !important;

  @media only screen and (max-width: 1010px) {
    width: 100% !important;
  }
`;

interface InputFieldProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
}

interface InputFieldIconProps extends InputFieldProps {
  ToggleDisabledComponent: React.ComponentType;
}

export const InputField = ({ label, ...props}: InputFieldProps) => {
  return (
    <FlexContainer justify="space-between">
      <FlexItem>
        <label htmlFor="signature">{label}</label>
      </FlexItem>
      <FlexItem width={50}>
        <StyledInput {...props} />
      </FlexItem>
    </FlexContainer>
  );
};

export const InputFieldIcon = ({ label, ToggleDisabledComponent, ...props }: InputFieldIconProps) => {
  return (
    <FlexContainer justify="space-between">
      <FlexItem>
        <label htmlFor="signature">{label}</label>
      </FlexItem>
      <FlexItem width={61.5}>
        <FlexContainer justify="right-align">
          <ToggleDisabledComponent />
          <StyledInput {...props} />
        </FlexContainer>
      </FlexItem>
    </FlexContainer>
  );
};