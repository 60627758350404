
import React, { useContext, useState } from 'react';
import { LmButtonTransIcon } from 'components/lm2/LmButton';
import { LinearLoader } from 'components/shared/LinearLoader';
import { CsvQueryParameters, CsvType, useFetchAnalysis } from 'hooks/useFetchAnalysis';
import styled from '@emotion/styled';
import { getDateInLocaleFormat } from 'functions/dateFormatter';
import { Menu, MenuItem } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TableContext from 'components/table/TableContext';

const getItemTexts = (csvType: CsvType) => {
  switch (csvType) {
    case CsvType.Standard:
      return 'Standard';
    case CsvType.Extended:
      return 'Utökad';
    case CsvType.Missing:
      return 'Saknade';
    default:
      return '';
  }
}

const ContentCenterStyled = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

interface CsvButtonProps {
  supportedCsvType: CsvType[];
}

export const CsvButton: React.FC<CsvButtonProps> = ({supportedCsvType}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | undefined>();
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const { getCsv } = useFetchAnalysis();
  const IconButton = <FileDownloadIcon />;
  const {
    isLoading,
    getFilterValues
  } = useContext(TableContext);

  const handleCloseMenu = () => {
    setAnchorEl(undefined);
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  };
  const onClick = async (csvType: CsvType) => {
    setAnchorEl(undefined);
    setIsLoadingCsv(true);
    try {
      const filterValues = getFilterValues();
      const csvQueryParameters: CsvQueryParameters = {
        ...filterValues,
        csvType: csvType,
      };
      const response = await getCsv(csvQueryParameters);

      if (!response) {
        // eslint-disable-next-line no-console
        console.error('error when downloading csv');
        return;
      }

      // BOM (Byte Order Mark) needed to correctly display UTF-8 encoded CSV file in Excel
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const responseBlob = await response.blob();
      const blob = new Blob([bom, await responseBlob.arrayBuffer()]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${csvType}_analysis_${(getDateInLocaleFormat(new Date()))}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error when downloading csv', error);
    }
    finally {
      setIsLoadingCsv(false);
    }
  };

  return (
    <ContentCenterStyled>
      <LmButtonTransIcon
        disabled={isLoadingCsv || isLoading}
        onClick={openMenu}>
        <ContentCenterStyled>{IconButton}</ContentCenterStyled>
        {isLoadingCsv && <LinearLoader />}
      </LmButtonTransIcon>
      <Menu
        id="csv-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {supportedCsvType.map((csvType) => (
          <MenuItem key={csvType} onClick={() => onClick(csvType)}>
            {getItemTexts(csvType)}
          </MenuItem>
        ))}
      </Menu>
    </ContentCenterStyled>
  );
};
