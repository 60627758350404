import React, { FC } from 'react';
import { Reading, Sample } from '../types';
import { inputTypes } from '../constants/inputTypes';
import { CompleteReading } from './CompleteReading';
import { IncompleteReading } from './IncompleteReading';
import { ReadingCode } from '../constants/readingCode';
import { sortOnReadingCode } from 'utils/readingUtils';
import { H3TopPadding } from './shared/DivWithTopPadding';


interface Props {
  sample: Sample;
}

export const OtherAnalyzes = ({ sample }: Props) => {
  const sortOnRequired = (a: Reading, b: Reading) => {
    if (a.required === b.required || (a.required === false && b.required === false)) {
      return 0;
    } else if (b.required && a.required === false) {
      return 1;
    } else {
      return -1;
    }
  };

  const filterAndSortReadings = (readings: Reading[] | undefined, predicate: (result?: string | undefined) => boolean) => {
    return readings
      ?.filter(reading => predicate(reading.result) && reading.inputType !== inputTypes.ExtraAnalysis)
      .sort((a: Reading, b: Reading) => sortOnReadingCode(a, b))
      .sort((a: Reading, b: Reading) => sortOnRequired(a, b));
  }

  const completedReadings = filterAndSortReadings(sample.readings, result => result === ReadingCode.RSLT_APPROVED);
  const incompleteReadings = filterAndSortReadings(sample.readings, result => result !== ReadingCode.RSLT_APPROVED);



  return (
    <>
      <ReadingsSection readings={incompleteReadings} title="Analyser:" ReadingComponent={IncompleteReading} />
      <ReadingsSection readings={completedReadings} title="Gjorda analyser:" ReadingComponent={CompleteReading} />

    </>
  );
};


type ReadingsSectionProps = {
  readings: Reading[] | undefined;
  title: string;
  ReadingComponent: FC<{ reading: Reading }>;
};

const ReadingsSection: FC<ReadingsSectionProps> = ({ readings, title, ReadingComponent }) => {
  if (!readings || readings.length === 0) {
    return null;
  }

  return (
    <>
      <H3TopPadding>{title}</H3TopPadding>
      {readings.map((reading) => <ReadingComponent key={reading.code} reading={reading} />)}
    </>
  );
}