import { FC, useContext } from 'react';
import { TableCell } from '@mui/material';
import styled from '@emotion/styled';
import AnalyzesTableContext from './MissingAnalyzesContext';
import { AnalyzesTableRow } from './MissingAnalyzesTableRow';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LabDcTable } from 'components/table/Table';
import TableContext from 'components/table/TableContext';
import { SortOrder } from 'types/index';
import { StyledTdFixedWidth } from 'components/shared/StyledTdFixedWidth';
import { CsvButton } from 'components/table/CsvButton';
import { CsvType } from 'hooks/useFetchAnalysis';

const StyledHederTextPointer = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const MissingAnalyzes: FC = () => {
  const {
    tableItems,
  } = useContext(AnalyzesTableContext);

  const {
    isLoading,
    filterValues: { sortOrder },
    sortByDate
  } = useContext(TableContext);


  const supportedCsvTypes = [CsvType.Missing];
  return (
    <LabDcTable
      isLoading={isLoading}
      tableHeader={
        <>
          <TableCell></TableCell>
          <TableCell align="left">Provid</TableCell>
          <TableCell align="left">Artikelnummer</TableCell>
          <TableCell align="left">Artikelnamn</TableCell>
          <TableCell
            onClick={sortByDate}
            align="left">
            <StyledHederTextPointer title='Sortera på datum'>
              <span>Datum</span>
              {sortOrder === SortOrder.ASC ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </StyledHederTextPointer>
          </TableCell>
          <TableCell align="left">Ort</TableCell>
          <StyledTdFixedWidth align="left">
            <CsvButton supportedCsvType={supportedCsvTypes}/>
          </StyledTdFixedWidth>
        </>
      }
      tableRows={tableItems?.map((item) => {
        return <AnalyzesTableRow key={`STrow${item.sampleId}`} sample={item} />;
      })}
    />


  );
};
