import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback, FC } from 'react';
import { LmBlock } from '../components/lm2/LmBlock';
import { LmBlockHeader } from '../components/lm2/LmBlockHeader';
import { LmList } from '../components/lm2/LmList';
import { LmListItem } from '../components/lm2/LmListItem';
import { PageHead } from '../components/PageHead';
import { LmButton } from '../components/lm2/LmButton';
import { Breadcrumb } from '../components/BreadCrumb';
import { Breadcrumbs } from '../components/BreadCrumbs';
import { configuration } from '../config';
import useFetch from '../hooks/useFetch';
import { BannerMessageContainer } from '../components/BannerMessages/BannerMessageContainer';
import { IDevice, PublishMessage } from '../types';
import { LocationMessage } from '../constants/messageTypes';
import { PostOptions } from 'classes/FetchOptions';

const { backendBaseUrl } = configuration;

type LocationType = {
  location: string;
};

export const Location: FC = () => {
  const { location } = useParams<LocationType>();
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [locationMessages, setLocationMessages] = useState<PublishMessage[]>([]);
  const { post } = useFetch();
  const navigate = useNavigate();
  const { get } = useFetch();

  const getDevices = useCallback(async () => {
    const response = await get(`${backendBaseUrl}/api/devices?location=${location}`);
    if (!response) {
      setDevices([]);
      return;
    }
    setDevices(await response.json());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getMessages = useCallback(async () => {
    const messagesBody = {
      type: LocationMessage,
      location: location,
    };
    const messagesResponse = await post(
      `${backendBaseUrl}/api/messages`,
      new PostOptions(JSON.stringify(messagesBody))
    );
    if (messagesResponse?.ok) {
      const fetchedMessages: PublishMessage[] = await messagesResponse.json();
      setLocationMessages(fetchedMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDevices();
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const click = () => {
    navigate(`/`);
  };

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb to={`/`}>Välj Ort</Breadcrumb>
        <Breadcrumb to={`/${location}`}>{location}</Breadcrumb>
      </Breadcrumbs>
      <PageHead>
        <h1>Välkommen till {location}</h1>
        <LmButton onClick={click}>Byt ort</LmButton>
      </PageHead>
      <LmBlock>
        <BannerMessageContainer locationMessages={locationMessages} />
        <LmBlockHeader> Välj instrumment</LmBlockHeader>
        <LmList>
          {devices?.map((device) => {
            return (
              <LmListItem key={device.id} to={`/${location}/${device.id}/analyzes/nytt`}>
                {device.alias || device.id}
              </LmListItem>
            );
          })}
        </LmList>
      </LmBlock>
    </>
  );
};
