import styled from '@emotion/styled';
import React from 'react';
import { Reading } from '../types';
import { Field, FieldName, FieldValue } from './shared/Field';
import { ReadingCode } from '../constants/readingCode';

const ListItem = styled.div<{ error?: boolean; }>`
  & > div {
    ${({ error }) => (error ? 'border: 1px solid #ff5722; background: #ffa270;' : '')}
    padding: 0 10px;
    margin: 0 -10px;
  }

  &.last-row div {
    border-bottom: none !important;
  }
`;

const Checkbox = styled.input`
  margin: 0 10px 0 0 !important;
`;

interface Props {
  reading?: Reading;
  locked?: boolean;
  onLock?: (locked: boolean) => void;
  rowIndex: number;
  readingsLength: number;
}

export const AnalysisTableRowReading = ({
  reading,
  locked,
  onLock,
  rowIndex,
  readingsLength
}: Props) => {

  const isLastRow = rowIndex === readingsLength - 1;

  return (
    <ListItem className={isLastRow ? 'last-row' : ''} 
        error={
          !!reading?.outliers?.filter((outlier) => Math.round(outlier.value) > 0).length
        }
      >
        <Field>
          {onLock && (
            <Checkbox
              type="checkbox"
              checked={locked ?? false}
              onChange={(e) => onLock(e.target.checked)}
            />
          )}

          <FieldName>
            <span>{reading?.code} </span>
            <b>{reading?.sourceName} </b>
          </FieldName>
          <FieldValue>
            <strong>{reading?.result === ReadingCode.EMPTY_STRING && reading?.value === 0 ? null : reading?.value} </strong>
            {reading?.outliers?.length ? (
              <>
                {reading?.outliers.some((outlier) => Math.round(outlier.value) > 0) ? (
                  <>
                    (
                    {reading?.outliers.map((outlier, index) => {
                      return (
                        <React.Fragment key={index}>
                          {Math.round(outlier.value)}
                        </React.Fragment>
                      );
                    })}
                    )
                  </>
                ) : null}
              </>
            ) : null}
          </FieldValue>
        </Field>
      </ListItem>
  );
};
