import React, { ChangeEvent, useState } from 'react';
import { Analysis, Reading, SupportItem } from '../../types';
import { Readings } from './Readings';

interface Props {
  editableCode?: SupportItem[];
  reading?: Reading;
  newExtraAnalyzes?: Analysis[];
  setNewExtraAnalyzes?: (analyzes: Analysis[]) => void;
  deleteAnalysis?: (code: string) => void;
  completeFlag: boolean | undefined;
  isEditing: boolean;
  setIsEditing?: (isEditing: boolean) => void;
}

export const ExtraAnalysisReadings = ({
  reading,
  newExtraAnalyzes,
  setNewExtraAnalyzes,
  editableCode,
  completeFlag,
  isEditing,
  setIsEditing,
  deleteAnalysis,
}: Props) => {
  const [inputValue, setInputValue] = useState(reading?.value);
  const isReadingEditable = editableCode?.some(({ value }) => reading?.code === value);
  if (isReadingEditable && !reading?.editable) {
    reading = { ...reading, editable: true };
  }

  const handleSetIsEditing = (isEditingValue: boolean) =>{
    setIsEditing && setIsEditing(isEditingValue)
  }
  const onClickEditing = () => {
    setInputValue(reading?.value);
    handleSetIsEditing(!isEditing);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.valueAsNumber);
  };

  const onMouseClick = () => {
    handleSetIsEditing(false);
    updateExtraAnalysis(reading?.code, inputValue);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && isEditing) {
      handleSetIsEditing(false);
      updateExtraAnalysis(reading?.code, inputValue);
    }
  };

  /**
   * Update new analysis
   * @param analysis
   * @param codeToUpdate
   * @param valueToUpdate
   **/
  const updateExtraAnalysis = async (
    codeToUpdate?: string,
    valueToUpdate?: number
  ) => {
    if (newExtraAnalyzes && codeToUpdate && valueToUpdate) {
      newExtraAnalyzes.forEach((obj) => {
        obj.readings?.forEach((item) => {
          if (item.code === codeToUpdate) item.value = valueToUpdate;
        });
      });
      setNewExtraAnalyzes && setNewExtraAnalyzes(newExtraAnalyzes);
    }
    handleSetIsEditing(false);
  };

  return (
    <Readings
      key={`ReadingList${reading?.code?.toString()}`}
      reading={reading}
      completeFlag={completeFlag}
      isEditing={isEditing}
      deleteAnalysis={deleteAnalysis}
      inputValue={inputValue}
      handleInputChange={handleInputChange}
      onMouseClick={onMouseClick}
      onKeyDown={onKeyDown}
      onClickEditing={onClickEditing}
    />
  );
};
