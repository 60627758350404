import { SetStateAction, createContext, useEffect, useMemo, useState } from "react";
import { Facility, LocationType } from "types";
import { getUtcISOString } from "functions/dateFormatter";
import { SortOrder } from "types/index";
import { useParams } from "react-router-dom";

interface IFilterObject {
  division: string;
  location: string;
  deviceTypes?: string[];
  readingCodes?: string[];
  sampleId?: string;
  fromDate?: string;
  toDate?: string;
  pageSize?: number;
  sortOrder: SortOrder;
  continuationToken?: string;
}

export interface IFilterValues {
  location: string;
  chosenDeviceTypes?: string[];
  chosenReadingCodes?: string[];
  sampleId: string;
  fromDate?: Date;
  toDate?: Date;
  page: number;
  pageSize: number;
  sortOrder: SortOrder;
}

interface IFetchState {
  hasMoreResults: boolean;
  continuationToken?: string;
}

const initFilterValues: IFilterValues = {
  location: '',
  sampleId: '',
  page: 1,
  pageSize: 200,
  sortOrder: SortOrder.DESC,
};

const initFilterObject: IFilterObject = {
  location: '',
  sampleId: '',
  pageSize: 200,
  sortOrder: SortOrder.DESC,
  division: 'SPML',

};
interface ITableContext {
  readyForInitFetch: boolean;
  fetchState: IFetchState;
  setFetchState: (value: SetStateAction<IFetchState>) => void;
  filterValues: IFilterValues;
  setFilterValues: (value: SetStateAction<IFilterValues>) => void;
  isLoading: boolean;
  setIsLoading: (isLoadingParm: boolean) => void;
  facilities: Facility[];
  setFacilities: (facilitiesParm: Facility[]) => void;
  deviceTypes: string[];
  setDeviceTypes: (deviceTypesParm: string[]) => void;
  getFilterValues: (continuationToken?: string) => IFilterObject;
  sortByDate: () => void;
  restTableState: () => void;
}

const initFetchState: IFetchState = {
  hasMoreResults: false,
};

const initState: ITableContext = {
  readyForInitFetch: false,
  fetchState: initFetchState,
  setFetchState: () => null,
  isLoading: false,
  setIsLoading: () => null,
  facilities: [],
  setFacilities: () => null,
  deviceTypes: [],
  setDeviceTypes: () => null,
  filterValues: initFilterValues,
  setFilterValues: () => null,
  getFilterValues: () => initFilterObject,
  sortByDate: () => null,
  restTableState: () => null,
};

interface TableContextProviderProps {
  children: React.ReactNode;
}

export const TableContextProvider = ({ children }: TableContextProviderProps) => {
  /* 'readyForInitFetch' is used to trigger initial fetch after location is set */
  const [readyForInitFetch, setReadyForInitFetch] = useState<boolean>(false);
  const { location } = useParams<LocationType>();
  const [fetchState, setFetchState] = useState<IFetchState>(initFetchState);
  const [filterValues, setFilterValues] = useState<IFilterValues>(initFilterValues);
  const [isLoading, setIsLoading] = useState(false);
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<string[]>([]);

  useEffect(() => {
    if (location){
      setFilterValues(current => ({ ...current, location: location}));
      setReadyForInitFetch(true);
    }
  }, [location]);

  const getFilterValues = (continuationToken?: string): IFilterObject => {
    return {
      division: 'SPML',
      location: filterValues.location,
      deviceTypes: filterValues.chosenDeviceTypes,
      readingCodes: filterValues.chosenReadingCodes,
      sampleId: filterValues.sampleId,
      fromDate: getUtcISOString(filterValues.fromDate),
      toDate: getUtcISOString(filterValues.toDate),
      pageSize: filterValues.pageSize,
      sortOrder: filterValues.sortOrder,
      continuationToken: continuationToken,
    };
  }

  const restTableState = () => {
    setFilterValues(initFilterValues);
    setFetchState(initFetchState);
    setFacilities([]);
    setDeviceTypes([]);
    setIsLoading(false);
  }

  const sortByDate = () => {
    setFilterValues(current => ({
      ...current,
      sortOrder: current.sortOrder === SortOrder.ASC
        ? SortOrder.DESC
        : SortOrder.ASC
    }));
  }

  const values = useMemo(() => ({
    readyForInitFetch,
    fetchState, setFetchState,
    isLoading, setIsLoading,
    facilities, setFacilities,
    deviceTypes, setDeviceTypes,
    filterValues, setFilterValues,
    getFilterValues,
    sortByDate,
    restTableState
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [fetchState, isLoading, facilities, deviceTypes, filterValues]);

  return (
    <TableContext.Provider value={values}>
      {children}
    </TableContext.Provider>
  );
};

const TableContext = createContext<ITableContext>(initState);
export default TableContext;
