import styled from '@emotion/styled';
import { Reading } from '../types';
import { Field, FieldName, FieldValue } from './shared/Field';

const CardListItem = styled.div<{ error?: boolean; otherAnalyzes?: boolean }>`
  & > div {
    ${({ error }) => (error ? 'border: 1px solid #ff5722;' : '')}
    ${({ error }) => (error ? 'background: #ffa270;' : '')}
    padding: 0 10px;
    margin: 0 -10px;
  }
`;
interface Props {
  reading?: Reading;
}

export const CompleteReading = ({ reading }: Props) => {

  return (
    <>
      <CardListItem
        error={
          !!reading?.outliers?.filter((outlier) => outlier.value > 0).length
        }
      >
        <Field>
          <FieldName
            color={
              reading?.outliers?.some((outlier) => outlier.value > 0)
                ? ''
                : 'orange'
            }
          >
            {reading?.required === true ? (
              <strong>{reading?.code} </strong>
            ) :
            <span>{reading?.code} </span>
            }
            {reading?.required === true ? (
              <strong>{reading?.sourceName} </strong>
            ) :
            <span>{reading?.sourceName} </span>
            }
          </FieldName>
          <FieldValue
            color={
              reading?.outliers?.some((outlier) => outlier.value > 0)
                ? ''
                : 'orange'
            }
          >
            <strong>{reading?.value} </strong>
            {reading?.outliers?.length ? (
              <>
                {reading?.outliers.some((outlier) => outlier.value > 0) ? (
                  <>
                    (
                    {reading?.outliers.map((outlier, index) => {
                      return <span key={index}>{outlier.value}</span>;
                    })}
                    )
                  </>
                ) : null}
              </>
            ) : null}
          </FieldValue>
        </Field>
      </CardListItem>
    </>
  );
};
