import { Analysis, Reading } from "types";
import { filterAnalysisReadings } from "utils/readingUtils";

export class CreateAnalysisRequest {
  timestamp?: string;
  deviceType?: string;
  deviceModel?: string;
  deviceId: string;
  approvedBy: string;
  eventId?: string;
  instrumentReference = -1;
  location: string;
  calibration?: string;
  readings: Reading[];
  serialNumber?: string;
  position?: string;
  inputType?: string;
  sampleId: string;
  articleName: string;
  articleNumber: string;

  constructor(analysis: Analysis, sampleId: string , signature: string, location: string) {


    if (!analysis.readings) {
      throw new Error(this.getErrorMessage('Readings'));
    }

    if (!analysis.deviceId) {
      throw new Error(this.getErrorMessage('DeviceId'));
    }
    if (!analysis.articleName) {
      throw new Error(this.getErrorMessage('ArticleName'));
    }
    if (!analysis.articleNumber) {
      throw new Error(this.getErrorMessage('ArticleNumber'));
    }

    this.sampleId = sampleId;
    this.timestamp = analysis.timestamp;
    this.deviceType = analysis.deviceType;
    this.deviceModel = analysis.deviceModel;
    this.deviceId = analysis.deviceId;
    this.inputType = analysis.inputType;
    this.approvedBy = signature;
    this.eventId = analysis.eventId;
    this.readings = filterAnalysisReadings(analysis.readings) ?? [];
    this.serialNumber = analysis.serialNumber?.toString();
    this.position = analysis.position;
    this.location = location;
    this.calibration = analysis.calibration;
    this.instrumentReference = analysis.instrumentReference ?? -1;
    this.articleName = analysis.articleName;
    this.articleNumber = analysis.articleNumber;
  }

  public stringify(): string {
    return JSON.stringify(this);
  }

  private getErrorMessage = (propertyName: string) => {
    return `${propertyName}  is required, when saving a analysis to the database.`;

  }
}