import { ChangeEvent, FC } from 'react';
import { ReadingCode } from '../../constants/readingCode';
import { Reading, Sample } from '../../types';
import { ManualInputReading } from './ManualIncompleteReading';
import { DivTopPadding, H3TopPadding } from 'components/shared/DivWithTopPadding';
import { OtherAnalyzes } from 'components/OtherAnalyzes';


interface ManualAnalyzesProps {
  sample?: Sample;
  currentReadings?: Reading[];
  tabIndexNumber: number;
  handleReadingValueChange: (event: ChangeEvent<HTMLInputElement>, name: string | undefined) => void;
}

export const ManualAnalyzes: FC<ManualAnalyzesProps> = ({
  sample,
  currentReadings,
  tabIndexNumber,
  handleReadingValueChange,
}) => {

  return (
    <>
      {sample && (
        <>
          <DivTopPadding>
            {currentReadings?.length !== 0 ? <H3TopPadding>Manuella analyser:</H3TopPadding> : null}
            {currentReadings?.map((requiredReading, index) => {
              return (
                <ManualInputReading
                  arrayIndex={index}
                  tabIndex={index + tabIndexNumber}
                  key={index}
                  requiredReading={requiredReading}
                  handleReadingValueChange={handleReadingValueChange}
                  isReadOnly={requiredReading.code === ReadingCode.AF_WASTE}
                />
              );
            })}
            {sample && (
              <DivTopPadding>
                <OtherAnalyzes sample={sample} />
              </DivTopPadding>
            )}
          </DivTopPadding>
          <div
            id="focusguard-2"
            tabIndex={currentReadings ? currentReadings?.length + tabIndexNumber : Number.MAX_SAFE_INTEGER}
          ></div>
        </>
      )}
    </>
  );
};
