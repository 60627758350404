import styled from '@emotion/styled';

export const Field = styled.div<{ justify?: string, hideBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justify }) => (justify ? justify : `left`)};
  min-width: 33%;
  border-bottom: ${({ hideBorder }) => (hideBorder ? `none` : `1px solid #efefef`)};
  color: #565656;
`;

export const FieldName = styled.div<{ color?: string }>`
  flex: 1;
  color: ${({ color }) => (color ? color : `#565656`)};
  padding: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FieldValue = styled.div<{ color?: string }>``;
