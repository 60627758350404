import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import Login from 'components/mainpage/Login';
import { SelectFacility } from 'components/mainpage/SelectFacility';
import { LinearLoader } from 'components/shared/LinearLoader';

const CenteredLinearLoader = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '95vh',
});

export const MainPage = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <CenteredLinearLoader><LinearLoader /></CenteredLinearLoader>;
  }

  return (
      isAuthenticated ? <SelectFacility /> : <Login />
  );
};
