/**
 * Determines whether the gross and net amounts are correct.
 * Returns true if the gross amount is greater than the net amount and both amounts are valid.
 * Otherwise, returns false.
 */
export const isGrossNetAmountCorrect = (grossAmount: number | undefined, netAmount: number | undefined): boolean => {
  if (grossAmount === undefined || netAmount === undefined) {
    return false;
  }
  if (grossAmount <= 0 || netAmount < 0) {
    return false;
  }
  if (grossAmount <= netAmount) {
    return false;
  }
  return true;
};
