export class FetchOptions {
  protected _method: string;
  private _headers?: {
    [key: string]: string;
  };
  private _body?: string;

  get body(): string | undefined {
    return this._body;
  }
  get method(): string {
    return this._method;
  }

  get headers(): typeof FetchOptions.prototype._headers {
    return this._headers;
  }
  protected constructor(method: string, body?: string, headers?: { [key: string]: string }) {
    this._method = method;
    this._headers = headers;
    this._body = body;
  }
}
export class PutOptions extends FetchOptions {
  constructor(body: string, headers?: { [key: string]: string }) {
    super('PUT', body, headers);
  }
}

export class PostOptions extends FetchOptions {
  constructor(body: string, headers?: { [key: string]: string }) {
    super('POST', body, headers);
  }
}

export class DeleteOptions extends FetchOptions {
  constructor(headers?: { [key: string]: string }) {
    super('DELETE', undefined, headers);
  }
}
