import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { Analysis, Reading, Sample, SupportItem } from 'types';
import { ExtraAnalysisReadings } from './ExtraAnalysisReadings';
import { useSupport } from 'hooks/useSupport';
import { inputTypes } from 'constants/inputTypes';
import { ReadingCode } from 'constants/readingCode';
import { SupportType } from 'types/supportType';

const Result = styled.div`
  padding-top: 20px;
`;

interface ExtraAnalyzesProps {
  sample?: Sample;
  newExtraAnalyzes?: Analysis[];
  setNewExtraAnalyzes: (newExtraAnalyzes: Analysis[]) => void;
}

export const ExtraAnalyzes: FC<ExtraAnalyzesProps> = ({ sample, newExtraAnalyzes, setNewExtraAnalyzes }) => {
  const [previousExtraReadings, setPreviousExtraReadings] = useState<Reading[]>([]);
  const [editableCode, setEditableCode] = useState<SupportItem[]>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const support = useSupport(SupportType.EXTRA_ANALYSIS);

  useEffect(() => {
    if (support?.length > 0) {
      setEditableCode(support.filter((item) => item.editable));
    }
  }, [support]);

  useEffect(() => {
    const extraReadings = sample?.readings?.filter(
      reading => reading.inputType === inputTypes.ExtraAnalysis
    );
    extraReadings && setPreviousExtraReadings(extraReadings);
  }, [sample]);

  /**
   * Delete the code from readings.
   * if readings list is empty delete analysis as well
   */
  const deleteReading = (code: string) => {
    if (!newExtraAnalyzes) {
      setIsEditing(false);
      return;
    }

    // Create a copy, to avoid modify newExtraAnalyzes directly
    const analyzesToUpdate = [...newExtraAnalyzes];
    const analysisToUpdate = analyzesToUpdate.find(a => a.readings?.some((reading) => reading.code === code))

    if (!analysisToUpdate) {
      return
    }

    // Remove analysisToUpdate from analyzesToUpdate
    const analysisIndex = analyzesToUpdate.indexOf(analysisToUpdate);
    analyzesToUpdate.splice(analysisIndex, 1);


    let codesToRemove: string[] = [];

    // If code is ACL_DECLINED or ACL_READY_FLAG, remove both
    if (code === ReadingCode.ACL_DECLINED || code === ReadingCode.ACL_READY_FLAG) {
      codesToRemove = [ReadingCode.ACL_READY_FLAG, ReadingCode.ACL_DECLINED]
    } else {
      codesToRemove = [code];
    }

    // Create a new readings array that excludes the object to remove
    const newReadingsArray = analysisToUpdate.readings?.filter(x => {
      return x.code && !codesToRemove.includes(x.code)
    });

    // if newReadingsArray contains any readings
    if (newReadingsArray?.length) {
      analysisToUpdate.readings = newReadingsArray;
      analyzesToUpdate.push(analysisToUpdate);
    }

    setNewExtraAnalyzes(analyzesToUpdate);
    setIsEditing(false);
  }

  // If no sample, don't render anything
  if (!sample) {
    return null;
  }

  const newExtraReadings = newExtraAnalyzes?.reduce((acc, a) => {
    const readingsWithCode = a.readings?.filter(reading => reading.code !== undefined);
    return readingsWithCode ? [...acc, ...readingsWithCode] : acc;
  }, [] as Reading[]);

  return (
    <Result>
      {
        (!!previousExtraReadings?.length || !!newExtraAnalyzes?.length) && <h3>Extra analyser:</h3>
      }
      {
        previousExtraReadings?.map((reading) => (
          <ExtraAnalysisReadings
            editableCode={editableCode}
            key={`PEAList${reading?.code?.toString()}`}
            reading={reading}
            completeFlag={true}
            isEditing={isEditing}
          />
        ))
      }
      {
        newExtraReadings?.filter((reading) => reading.code !== undefined).map((reading) => (
          <ExtraAnalysisReadings
            editableCode={editableCode}
            key={`NEAList${reading.code?.toString()}`}
            reading={reading}
            newExtraAnalyzes={newExtraAnalyzes}
            setNewExtraAnalyzes={setNewExtraAnalyzes}
            completeFlag={sample?.completed}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            deleteAnalysis={deleteReading}
          />
        ))
      }
    </Result>
  );
};
