import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AnalysisPosition } from './fnAnalysisTypes';
import { InputField, InputFieldIcon } from 'components/shared/inputs/InputField';
import { FC, useState } from 'react';

interface SignatureInputProps {
  signature?: string;
  handleSignatureChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  tabIndexNumber: number;
  position: AnalysisPosition;
}

export const SignatureInput: FC<SignatureInputProps> = ({
  signature,
  handleSignatureChange,
  tabIndexNumber,
  position,
}) => {
  const [signatureIsDisabled, setSignatureIsDisabled] = useState<boolean>(position === AnalysisPosition.RIGHT);

  const handleOnSignatureClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (position === AnalysisPosition.RIGHT) {
      ev.preventDefault();
      ev.stopPropagation();
      setSignatureIsDisabled(preValue => !preValue);
    }
  };

  const props = {
    label: 'Signatur',
    type: 'text',
    id: 'signature',
    name: 'signature',
    placeholder: 'Ange signatur',
    value: signature ?? '',
    onChange: handleSignatureChange,
    required: true,
    autoFocus: tabIndexNumber === 2,
    tabIndex: tabIndexNumber,
    disabled: signatureIsDisabled,
    className: tabIndexNumber === 2 ? 'first-item' : '',
  };

  return (
    <>
      {position === AnalysisPosition.RIGHT ? (
        <InputFieldIcon
          ToggleDisabledComponent={() => (
            <IconButton title="Editera signatur" onClick={handleOnSignatureClick}>
              <EditIcon />
            </IconButton>
          )}
          {...props}
        />
      ) : (
        <InputField {...props} />
      )}
    </>
  );
};
