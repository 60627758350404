import styled from '@emotion/styled';
import React from 'react';
import { FlexContainer, FlexItem } from './FlexLayout';

const StyledTextarea = styled.textarea`
  width: 100%;
`;

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label: string;
}

export const TextareaField = ({ label, ...props }: Props) => {
  return (
    <FlexContainer justify="space-between">
      <FlexItem>
        <label htmlFor="signature">{label}</label>
      </FlexItem>
      <FlexItem width={60}>
        <StyledTextarea {...props} />
      </FlexItem>
    </FlexContainer>
  );
};
