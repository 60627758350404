import App from './App';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root');
if (root !== null) {
  ReactDOM.createRoot(root).render(
          <App />
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
