interface IConfiguration {
  backendBaseUrl: string;
  authDomain: string;
  authClientId: string;
  authAudience: string;
  autoLoginAtUrls: string;
  appInsightsConnectionString: string;
}

declare global {
  interface Window {
    envConfig: IConfiguration;
  }
}

const envConfig = (window as Window).envConfig ?? {};

// Override in development mode by using a ".env.development.local" file
if (process.env.REACT_APP_BACKEND_BASE_URL !== undefined) {
  envConfig.backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  envConfig.authDomain = process.env.REACT_APP_AUTH_DOMAIN ?? '';
  envConfig.authClientId = process.env.REACT_APP_AUTH_CLIENT_ID ?? '';
  envConfig.authAudience = process.env.REACT_APP_AUTH_AUDIENCE ?? '';
  envConfig.autoLoginAtUrls = process.env.REACT_APP_LOGIN_AT_URLS ?? '';
  envConfig.appInsightsConnectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING ?? '';

  // eslint-disable-next-line no-console
  console.log("Using settings from environment variables");
} else {
  // eslint-disable-next-line no-console
  console.log("Using settings from Window");
}

export const configuration = envConfig;
