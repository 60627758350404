import { LmBlock } from '../lm2/LmBlock';
import { ManualAnalysisCard } from './ManualAnalysisCard';

export const NewManualAnalysis = () => {
  return (
    <>
      <LmBlock>
        <ManualAnalysisCard
        />
      </LmBlock>
    </>
  );
};
