import styled from '@emotion/styled';

interface Props {
  direction?: string;
  justify?: string;
  alignItems?: string;
  spacing?: number;
}

export const FlexContainer = styled.div<Props>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  margin-left: ${({ spacing }) => (spacing ? `-${spacing}px` : 0)};
  margin-right: ${({ spacing }) => (spacing ? `-${spacing}px` : 0)};
`;

export const FlexItem = styled.div<{
  /**
   * Width of the item in percentage
   */
  width?: number;
  spacing?: number;
  flex?: boolean;
}>`
  flex: ${({ flex }) => (flex ? '1 1' : '0 0')}
    ${({ width }) => (width ? `${width}%` : 'auto')};
  padding-left: ${({ spacing }) => (spacing ? `${spacing}px` : 0)};
  padding-right: ${({ spacing }) => (spacing ? `${spacing}px` : 0)};
`;
