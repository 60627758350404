import styled from '@emotion/styled';
import { LmButtonGreen, LmButtonRed } from '../lm2/LmButton';
import { Analysis, IDevice, LocationType, Reading } from '../../types';
import { useContext, useEffect, useState, useCallback, ChangeEvent, FC } from 'react';
import { LinearLoader } from '../shared/LinearLoader';
import { FlexContainer, FlexItem } from '../shared/FlexLayout';
import { Card, CardBody, CardHeader } from '../shared/Card';
import { SampleErrorMessage } from '../shared/ErrorMessage';
import { ExtraAnalyzes } from '../ExtraAnalyzes/ExtraAnalyzes';
import { useParams } from 'react-router-dom';
import { useSupport } from 'hooks/useSupport';
import { ExtraInfo } from '../ExtraInfo';
import { ManualAnalyzes } from './ManualAnalyzes';
import { configuration } from '../../config';
import useFetch from '../../hooks/useFetch';
import { MenuItem, TextField } from '@mui/material';
import { InformationButton } from '../InformationButton';
import { isGrossNetAmountCorrect } from '../../functions/isGrossNetAmountCorrect';
import { BannerMessageContainer } from '../BannerMessages/BannerMessageContainer';
import { ErrorContainer } from '../ErrorContainer';
import { DeviceType } from '../../constants/deviceTypes';
import { parseInputNumber } from 'functions/parseInputNumber';
import { WasteTypes } from 'constants/wasteTypes';
import useDialog from 'hooks/useDialog';
import { RESET_VALUES_DIALOG_BODY, RESET_VALUES_DIALOG_TITLE } from 'constants/resetValuesStrings';
import { useGetSample } from 'hooks/useGetSample';
import AnalyzesContext from 'contexts/AnalyzesContext';
import { isApproveDisabled } from 'functions/isApproveDisabled';
import useSubmitAnalyzes from 'hooks/useSubmitAnalyzes';
import { SampleInformation } from 'components/sampleInfo/SampleInfo';
import { BasicInputContainer } from 'components/shared/inputs/BasicInputContainer';
import useNavigationBlocker from 'hooks/useNavigationBlocker';
import { inputTypes } from 'constants/inputTypes';
import { ReadingCode } from 'constants/readingCode';
import { calculateWastePercentValue } from 'utils/readingUtils';
import { SupportType } from 'types/supportType';

const { backendBaseUrl } = configuration;

const FieldValue = styled.div<{ color?: string }>`
  p {
    display: inline;
    min-width: 100px;
    text-align: right;
    color: ${({ color }) => color ?? `#565656`};
  }
`;

const Overlay = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;


type GrossNetReading = {
  gross: Reading | undefined;
  net: Reading | undefined;
};

export const ManualAnalysisCard: FC = () => {
  const defaultInputValues = {
    articleNumber: '',
    calibration: '',
    sampleId: '',
    signature: '',
  };
  const { sample, resetSampleValues, getSample, sampleMessages, triedToFetchSample, loading } = useGetSample();
  const [inputValue, setInputValue] = useState({ ...defaultInputValues });
  const controller = new AbortController();
  const support = useSupport(SupportType.MANUAL_INPUT);
  const { location } = useParams<LocationType>();
  const { device, lastIncomingAnalysis, setIncomingAnalyzes, newExtraAnalyzes, text, oldText, setOldText, setText, setNewExtraAnalyzes } = useContext(AnalyzesContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showM3ConnectionError, setShowM3ConnectionError] = useState<boolean>();
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [currentDevice, setCurrentDevice] = useState<IDevice>();
  const { get } = useFetch();
  const [donReadings, setDonReadings] = useState<Reading[]>([]);
  const [nitReadings, setNitReadings] = useState<Reading[]>([]);
  const [vagReadings, setVagReadings] = useState<Reading[]>([]);
  const [fnReadings, setFnReadings] = useState<Reading[]>([]);
  const [grossAndNet, setGrossAndNet] = useState<GrossNetReading | undefined>();
  const { showDialog } = useDialog();
  const { errorMessages, isSaving, setErrorMessages, saveAnalyzes } = useSubmitAnalyzes();

  const getCurrentReadings = () => {
    switch (currentDevice?.deviceType) {
      case DeviceType.DON:
        return donReadings;
      case DeviceType.NIT:
        return nitReadings;
      case DeviceType.VAG:
        return vagReadings;
      case DeviceType.FN:
        return fnReadings;
      default:
        return [];
    }
  };

  const getCurrentReadingsWithValues = () => {
    return getCurrentReadings().filter(r => r.value !== undefined);
  };

  const navigationBlockingConditions = [
    getCurrentReadingsWithValues().length !== 0,
    newExtraAnalyzes.length !== 0,
    newExtraAnalyzes.length !== 0,
    text !== oldText
  ]
  useNavigationBlocker(navigationBlockingConditions);

    // If the user is on a specific device, preselect it
  useEffect(() => {
      device && setCurrentDevice(device)
  }, [device]);

  useEffect(() => {
    const grossNet: GrossNetReading = {
      gross: vagReadings?.find(r => r.sourceName === WasteTypes.GROSS_SOURCE_NAME),
      net: vagReadings?.find(r => r?.sourceName === WasteTypes.NET_SOURCE_NAME),
    };
    setGrossAndNet(grossNet);
  }, [vagReadings]);

  const isGrossNetCorrect = () => {
    /* Check if theres no gross and net values to allow to send only extra analyzes */
    if (!grossAndNet?.gross?.value && !grossAndNet?.net?.value) {
      return true;
    }
    return isGrossNetAmountCorrect(grossAndNet?.gross?.value, grossAndNet?.net?.value);
  };

  const isGrossNetError = () => {
    return (
      !isGrossNetAmountCorrect(grossAndNet?.gross?.value, grossAndNet?.net?.value) &&
      grossAndNet?.net?.value !== undefined &&
      grossAndNet?.gross?.value !== undefined
    );
  };

  useEffect(() => {
    support?.forEach(({ id, devicetype, value }) => {
      const supportItem = { sourceName: id, code: value };
      switch (devicetype) {
        case DeviceType.DON:
          setDonReadings(previousDonReadings => [...previousDonReadings, supportItem]);
          break;
        case DeviceType.NIT:
          setNitReadings(previousNitReadings => [...previousNitReadings, supportItem]);
          break;
        case DeviceType.VAG:
          setVagReadings(previousVagReadings => [...previousVagReadings, supportItem]);
          break;
        case DeviceType.FN:
          setFnReadings(previousFnReadings => [...previousFnReadings, supportItem]);
          break;
        default:
          break;
      }
    });
  }, [support]);

  useEffect(() => {
    setVagReadings(() => {
      const sampleWasteReading = sample?.readings?.find(({code}) => code === ReadingCode.AF_WASTE
      );

      // If found, show Gross, Net and Waste reading
      // else show nothing
      if (sampleWasteReading) {
        return [
          { sourceName: WasteTypes.GROSS_SOURCE_NAME },
          { sourceName: WasteTypes.NET_SOURCE_NAME },
          { sourceName: sampleWasteReading.sourceName, code: sampleWasteReading.code },
        ];
      } else {
        return [];
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sample]);

  const resetValueFields = () => {
    const resetValueField = (r: Reading) => ({ ...r, value: undefined });
    setDonReadings(donReadings.map(r => resetValueField(r)));
    setNitReadings(nitReadings.map(r => resetValueField(r)));
    setVagReadings(vagReadings.map(r => resetValueField(r)));
    setFnReadings(fnReadings.map(r => resetValueField(r)));
  };

  const resetValues = () => {
    setInputValue({ ...defaultInputValues });
    setNewExtraAnalyzes([]);
    setShowM3ConnectionError(false);
    setOldText('');
    setText('');
    resetSampleValues();
    resetValueFields();
    setErrorMessages([]);
  };

  const handleChangeDevice = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    resetValueFields();
    setCurrentDevice(devices.find(d => d.alias === e.target.value));
  };

  const getDevices = useCallback(async () => {
    const response = await get(`${backendBaseUrl}/api/devices?location=${location}`);
    if (!response) {
      setDevices([]);
      return;
    }

    setDevices(await response.json());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, device]);

  const handleBlur = async () => {
    if (inputValue?.sampleId === sample?.id) {
      return;
    }
    setNewExtraAnalyzes([]);
    resetValueFields();
    getSample(inputValue?.sampleId);
  };

  useEffect(
    () => {
      const sampleText = sample?.text ?? '';
      setInputValue({
        ...inputValue,
        articleNumber: sample?.articleNumber ?? '',
        calibration: sample?.grainType ?? '',
      });
      setOldText(sampleText);
      setText(sampleText);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sample]
  );

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue({ ...inputValue, [event.target.name]: event.target.value });
  };

  const handleSampleIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue({ ...inputValue, [event.target.name]: event.target.value.trim() });
  };

  const focusGuard1 = document.querySelector('#focusguard-1');
  const focusGuard2 = document.querySelector('#focusguard-2');
  focusGuard1?.addEventListener('focus', () => {
    document.querySelector<HTMLInputElement>('.last-item')?.focus();
  });
  focusGuard2?.addEventListener('focus', () => {
    document.querySelector<HTMLInputElement>('.first-item')?.focus();
  });

  const handleReadingValueChange = (event: ChangeEvent<HTMLInputElement>, name: string | undefined) => {
    const modifiedReading = getCurrentReadings()?.find(reading => {
      return reading?.sourceName === name;
    });
    const newValue = parseInputNumber(event.target.valueAsNumber);
    if (currentDevice?.deviceType === DeviceType.DON) {
      setReadingValue(setDonReadings, modifiedReading, newValue);
    }
    if (currentDevice?.deviceType === DeviceType.NIT) {
      setReadingValue(setNitReadings, modifiedReading, newValue);
    }
    if (currentDevice?.deviceType === DeviceType.VAG) {
      setReadingValue(setVagReadings, modifiedReading, newValue);
    }
    if (currentDevice?.deviceType === DeviceType.FN) {
      setReadingValue(setFnReadings, modifiedReading, newValue);
    }
  };

  const setReadingValue = (
    setReading: React.Dispatch<React.SetStateAction<Reading[]>>,
    modifiedReading: Reading | undefined,
    newValue: number | undefined
  ) => {
    setReading((previousReadings: Reading[]) =>
      previousReadings.map(reading => {
        if (reading.sourceName === modifiedReading?.sourceName) {
          return { ...reading, value: newValue };
        }
        return reading;
      })
    );
  };

  // effect to handle the calculation of waste when net and gross values are changed
  useEffect(() => {
    if (currentDevice?.deviceType !== DeviceType.VAG) {
      return;
    }

    const calculateGrossNetWaste = () => {
      const wasteReading = vagReadings?.find(r => r?.code === ReadingCode.AF_WASTE);
      const grossValue = grossAndNet?.gross?.value;
      const netValue = grossAndNet?.net?.value;

      //Calculates and sets waste
      if (netValue !== undefined && grossValue !== undefined && wasteReading) {
        const newWasteValue = calculateWastePercentValue(netValue, grossValue);
        setReadingValue(setVagReadings, wasteReading, newWasteValue);
        return;
      }

      setReadingValue(setVagReadings, undefined, undefined);
    };

    calculateGrossNetWaste();

    // Should only be triggered when net or gross values are changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grossAndNet?.gross?.value, grossAndNet?.net?.value]);

  const handleSubmit = async () => {
    document.querySelector<HTMLInputElement>('.first-item')?.focus();

    const newAnalysis: Analysis = {
      inputType: inputTypes.ManualInput,
      sampleId: inputValue.sampleId,
      approvedBy: inputValue.signature,
      deviceId: currentDevice?.id,
      deviceModel: currentDevice?.deviceModel,
      deviceType: currentDevice?.deviceType,
      division: currentDevice?.division,
      calibration: currentDevice?.deviceType === DeviceType.NIT ? inputValue.calibration : undefined,
      location: location,
      readings: getCurrentReadingsWithValues(),
    };

    const analyzesToSave: Analysis[] = newAnalysis.readings?.length ? [...newExtraAnalyzes, newAnalysis] : [...newExtraAnalyzes];
    const saveIsSuccess = await saveAnalyzes(analyzesToSave, inputValue, location, text, oldText, sample);

    if (saveIsSuccess) {
      resetValues();
      setIncomingAnalyzes([]);
      document.querySelector<HTMLInputElement>('.first-item')?.focus();
    }
  };

  const handleClear = () => {
    controller.abort();
    document.querySelector<HTMLInputElement>('.first-item')?.focus();
    showDialog(RESET_VALUES_DIALOG_TITLE, RESET_VALUES_DIALOG_BODY, resetValues, true);
  };

  const enableApproveConditions: boolean[] = [getCurrentReadingsWithValues().length !== 0];
  const disableApproveConditions: boolean[] = [
    currentDevice?.deviceType === DeviceType.VAG && !isGrossNetCorrect(),
    currentDevice?.deviceType === DeviceType.DON && getCurrentReadingsWithValues().length === 0,
  ];
  const isApproveButtonDisabled = isApproveDisabled({
    sample,
    newExtraAnalyzes: newExtraAnalyzes,
    inputValue,
    text,
    oldText,
    isSaving,
    enableConditions: enableApproveConditions,
    disableConditions: disableApproveConditions,
  });

  useEffect(() => {
    if (isApproveButtonDisabled && showModal === false) {
      document.querySelector<HTMLInputElement>('.approve')?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproveButtonDisabled]);

  return (
    <Card>
      {showModal && (
        <ExtraInfo
          sample={sample}
          newExtraAnalyzes={newExtraAnalyzes}
          setNewExtraAnalyzes={setNewExtraAnalyzes}
          location={location}
          onClose={() => setShowModal(false)}
          setText={setText}
          sampleText={text}
        />
      )}
      <div id="focusguard-1" tabIndex={1}></div>
      {isSaving ? (
        <Overlay>
          <LinearLoader />
        </Overlay>
      ) : null}
      {loading ? <LinearLoader /> : null}
      <CardHeader>
        <BannerMessageContainer sampleMessages={sampleMessages} />
        <SampleErrorMessage
          sample={sample}
          sampleId={inputValue?.sampleId}
          triedToFetchSample={triedToFetchSample}
          getSample={getSample}
        />
        <ErrorContainer
          analysis={lastIncomingAnalysis}
          connectionError={showM3ConnectionError}
          sample={sample}
          analysisErrors={errorMessages}
          grossNetError={isGrossNetError()}
        />
        <FlexContainer>
          <FlexItem width={50}>
            <FieldValue>
              <FlexContainer justify="space-between" style={{ height: '58px' }}>
                <FlexItem width={50}>
                  <label htmlFor="signature">Instrument</label>
                </FlexItem>
                <FlexItem width={50}>
                  <TextField
                    fullWidth
                    select
                    value={currentDevice?.alias ?? ''}
                    onChange={handleChangeDevice}
                    disabled={!devices.length}
                    hiddenLabel={true}
                    variant={undefined}
                    style={{ borderColor: '#009fe3' }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {devices
                      ?.filter(d => d.deviceType !== DeviceType.MI)
                      ?.map(d => (
                        <MenuItem key={d.id} value={d.alias}>
                          {d.alias}
                        </MenuItem>
                      ))}
                  </TextField>
                </FlexItem>
              </FlexContainer>
            </FieldValue>
            <FieldValue>
              <FlexContainer justify="space-between">
                <FlexItem width={50}>
                  <label htmlFor="signature">Kalibrerings-ID</label>
                </FlexItem>
                <FlexItem width={50}>
                  <input
                    type="text"
                    id="calibration"
                    name="calibration"
                    value={inputValue.calibration || ''}
                    onChange={handleInputChange}
                    required
                    autoFocus
                    tabIndex={2}
                    disabled={!sample || sample?.grainType !== undefined}
                  />
                </FlexItem>
              </FlexContainer>
            </FieldValue>
            <BasicInputContainer
              inputValue={inputValue}
              handleSignatureChange={handleInputChange}
              handleIDChange={handleSampleIdChange}
              onBlurSampleId={handleBlur}
              focus={'signature'}
            />
          </FlexItem>
          <SampleInformation analysis={lastIncomingAnalysis} sample={sample} />
        </FlexContainer>
      </CardHeader>
      <CardBody>
        <FlexItem spacing={15}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <FlexContainer spacing={4}>
              <InformationButton
                informationAvailable={!!text}
                type="button"
                tabIndex={100}
                onClick={() => setShowModal(true)}
                disabled={!sample || sample.completed}
              >
                Lägg till information
              </InformationButton>
              <FlexItem spacing={4} style={{ marginLeft: 'auto' }}>
                <LmButtonRed type="button" onClick={handleClear} tabIndex={6} className="last-item">
                  Rensa
                </LmButtonRed>
              </FlexItem>
              <FlexItem spacing={4}>
                <LmButtonGreen className="approve" type="submit" disabled={isApproveButtonDisabled} tabIndex={5}>
                  Godkänn
                </LmButtonGreen>
              </FlexItem>
            </FlexContainer>
          </form>
        </FlexItem>
        <FlexItem width={50}>
          <ManualAnalyzes
            sample={sample}
            currentReadings={getCurrentReadings()}
            tabIndexNumber={6}
            handleReadingValueChange={handleReadingValueChange}
          />
        </FlexItem>
        <FlexItem width={50}>
          <ExtraAnalyzes sample={sample} newExtraAnalyzes={newExtraAnalyzes} setNewExtraAnalyzes={setNewExtraAnalyzes} />
        </FlexItem>
      </CardBody>
    </Card>
  );
};
