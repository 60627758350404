import { DeviceType } from "../constants/deviceTypes";

export const getPresentableDeviceType = (input: string): string => {
  const deviceTypes: { [key in DeviceType]: string } = {
    [DeviceType.DON]: 'DON',
    [DeviceType.NIT]: 'NIT',
    [DeviceType.VAG]: 'Avfall',
    [DeviceType.FN]: 'Falltal',
    [DeviceType.MI]: 'Manuellt instrument',
  };

  const matchingDeviceType = Object.keys(deviceTypes).find(
    (key) => key === input
  );

  return matchingDeviceType ? deviceTypes[matchingDeviceType as DeviceType] : input;
};