import styled from '@emotion/styled';
import { ForwardedRef, ReactNode, forwardRef } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
}

const Button = forwardRef(({ children, className, ...props }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <button {...props} className={className + ' lm__base-btn'} ref={ref}>
      {children}
    </button>
  );
});

export const LockButton = styled(Button)`
    margin-bottom: 10px;
`;