import { FcWithChildren } from 'types/fcWithChildren';
import styled from '@emotion/styled';

interface Props {
  onClose?: () => void;
}

const ModalAlert = styled.div`
  display: flex;
  z-index: 10000;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: scroll !important;
`;

const Modal = styled.div`
  display: flex;
  width: 650px;
  max-width: 90%;
  position: relative;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 !important;
  max-height: none !important;
  overflow: visible !important;
`;

export const LmModal: FcWithChildren<Props> = ({
  onClose,
  children,
}) => {
  return (
    <ModalAlert className="lm__modal-alert">
      <Modal className="lm__modal" style={{ maxWidth: '1050px' }}>
        <div className="lm__information-modal__close-btn">
          <div onClick={onClose}></div>
        </div>
        <ModalContent className="modal-content-wrapper">
          {children}
        </ModalContent>
      </Modal>
    </ModalAlert>
  );
};
