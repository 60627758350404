import { useContext, useState } from 'react';
import { Analysis, Reading, Sample, SupportItem } from '../types';
import styled from '@emotion/styled';
import { LmButtonGreen, LmButtonYellow } from './lm2/LmButton';
import { LmModal } from './lm2/LmModal';
import { FlexContainer, FlexItem } from './shared/FlexLayout';
import {
  CardActions,
  CardBody,
  CardHeader,
} from './shared/Card';
import { SupportSelectField } from './Select';
import { useSupport } from '../hooks/useSupport';
import { TextareaField } from './shared/TextareaField';
import { Field, FieldName, FieldValue } from './shared/Field';
import { ReadingCode } from '../constants/readingCode';
import { inputTypes } from '../constants/inputTypes';
import { haveCommonReadings } from '../functions/compareAnalyzesReadings';
import AnalyzesContext from 'contexts/AnalyzesContext';
import { SupportType } from 'types/supportType';

const RemoveButton = styled.button`
  padding: 11px;
  border: 1px solid #ccc;
  border-left-width: 0;
`;
interface ExtraInfoProps {
  sample?: Sample;
  newExtraAnalyzes: Analysis[];
  setNewExtraAnalyzes: (analyzes: Analysis[]) => void;
  location?: string;
  onClose: () => void;
  setText: (text: string) => void;
  sampleText: string;
}

export const ExtraInfo = ({
  onClose,
  sample,
  newExtraAnalyzes,
  setNewExtraAnalyzes,
  location,
  setText,
  sampleText
}: ExtraInfoProps) => {
  const [readings, setReadings] = useState<Reading[]>([]);
  const [editText, setEditText] = useState<string>(sampleText);
  const support = useSupport(SupportType.EXTRA_ANALYSIS);
  const { params: { deviceId } } = useContext(AnalyzesContext);

  if (!sample) {
    return null;
  }

  const getReadingComplete = (): Reading | undefined => {
    const hasReadyReading = getUsedReadingCodes().some(x => x === ReadingCode.ACL_READY_FLAG)
    if(hasReadyReading){
      return;
    }

    const supportItem = support?.find((code) => code.value === ReadingCode.ACL_READY_FLAG);
    if(!supportItem){
      return;
    }

    const reading: Reading = { code: supportItem.value, sourceName: supportItem.id, value: supportItem.defaultValue };
    return reading;
  }

  /**
   * Creates an extra analysis that is not part of required analysis package.
   */
  const handleSubmit = async () => {

    /**
     * check readings if they already have a default value
     */
    const updatedReadings: Reading[] = readings.map((item) => {
      if (item.defaultValue) {
        item.value = item.defaultValue;
        return item;
      }
      return item;
    });

    const newExtraAnalysis: Analysis = {
      sampleId: sample.id,
      deviceId: deviceId,
      inputType: inputTypes.ExtraAnalysis,
      location: location,
      readings: updatedReadings
    };

    setText(editText);

    if (newExtraAnalysis.readings !== undefined && newExtraAnalysis.readings.length !== 0 && !haveCommonReadings(newExtraAnalyzes, [newExtraAnalysis])) {
      setNewExtraAnalyzes([...(newExtraAnalyzes || []), newExtraAnalysis]);
    }
    onClose();
  };

  const getUsedReadingCodes = () => {
    const sampleReadingCodes = sample?.readings?.filter((reading) => reading.result !== '').map((reading) => reading.code) ?? [];
    const notAddedReadingCodes = readings.map(reading => reading.code) ?? []
    const newReadingCodes = newExtraAnalyzes?.flatMap(analysis => analysis.readings?.map(reading => reading.code) ?? []) ?? [];
    return [...notAddedReadingCodes, ...sampleReadingCodes, ...newReadingCodes];
  }

  /**
   *  Get all the extra analysis
   */
  const getAvailableItems = () => {
    const allUsedReadingCodes: Set<string> = new Set();
    getUsedReadingCodes().forEach((code) => code && allUsedReadingCodes.add(code));

    const availableSupportItems = support?.filter(
      (item) => !readings?.some(({ sourceName }) => item.id === sourceName) && !allUsedReadingCodes.has(item.value)
    );

    return availableSupportItems;
  }


  const isSaveDisabled = () => {
    if (readings.length > 0) {
      return false
    } else if ((editText ?? "") !== (sampleText ?? "")) {
      return false
    } else {
      return true
    }
  }

  const addReading = (item: SupportItem) => {
    const newReadings: Reading[] = [{
      code: item.value,
      sourceName: item.id,
      editable: item.editable ?? false,
      defaultValue: item.defaultValue ?? 0
    }];

    if (newReadings[0].code === ReadingCode.ACL_DECLINED){
      const readingsWithAclApprove = getReadingComplete()
      if (readingsWithAclApprove) {
        newReadings.push(readingsWithAclApprove)
      }
    }

    setReadings((existingReading) => [
      ...existingReading,
      ...newReadings
    ])
  }

  return (
    <LmModal key={sample.id}>
      <CardHeader>
        <h1>Extra info - {sample.id}</h1>
        <TextareaField
          label="Fritext"
          id="fritext"
          name="fritext"
          placeholder="Ange fritext"
          value={editText}
          onChange={(event) => {
            setEditText(event.target.value)
          }}
          required
        />
        <SupportSelectField
          label="Lägg till analyser"
          items={getAvailableItems()}
          onChange={addReading}
        />
      </CardHeader>
      <CardBody key={sample?.id}>
        {readings.map((reading) => {
          return (
            <Field key={'key' + reading?.code}>
              <FieldName>
                <span>{reading.code}</span> <b>{reading?.sourceName}</b>
              </FieldName>
              <FieldValue>
                <FlexContainer>
                  <FlexItem>
                    {
                      reading.editable && !reading.defaultValue ? (
                        <input
                          type="number"
                          onWheel={event => event.currentTarget.blur()}
                          id="extraAnalysis"
                          name={reading.sourceName}
                          value={reading.value ?? ''}
                          onChange={(event) =>
                            setReadings((existingReadings) =>
                              existingReadings.map((existingReading) =>
                                existingReading.sourceName !== reading.sourceName
                                  ? existingReading
                                  : {
                                    ...existingReading,
                                    value: +event.target.value,
                                  }
                              )
                            )
                          }

                        />) : null
                    }
                    {
                      !reading.editable && reading.defaultValue ? (
                        <input
                          style={{ border: 0 }}
                          type="number"
                          onWheel={event => event.currentTarget.blur()}
                          id="extraAnalysis"
                          name={reading.sourceName}
                          value={reading.defaultValue}
                          disabled
                        />
                      ) : null
                    }
                  </FlexItem>
                  <FlexItem>
                    <RemoveButton
                      onClick={() =>
                        setReadings((existingReadings) =>
                          existingReadings.filter(
                            (existingReading) => existingReading !== reading
                          )
                        )
                      }
                    >
                      x
                    </RemoveButton>
                  </FlexItem>
                </FlexContainer>
              </FieldValue>
            </Field>
          );
        })}
      </CardBody>
      <CardActions>
        <LmButtonYellow onClick={onClose}>Avbryt</LmButtonYellow>
        <LmButtonGreen onClick={handleSubmit} disabled={isSaveDisabled()}>
          Lägg till
        </LmButtonGreen>
      </CardActions>
    </LmModal>
  );
};
