import { useContext, useEffect, useState } from 'react';
import { Analysis, Reading } from '../types';
import { LmBlock } from './lm2/LmBlock';
import { ScaleCard } from './ScaleCard';
import useDialog from 'hooks/useDialog';
import { INCOMING_ANALYSIS_ALERT_BODY, INCOMING_ANALYSIS_ALERT_TITLE } from 'constants/incomingAnalysisAlertStrings';
import AnalyzesContext from 'contexts/AnalyzesContext';
import { ReadingCode } from 'constants/readingCode';
import { WasteTypes } from 'constants/wasteTypes';
import { useGetSample } from 'hooks/useGetSample';
import { calculateWastePercentValue } from 'utils/readingUtils';

export const NewScaleAnalysis = () => {
  const [grossAnalysis, setGrossAnalysis] = useState<Analysis | undefined>();
  const [netAnalysis, setNetAnalysis] = useState<Analysis | undefined>();
  const [newAnalysis, setNewAnalysis] = useState<Analysis>();
  const [previousAnalyzes, setPreviousAnalyzes] = useState<Analysis[]>([]);
  const { showDialog } = useDialog();
  const { incomingAnalyzes } = useContext(AnalyzesContext);
  const useSample = useGetSample();

  const handleAcceptIncomingAnalysis = () => {
    setGrossAnalysis(incomingAnalyzes ? incomingAnalyzes[0] : undefined);
    setNetAnalysis(undefined);
    setNewAnalysis(undefined);
  };

  // Handle incoming analyzes
  useEffect(() => {
    if (incomingAnalyzes === undefined || incomingAnalyzes?.length === 0) {
      setGrossAnalysis(undefined);
      setNetAnalysis(undefined);
      return;
    }
    const firstIncomingAnalysis = incomingAnalyzes[0];

    // No gross analysis, set the first incoming analysis as gross analysis
    if (!grossAnalysis) {
      setGrossAnalysis(firstIncomingAnalysis);
      return;
    }

    // No net analysis, set the first incoming analysis as net analysis
    if (!netAnalysis && grossAnalysis !== firstIncomingAnalysis) {
      setNetAnalysis(firstIncomingAnalysis);
      return;
    }

    // If the first incoming analysis is different from the previous one, show the incoming analysis alert
    if (previousAnalyzes[0]?.id !== firstIncomingAnalysis.id) {
      showDialog(INCOMING_ANALYSIS_ALERT_TITLE, INCOMING_ANALYSIS_ALERT_BODY, handleAcceptIncomingAnalysis, true);
    }

    setPreviousAnalyzes(incomingAnalyzes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingAnalyzes]);


  const createReading = (sourceName?: string, value?: number, code?: string): Reading => {
    return { sourceName, code, value};
  }

  // Create a new analysis based on the gross and net analysis
  useEffect(() => {
    if (!grossAnalysis?.readings?.length) {
      setNewAnalysis(undefined);
      return
    }

    const { deviceId, deviceModel, deviceType, division, location, eventId } = grossAnalysis;
    const grossValue = grossAnalysis?.readings?.[0]?.value;
    const netValue = netAnalysis?.readings?.[0]?.value;

    const refinedAnalysis: Analysis = {
      deviceId,
      deviceModel,
      deviceType,
      division,
      eventId,
      location,
      readings: [createReading(WasteTypes.GROSS_SOURCE_NAME, grossValue)],
    };

    // If both gross and net analysis are available, calculate the waste percentage
    // and create a net reading and waste reading
    if (netValue !== undefined && grossValue !== undefined) {
      refinedAnalysis.readings = [
        ...refinedAnalysis.readings ?? [],
        createReading(WasteTypes.NET_SOURCE_NAME, netValue),
        // TODO: sourceName and code should be fetched from the support table
        createReading(WasteTypes.WASTE_SOURCE_NAME, calculateWastePercentValue(netValue, grossValue), ReadingCode.AF_WASTE)
      ];
    }
    setNewAnalysis(refinedAnalysis);
  }, [grossAnalysis, netAnalysis, useSample.sample?.readings]);

  return (
    <LmBlock>
      <ScaleCard
        analysis={newAnalysis}
        {...useSample}
      />
    </LmBlock>
  );
};
