import { ErrorMessage } from "./NitAnalysisCard/components/ErrorMessage";
import { Analysis, Sample } from "../types";
import { DeviceType } from "../constants/deviceTypes";

interface ErrorContainerProps {
    analysis?: Analysis;
    sample?: Sample;
    calibration?: boolean;
    connectionError?: boolean;
    grossNetError?: boolean;
    analysisErrors: string[];
}

export function ErrorContainer({
    analysis,
    calibration,
    sample,
    connectionError,
    grossNetError,
    analysisErrors
}: Readonly<ErrorContainerProps>) {
    return (
        <>
            {sample?.completed &&
                <ErrorMessage>
                    Varning! ProvID är klarflaggat.
                </ErrorMessage>}
            {!calibration && sample && analysis && analysis.deviceType === DeviceType.NIT &&
                <ErrorMessage>
                    Varning! Fel kalibrering vald.
                </ErrorMessage>}
            {connectionError &&
                <ErrorMessage>
                    Fel! Ingen kontakt med M3-databasen.
                </ErrorMessage>}
            {grossNetError &&
                <ErrorMessage>
                    Varning! Felaktiga brutto/netto värden angivna.
                </ErrorMessage>}
            {analysisErrors.map((message) =>
                <ErrorMessage key={message}>
                    {message}
                </ErrorMessage>)}
        </>
    );
}