import { useEffect } from "react";
import { TabOrderProps } from "../models/Props";
import { BASIC_INPUT_REFS } from "components/shared/inputs/BasicInputContainer";
import { NIT_CARD_BUTTON_REFS } from "./NitCardButtonPanel";

export function TabOrder({
    children,
    focus,
    setFocus,
    refs,
    hidden
}: TabOrderProps) {

    useEffect(() => {
        const handleTabKey = (event: KeyboardEvent) => {
          if (event.key !== 'Tab') {
            return
          }
          const { buttons } = refs;
          switch(focus) {
            case BASIC_INPUT_REFS.SIGN:
                setFocus(BASIC_INPUT_REFS.SAMPLE);
                break;
            case BASIC_INPUT_REFS.SAMPLE:
              if (buttons?.current.anyDisabled()[NIT_CARD_BUTTON_REFS.APPROVE] && hidden === false) {
                setFocus(NIT_CARD_BUTTON_REFS.CLEAN);
              } else {
                setFocus(NIT_CARD_BUTTON_REFS.APPROVE);
              }
              break;
            case NIT_CARD_BUTTON_REFS.CLEAN:
              if (buttons?.current.anyDisabled()[NIT_CARD_BUTTON_REFS.INFORMATION]) {
                if (buttons?.current.anyDisabled()[NIT_CARD_BUTTON_REFS.LOCK]) {
                  setFocus(BASIC_INPUT_REFS.SIGN);
                } else {
                  setFocus(NIT_CARD_BUTTON_REFS.APPROVE);
                }
              } else {
                setFocus(NIT_CARD_BUTTON_REFS.INFORMATION);
              }
              break;
            case NIT_CARD_BUTTON_REFS.INFORMATION:
              if (buttons?.current.anyDisabled()[NIT_CARD_BUTTON_REFS.LOCK]) {
                setFocus(BASIC_INPUT_REFS.SIGN);
              } else {
                setFocus(NIT_CARD_BUTTON_REFS.LOCK);
              }
              break;
            case NIT_CARD_BUTTON_REFS.LOCK:
              setFocus(BASIC_INPUT_REFS.SIGN);
              break;
            case NIT_CARD_BUTTON_REFS.APPROVE:
              setFocus(BASIC_INPUT_REFS.SIGN);
              break;
            default:
              break;
          }
          event.preventDefault();
          return;
        };
        window.addEventListener('keydown', handleTabKey);
        return () => {
          window.removeEventListener('keydown', handleTabKey);
        }
      }, [focus, setFocus, refs, hidden])

    return (
        <>
        {children}
        </>
    )
}