import { LmButton } from '../components/lm2/LmButton';
import { useParams, useNavigate } from 'react-router';
import { PageHead } from '../components/PageHead';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Breadcrumb } from '../components/BreadCrumb';
import { Breadcrumbs } from '../components/BreadCrumbs';

type LocationType = {
  deviceId: string;
  location: string;
};

const LmLink = styled(Link)`
  background-color: #009636;
  color: #fff;
  border-color: #009636;
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  padding: 20px 30px;
  border: 2px solid;
  font-family: LMSans, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  transition: background-color 0.1s;
  cursor: pointer;
  :hover {
    color: #fff;
  }
`;

export const Device = () => {
  const { deviceId, location } = useParams<LocationType>();
  const navigate = useNavigate();

  const click = () => {
    navigate(`${location}`);
  };

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb to={`/`}>Välj Ort</Breadcrumb>
        <Breadcrumb to={`/${location}`}>{location}</Breadcrumb>
        <Breadcrumb to={`/${location}/${deviceId}`}>{deviceId} </Breadcrumb>
      </Breadcrumbs>
      <PageHead>
        <h2>{deviceId}</h2>
        <LmButton onClick={click}>Byt Instrument</LmButton>
      </PageHead>
      <LmLink to={`/${location}/${deviceId}/analyzes`}>Ny mätningt</LmLink>
    </>
  );
};
