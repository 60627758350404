import { useContext, useEffect, useState } from 'react';
import { Analysis, InputValueProps } from '../../types';
import { FnAnalysisCard, defaultInputValues } from 'components/fnAnalysisCard/FnAnalysisCard';
import { LmBlock } from '../lm2/LmBlock';
import { FlexContainer, FlexItem } from '../shared/FlexLayout';
import useDialog from 'hooks/useDialog';
import {
  INCOMING_ANALYSIS_ALERT_BODY,
  INCOMING_LEFT_ANALYSIS_ALERT_TITLE,
  INCOMING_RIGHT_ANALYSIS_ALERT_TITLE,
} from 'constants/incomingAnalysisAlertStrings';
import FnAnalysisContextProvider, { FnAnalysisCardContext } from './fnAnalysisCardContext';
import { AnalysisPosition, SetBothSignaturesType } from './fnAnalysisTypes';
import { SAME_FN_SAMPLE_ID_DIALOG_BODY, SAME_FN_SAMPLE_ID_DIALOG_TITLE } from 'constants/sameFnSampleIdStrings';
import AnalyzesContext from 'contexts/AnalyzesContext';
import useNavigationBlocker from 'hooks/useNavigationBlocker';

const NewFnAnalysis = () => {
  const {
    isSameSampleIdInLeftAndRight,
    averageIsChecked,
    setAverageIsChecked,
    setLastIncomingAnalyzes,
    leftState: { leftAnalysis,
      setLeftAnalysis,
      lastIncomingLeftAnalysis,
      setLastIncomingLeftAnalysis,
      leftNewExtraAnalyzes,
      leftOldText,
      leftText },
    rightState: {
      rightAnalysis,
      setRightAnalysis,
      lastIncomingRightAnalysis,
      setLastIncomingRightAnalysis,
      rightNewExtraAnalyzes,
      rightOldText,
      rightText,
    }
  } = useContext(FnAnalysisCardContext);
  const [averageAnalysis, setAverageAnalysis] = useState<Analysis>();

  const [leftInputValue, setLeftInputValue] = useState<InputValueProps>({ ...defaultInputValues });
  const [rightInputValue, setRightInputValue] = useState<InputValueProps>({
    ...defaultInputValues,
  });

  const { showDialog } = useDialog();
  const { incomingAnalyzes } = useContext(AnalyzesContext);

  const navigationBlockingConditions = [
    leftAnalysis !== undefined,
    rightAnalysis !== undefined,
    leftNewExtraAnalyzes.length !== 0,
    rightNewExtraAnalyzes.length !== 0,
    leftText !== leftOldText,
    rightText !== rightOldText
  ]
  useNavigationBlocker(navigationBlockingConditions);

  const handleAcceptIncomingAnalysis = (cardPosition: AnalysisPosition) => {
    if (cardPosition === AnalysisPosition.LEFT) {
      setLeftAnalysis(lastIncomingLeftAnalysis);
      setLastIncomingLeftAnalysis(undefined);
      return;
    }

      setRightAnalysis(lastIncomingRightAnalysis);
      setLastIncomingRightAnalysis(undefined);
  };

  useEffect(() => {
    setLastIncomingAnalyzes(incomingAnalyzes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingAnalyzes]);

  const setBothSignatures: SetBothSignaturesType = (newSignatureValue, analysisPosition, clearValues) => {
    // If clearValues is true, reset the input values for the specified analysis position
    if (clearValues) {
      if (analysisPosition === AnalysisPosition.LEFT) {
        setLeftInputValue({ ...defaultInputValues });
        // If the right rightInputValue doesn't have a sample ID, reset its input values too
        if (!rightInputValue?.sampleId) {
          setRightInputValue({ ...defaultInputValues });
        }
      } else if (analysisPosition === AnalysisPosition.RIGHT) {
        setRightInputValue({ ...defaultInputValues });
        // If the left rightInputValue doesn't have a sample ID, reset its input values too
        if (!leftInputValue?.sampleId) {
          setLeftInputValue({ ...defaultInputValues });
        }
      }
      return;
    }

    // Set the signature value for both input values
    setRightInputValue({ ...rightInputValue, signature: newSignatureValue });
    setLeftInputValue({ ...leftInputValue, signature: newSignatureValue });
  };

  useEffect(() => {
    if (leftAnalysis && lastIncomingLeftAnalysis && leftAnalysis.eventId !== lastIncomingLeftAnalysis?.eventId) {
      showDialog(INCOMING_LEFT_ANALYSIS_ALERT_TITLE,
        INCOMING_ANALYSIS_ALERT_BODY,
        () => handleAcceptIncomingAnalysis(AnalysisPosition.LEFT),
        true);
    }

    if (rightAnalysis && lastIncomingRightAnalysis && rightAnalysis.eventId !== lastIncomingRightAnalysis?.eventId) {
      showDialog(INCOMING_RIGHT_ANALYSIS_ALERT_TITLE,
        INCOMING_ANALYSIS_ALERT_BODY,
        () => handleAcceptIncomingAnalysis(AnalysisPosition.RIGHT),
        true);
    }

    if (leftAnalysis === undefined) {
      setLeftAnalysis(lastIncomingLeftAnalysis);
    }

    if (rightAnalysis === undefined) {
      setRightAnalysis(lastIncomingRightAnalysis);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastIncomingLeftAnalysis, lastIncomingRightAnalysis]);

  useEffect(() => {
    if (isSameSampleIdInLeftAndRight) {
      setAverageIsChecked(true)
      showDialog(
        SAME_FN_SAMPLE_ID_DIALOG_TITLE,
        SAME_FN_SAMPLE_ID_DIALOG_BODY,
        () => setAverageIsChecked(true),
        false,
        () => {
          setAverageIsChecked(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSameSampleIdInLeftAndRight]);


  useEffect(() => {
    const lastReceivedAnalysis = incomingAnalyzes?.find(item => item);
    const leftReadingValue = leftAnalysis?.readings && leftAnalysis?.readings[0]?.value;
    const rightReadingValue = rightAnalysis?.readings && rightAnalysis?.readings[0]?.value;

    if (lastReceivedAnalysis && leftReadingValue && rightReadingValue && averageIsChecked) {
      const averageReadingValue = (leftReadingValue + rightReadingValue) / 2;

      const { deviceId, deviceModel, deviceType, division, eventId, location } = lastReceivedAnalysis;
      const refinedAnalysis: Analysis = {
        deviceId,
        deviceModel,
        deviceType,
        division,
        eventId,
        location,
        readings: [{ ...lastReceivedAnalysis.readings?.[0], value: averageReadingValue }],
      };
      setAverageAnalysis(refinedAnalysis);
      return;
    }

    setAverageAnalysis(undefined);
  }, [incomingAnalyzes, averageIsChecked, leftAnalysis?.readings, rightAnalysis?.readings]);

  const focusGuard1 = document.querySelector('#focusguard-1');
  const focusGuard2 = document.querySelector('#focusguard-2');
  focusGuard1?.addEventListener('focus', () => {
    document.querySelector<HTMLInputElement>('.last-item')?.focus();
  });
  focusGuard2?.addEventListener('focus', () => {
    document.querySelector<HTMLInputElement>('.first-item')?.focus();
  });

  return (
    <LmBlock>
      <div id="focusguard-1" tabIndex={1}></div>
      <FlexContainer spacing={5} alignItems="flex-start">
        <FlexItem spacing={5} width={50}>
          <FnAnalysisCard
            cardPosition={AnalysisPosition.LEFT}
            analysis={averageAnalysis || leftAnalysis}
            tabIndexNumbers={[2, 3, 6, 8, 10, 12]}
            inputValue={leftInputValue}
            setInputValue={setLeftInputValue}
            setBothSignatures={setBothSignatures}
          />
        </FlexItem>
        <FlexItem spacing={5} width={50}>
          <FnAnalysisCard
            cardPosition={AnalysisPosition.RIGHT}
            analysis={rightAnalysis}
            tabIndexNumbers={!averageIsChecked ? [4, 5, 9, 11, 13] : []}
            inputValue={rightInputValue}
            setInputValue={setRightInputValue}
            setBothSignatures={setBothSignatures}
          />
        </FlexItem>
      </FlexContainer>
      <div id="focusguard-2" tabIndex={13}></div>
    </LmBlock>
  );
};

export const NewFnAnalysisWithContext: React.FC = () => {
  return (
    <FnAnalysisContextProvider>
      <NewFnAnalysis />
    </FnAnalysisContextProvider>
  );
};
