export enum ReadingCode {
  ACL_DECLINED = 'L9099',
  ACL_READY_FLAG = 'L2050',
  AF_WASTE = 'L0013',
  EMPTY_STRING = '',
  FN_FALLING_NUMBER = 'L0020',
  NIT_COLOR = 'L0030',
  NIT_GLUTEN = 'L1081',
  NIT_PROTEIN = 'L0041',
  NIT_SPECIFIC_WEIGHT = 'L0040',
  NIT_STARCH_DM = 'L1067',
  NIT_WATER = 'L0988',
  NO_DATA = 'Ingen data hittades',
  RSLT_APPROVED = 'G'
}