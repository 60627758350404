import { ForwardedRef, forwardRef } from 'react';
import { FlexContainer, FlexItem } from '../FlexLayout';
import { FieldContainerProps } from '../../NitAnalysisCard/models/Props';
import { FieldValue } from '../../NitAnalysisCard/styles/styles';

export const FieldContainer = forwardRef(
  (
    {
      id,
      title,
      value,
      onChange,
      onBlur,
      placeholder,
      tabIndex: index,
      className,
      disabled = false,
      autofocus = false,
      onFocus,
      required = true,
    }: FieldContainerProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <FieldValue>
        <FlexContainer justify="space-between">
          <FlexItem width={50}>
            <label htmlFor={id}>{title}</label>
          </FlexItem>
          <FlexItem width={50}>
            <input
              type="text"
              id={id}
              name={id}
              placeholder={placeholder}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              required={required}
              autoFocus={autofocus}
              tabIndex={index}
              className={className}
              disabled={disabled}
              onFocus={e => onFocus && onFocus(e, id)}
              ref={ref}
            />
          </FlexItem>
        </FlexContainer>
      </FieldValue>
    );
  }
);
