import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { FullScreen } from 'components/Fullscreen';
import { NewNitAnalysis } from 'components/NewNitAnalysis';
import { NewScaleAnalysis } from 'components/NewScaleAnalysis';
import { SampleContext } from 'components/SampleContainer';
import { Tabs } from 'components/Tabs';
import { BreadcrumbsForAnalyzes } from 'components/analyzes/BreadCrumbsForAnalyzes';
import { NewFnAnalysisWithContext } from 'components/fnAnalysisCard/NewFnAnalysis';
import { LmBlock } from 'components/lm2/LmBlock';
import { NewManualAnalysis } from 'components/manualInstrument/NewManualAnalysis';
import { configuration } from 'config';
import { DeviceType } from 'constants/deviceTypes';
import AnalyzesContext, { AnalyzesContextProvider, AnalyzesParmasType } from 'contexts/AnalyzesContext';
import useFetch from 'hooks/useFetch';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Analysis } from 'types';
import { AnalyzesTableWithContext } from 'components/analyzesTable/AnalyzesTableContainer';
import { TabNames } from 'constants/tabNames';
import { MissingAnalyzesWithContext } from 'components/missingAnalyzes/MissingAnalyzesContainer';

const { backendBaseUrl } = configuration;

const Analyzes = () => {
  const params = useParams<AnalyzesParmasType>();
  const [connection, setConnection] = useState<HubConnection>();
  const { get } = useFetch();
  const { setLocation } = useContext(SampleContext);

  const {
    device,
    setDevice,
    params: { tab, deviceId },
    setParams,
    addAnalysisToIncomingAnalyzes,
  } = useContext(AnalyzesContext);

  useEffect(() => {
    setLocation(params?.location ?? '');
  }, [params?.location, setLocation]);

  useEffect(() => {
    setParams(params);

    // Only run when params changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.deviceId, params.tab, params.location]);


  const handleAnalysisFromSignalR = useCallback((analysis: Analysis) => {
    addAnalysisToIncomingAnalyzes(analysis);
    // Creates a memoized function on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Start connection to signalR server
  const startConnectionToSignalR = async () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${backendBaseUrl}/api/`)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 60000) {
            return 2000;
          } else {
            return 20000;
          }
        },
      })
      .build();
    await newConnection.start();
    setConnection(newConnection);
  };

  useEffect(() => {
    startConnectionToSignalR();
  }, []);

  // If connected, start listening and filter on deviceId.
  // When deviceId matches, call handleAnalysis(analysis).
  // The returned function cleans up before useEffect runs again.
  useEffect(() => {
    if (connection && deviceId) {
      connection.on(deviceId, handleAnalysisFromSignalR);

      return () => {
        connection.off(deviceId, handleAnalysisFromSignalR);
      };
    }
  }, [connection, deviceId, handleAnalysisFromSignalR]);

  // Get device, if device id is changed
  useEffect(() => {
    const getDevice = async () => {
      const response = await get(`${backendBaseUrl}/api/devices/${deviceId}`);
      if (!response) {
        setDevice(undefined);
        return;
      }
      setDevice(await response.json());
    };
    deviceId && getDevice();
    // only run when deviceId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  const getAnalysisComponent = (): JSX.Element => {
    switch (device?.deviceType) {
      case DeviceType.FN:
        return <NewFnAnalysisWithContext />;
      case DeviceType.VAG:
        return <NewScaleAnalysis />;
      case DeviceType.NIT:
        return <NewNitAnalysis />;
      case DeviceType.MI:
      case DeviceType.DON:
        return <NewManualAnalysis />;
    }
    return <></>;
  };

  return (
    <>
      <BreadcrumbsForAnalyzes />
      <Tabs />
      <FullScreen>
        {tab === TabNames.NEW && getAnalysisComponent()}
        {tab === TabNames.ALL && (
          <LmBlock>
            <AnalyzesTableWithContext />
          </LmBlock>
        )}
        {tab === TabNames.MISSING && (
          <LmBlock>
            <MissingAnalyzesWithContext />
          </LmBlock>
        )}
      </FullScreen>
    </>
  );
};

export const AnalyzesWithContext: React.FC = () => {
  return (
    <AnalyzesContextProvider>
      <Analyzes />
    </AnalyzesContextProvider>
  );
};
