import { Card, CircularProgress, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import styled from '@emotion/styled';
import { FC } from 'react';

const StyledLabDcTable = styled.div`
  padding: 20px 0;
`;

interface IAnalyzesTableProps {
  isLoading: boolean;
  tableHeader: JSX.Element;
  tableRows: JSX.Element[];
}

export const LabDcTable: FC<IAnalyzesTableProps> = ({ isLoading, tableHeader, tableRows }) => {
  return (
    <StyledLabDcTable>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="center">
            {isLoading ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card>
                  <TableContainer>
                    <Table size="small" aria-label="Events table">
                      <TableHead>
                        <TableRow>
                          {tableHeader}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableRows}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </StyledLabDcTable>

  );
};
