import { useEffect } from "react";
import useDialog from "./useDialog";
import { useBlocker } from "react-router-dom";

const useNavigationBlocker = (blockingConditions: boolean[]) => {
  const DIALOG_TITLE = 'Osparade ändringar';
  const DIALOG_BODY = 'Det finns osparade ändring på sidan, vill du lämna ändå?';
   const { showDialog } = useDialog();

  const shouldBlockNavigation = () => {
    return blockingConditions.some((condition) => condition === true);
  };
   const blocker =  useBlocker(shouldBlockNavigation())

   useEffect(() => {
    if (blocker.state === 'blocked') {
      showDialog(
        DIALOG_TITLE,
        DIALOG_BODY,
        () => {
          blocker.proceed();
        },
        true,
        () => {
          blocker.reset();
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocker.state])
}

export default useNavigationBlocker;