/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import Select, { components, InputProps, MultiValue } from 'react-select';
import { SupportItem } from '../types';
import { FlexContainer, FlexItem } from './shared/FlexLayout';

const STYLED_SELECT_PLACERHOLDER_TEXT = 'Välj...'

const StyledSelect = styled.div`
  input {
    border: 0 !important;
    padding: 0 !important;
  }
`;

const StyledInput = styled.div`
  margin: 2 px;
  padding-bottom: 2 px;
  padding-top: 2 px;
  visibility: visible;
  color: hsl(0, 0%, 20%);
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  box-sizing: border-box;
  border: 0;

  & input {
    border: 0 !important;
    padding: 0 !important;
  }
`;

const Input = (props: InputProps<SupportItem, true>) => {
  if (props.isHidden) {
    return <components.Input {...props} />;
  }
  return (
    <StyledInput>
      <components.Input {...props} />
    </StyledInput>
  );
};

interface Props {
  items: MultiValue<SupportItem>;
  onChange: (items: SupportItem) => void;
}

const SupportSelect = (props: Props) => {
  const styles = {
    control: (provided: any, state: any) => ({
      ...provided,
      padding: 0,
      borderRadius: 0,
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: '11px 10px!important',
      borderRadius: 0,
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      border: 0,
    }),
  };

  return (
    <StyledSelect>
      <Select
        value={null}
        styles={styles}
        components={{ Input }}
        options={props.items}
        getOptionLabel={(item: SupportItem) => item.value + ' ' + item.id}
        onChange={(item: any) => props.onChange(item)}
        placeholder={STYLED_SELECT_PLACERHOLDER_TEXT}
      />
    </StyledSelect>
  );
};

interface FieldProps extends Props {
  label: string;
}

export const SupportSelectField = ({ label, ...props }: FieldProps) => {
  return (
    <FlexContainer justify="space-between">
      <FlexItem>
        <label>{label}</label>
      </FlexItem>
      <FlexItem width={60}>
        <SupportSelect {...props} />
      </FlexItem>
    </FlexContainer>
  );
};
