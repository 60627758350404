import React from 'react';
import styled from '@emotion/styled';
import {TableRow as MuiTableRow, TableCell} from '@mui/material';
import { Analysis, TableSample } from 'types';
import { AnalysisTableRowReading } from 'components/AnalysisTableRowReading';
import { formatToDate } from 'functions/dateFormatter';
import { ApprovalInfoTableCell, InstrumentCodeAndPositionTableCell } from './TableCells';
import { TableRow } from 'components/table/TableRow';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: none;
`;

const StyledTableCell = styled.td`
  background-color: #eef1d8;
  color: #134d25;
`;

const StyledMultiTableCell = styled.td`
  border-bottom: none;
`;

export const AnalyzesTableRow = ({ sample }: { sample: TableSample }) => {
  const firstCreatedAnalysisTime = sample?.firstCreatedAnalysis?.approvedDate;

  const getTableRowHeader = (a: Analysis) => {
    if (a.readings?.length) {
      return (
        <StyledContainer>
          <InstrumentCodeAndPositionTableCell analysis={a} />
          <ApprovalInfoTableCell analysis={a} />
        </StyledContainer>
      );
    }

    return null;
  };

  return (
    <TableRow key={sample.id}
      headerContent={
        <React.Fragment> 
          <TableCell align="left">{sample.id}</TableCell>
          <TableCell align="left">{sample.articleNumber}</TableCell>
          <TableCell align="left">{sample.articleName}</TableCell>
          <TableCell align="left">
            {formatToDate(firstCreatedAnalysisTime)}
          </TableCell>
          <TableCell />
        </React.Fragment> 
      }
      bodyContent={
        <React.Fragment> 
          {sample.analyzes.map((analysis: Analysis) => {
            return (
              <React.Fragment key={`${analysis.id}`}> 
                <MuiTableRow key={`AARF${analysis.id}`}>
                  <StyledTableCell>
                    {getTableRowHeader(analysis)}
                  </StyledTableCell>
                </MuiTableRow> 
                
                {analysis.readings ? (
                    <MuiTableRow key={`AATR${analysis.id}`}>
                      <StyledMultiTableCell key={`AATC${analysis.id}`} align="left">
                        {analysis.readings?.map((reading, rowIndex) => (
                          <AnalysisTableRowReading
                            rowIndex={rowIndex}
                            readingsLength={analysis.readings ? analysis.readings.length : 0}
                            key={`AARI${reading.code ?? reading.sourceName}${reading.sentToM3}`}
                            reading={reading} />
                        ))}
                      </StyledMultiTableCell>
                    </MuiTableRow>
                  ) : null}
             
              </React.Fragment>  
            );
          })}
        </React.Fragment> 
      }

    />

  );
};