import styled from '@emotion/styled';
import { ForwardedRef, ReactNode, forwardRef } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  informationAvailable: boolean
}

const Button = forwardRef(({ children, className, informationAvailable, ...props }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <button {...props} className={className + ' lm__base-btn'} ref={ref}>
      {children}
    </button>
  );
});

export const InformationButton = styled(Button)`
  background-color: ${props => props?.informationAvailable ? '#009fe3' : '#FFFFFF'};
  color: ${props => props?.informationAvailable ? '#FFFFFF' : '#009fe3'};
  &:focus {
    outline: 2px solid orange;
  }
`;
