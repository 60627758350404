import styled from '@emotion/styled';

export const Card = styled.div`
  position: relative;
  border: 1px solid silver;
  margin-bottom: 25px;
`;

export const CardHeader = styled.div`
  background-color: #fafafa;
  padding: 20px;
  @media (max-width: 650px) {
    padding: 10px;
  }
`;

export const CardHeaderWhite = styled.div`
  padding: 20px;
  @media (max-width: 650px) {
    padding: 10px;
  }
`;

export const CardBody = styled.div`
  flex: 1;
  padding: 10px;
`;

export const CardRow = styled.div<{ row?: boolean; right?: boolean }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const CardActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  @media (max-width: 650px) {
    padding: 10px;
  }
`;
