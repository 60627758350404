import { FormControl, InputLabel, Checkbox as MuiCheckbox, TextField } from "@mui/material";
import { styled } from '@mui/system';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { colors } from "./styles/colors";

export const Checkbox = styled(MuiCheckbox)({
  color: colors.green.dark,
  '&.Mui-checked': {
    color: colors.green.dark,
  },
});

export const StyledFormControl = styled(FormControl)({
  minWidth: 211,
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.green.dark,
  },
});

export const StyledInputLabel = styled(InputLabel)({
  '&.Mui-focused': { color: colors.green.dark }
});

export const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': { border: 'none !important' }, // !important is needed to override the default border
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.green.dark,
    },
  },
  '& label.Mui-focused': {
    color: colors.green.dark,
  },
});

export const StyledDatePicker = styled(DatePicker<Dayjs>)({
  '& .MuiInputBase-input': { border: 'none !important' }, // !important is needed to override the default border
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.green.dark,
    },
  },
  '& label.Mui-focused': {
    color: colors.green.dark,
  },
});
