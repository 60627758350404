import { TableCell, TableRow as MuiTableRow } from '@mui/material';
import { Reading } from 'types';
import { formatToDate } from 'functions/dateFormatter';
import { TableRow} from 'components/table/TableRow';
import { TableReading } from 'components/table/TableReading';
import { SampleWithMissingAnalyzes } from 'types/sampleWithMissingAnalyzes';
import styled from '@emotion/styled';

const StyledMultiTableCell = styled.td`
  border-bottom: none;
`;

export const AnalyzesTableRow = ({ sample }: { sample: SampleWithMissingAnalyzes }) => {

  return (
    <TableRow
      headerContent={
        <>
          <TableCell align="left">{sample.sampleId}</TableCell>
          <TableCell align="left">{sample.articleNumber}</TableCell>
          <TableCell align="left">{sample.articleName}</TableCell>
          <TableCell align="left">
            {formatToDate(sample.created)}
          </TableCell>
          <TableCell align="left">
            {sample.location}
          </TableCell>
          <TableCell />
        </>
      }
      bodyContent={
        <>
          {sample.missingReadings?.map((sampleReading: Reading) => {
            return (
              <MuiTableRow key={`AARF${sampleReading.code}`}>
                <StyledMultiTableCell align="left">
                  <TableReading key={`AARI${sampleReading.code}`} reading={sampleReading} hideBorder />
                </StyledMultiTableCell>
              </MuiTableRow>
            );
          })}
        </>
      }
    />

  );
};