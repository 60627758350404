import React from 'react';
import styled from '@emotion/styled';
import { Analysis } from 'types';
import { inputTypes } from 'constants/inputTypes';
import { LocationTypes } from 'constants/locationTypes';
import { formatToDateTime } from 'functions/dateFormatter';
import { ReadingCode } from 'constants/readingCode';

const StyledHeading = styled.div`
  padding-top:5px;
`;

interface Props {
  analysis: Analysis;
}

interface Translations {
  [key: string]: string;
}

export const InstrumentCodeAndPositionTableCell: React.FC<Props> = ({ analysis }) => {
  const translations: Translations = {
    'right': 'höger',
    'left': 'vänster',
    'average': 'medelvärde',
  };

  const getPositionText = (position: string | undefined) => {
    const translatedPosition = translations[position?.toLowerCase() ?? 'average'];
    return `Position: ${translatedPosition ?? '-'}`;
  }

  const isFallingNumber = analysis.readings?.findIndex(reading => reading.code === ReadingCode.FN_FALLING_NUMBER) !== -1
  const positionText = getPositionText(analysis.position)
  const instrumentText = `Instrumentkod: ${analysis.eurofinsCode ?? 'saknas'}`
  return (
    <div key={analysis.id + 'id'}>
      <StyledHeading>{instrumentText}</StyledHeading>
      {/*&nbsp; is to take upp space if it's not a falling number */}
      {isFallingNumber ? positionText : <>&nbsp;</>}
    </div>
  );
};

export const ApprovalInfoTableCell: React.FC<Props> = ({ analysis }) => {
  const inputType = analysis.inputType === inputTypes.ManualInput ? 'manuellt' : '';
  const approvedByText = analysis.location === LocationTypes.Azure
    ? 'systemet'
    : `${analysis.approvedBy}, ${analysis.location}`;
  const sentText = analysis?.sentToM3Timestamp
    ? `Skickad: ${formatToDateTime(analysis.sentToM3Timestamp)}`
    : 'Ej skickad';

  return (
    <div key={analysis.sentToM3Timestamp + 'sentToM3Timestamp'}>
      {`${sentText}`}
      <br />
      {`Godkänd ${inputType} av: ${approvedByText}`}
    </div>
  );
};