
/**
 * Enum for the type support table has
 */
export enum SupportType {
  /**
   * Is used to find a analysis code ("analysklass" or "L code") for a specific constituent name.
   */
  ANALYSIS = "Analys",
  EXTRA_ANALYSIS = "ExtraAnalys",
  MANUAL_INPUT = "ManualInput",
  SAMPLE_TYPE = "ProvTyp",
  /**
   * Is used to find a sourceName for readings
   * ReadingName has name (e.g "Avfall %") and value (e.g "L0013")
   */
  READING_NAME = "ReadingName"
}