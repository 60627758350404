import { createContext, useState } from "react";
import IConfirmAlertOptions from "interfaces/IDialogAlertOptions";
import IContextProps from "interfaces/IContextProps";
import IDialogAlertOptions from "interfaces/IDialogAlertOptions";

interface IDialogContext {
  dialog: IDialogAlertOptions,
  setDialog: React.Dispatch<React.SetStateAction<IConfirmAlertOptions>>
}

export const DialogContext = createContext<IDialogContext>({} as IDialogContext);

const DialogContextProvider = (children: IContextProps) => {
  const [dialog, setDialog] = useState<IConfirmAlertOptions>({
    title: '',
    body: '',
    isOpen: false
  });

  const values = {
    dialog,
    setDialog
  };

  return (
    <DialogContext.Provider value={values}>
      {children.children}
    </DialogContext.Provider>
 );
};

export default DialogContextProvider;